import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NotificationDetails {
  carrierName: string;
  blNo: string;
  document: string;
  event: string;
  documentNo: string;
  time: string;
  amount: string;
}

interface NotificationSlice {
  data: NotificationDetails[];
}

const initialState: NotificationSlice = {
  data: [],
};

export const notificationSlice = createSlice({
  name: 'creditBalance',
  initialState,
  reducers: {
    updateNotificationDetails: (
      state,
      action: PayloadAction<NotificationDetails[]>
    ) => {
      state.data = action.payload;
    },
  },
});

export const { updateNotificationDetails } = notificationSlice.actions;

export default notificationSlice.reducer;
