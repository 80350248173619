export const blDetailsTable = [
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402069',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402030',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402045',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402056',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402010',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402035',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402042',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402050',
  },
  {
    hasMessage: true,
    blNumber: 'HLCUMEA210402070',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402060',
  },
  {
    hasMessage: false,
    blNumber: 'HLCUMEA210402080',
  },
];

export const getBlDetail = (blNumber: string) => {
  return blDetailsTable.find((value) => value.blNumber === blNumber);
};
