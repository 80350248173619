import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface BlRequestInsSlice {
  isCarrierHaulageRequired: boolean;
}

const initialState: BlRequestInsSlice = {
  isCarrierHaulageRequired: false,
};

export const blRequestInsSlice = createSlice({
  name: 'blRequestInstruction',
  initialState,
  reducers: {
    toggleBlRequestInstruction: (state, action: PayloadAction<boolean>) => {
      state.isCarrierHaulageRequired = action.payload;
    },
  },
});

export const { toggleBlRequestInstruction } = blRequestInsSlice.actions;

export default blRequestInsSlice.reducer;
