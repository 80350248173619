import { Button, ButtonGroup, Card } from "react-bootstrap";
import ReqForm from "./ReqForm";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDispatch } from "react-redux";
import { updateSelectedTab } from "./slices/nominationReq";
import { useLocation, matchPath } from "react-router-dom";

const NominationReq = () => {
  const location = useLocation();

  const matchPth = matchPath(
    {
      path: "/bl/:blNum/nomination-request",
      caseSensitive: true,
      end: true,
    },
    location.pathname
  );

  const selectedTab = useSelector(
    (state: RootState) => state.nominationReq.selectedTab
  );

  const dispatch = useDispatch();

  const _updateSelectedTab = (tab: "consignee" | "liner") => () => {
    dispatch(updateSelectedTab(tab));
  };

  return (
    <Card className="h-100">
      {!matchPth && (
        <Card.Header /* className="border-bottom" */>
          <div className="row /* g-0 */">
            <div className="col-lg-auto ">
              <span className="text-primary fs-0">
                You are not delegated for this BL.Request delegation from:
              </span>
            </div>
            <div className="col-lg-4">
              <div className="row /* g-0 */">
                <div className="col-lg-5">
                  <Button
                    variant={
                      selectedTab === "consignee" ? "dark" : "outline-dark"
                    }
                    size="sm"
                    className="w-80 /* odex-border-r-0px fs--1 */"
                    onClick={_updateSelectedTab("consignee")}>
                      
                    Consignee
                  </Button>
                </div>

                <div className="col-4">
                  <Button
                    variant={selectedTab === "liner" ? "dark" : "outline-dark"}
                    size="sm"
                    className="w-100 odex-border-l-0px fs--1"
                    onClick={_updateSelectedTab("liner")}>
                    Liner
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card.Header>
      )}
      <ReqForm />
    </Card>
  );
};

export default NominationReq;
