import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { blDetailsTable } from '../data/blDetails';
import MainTableNewJson from '../../../../services/mainTable.json';

interface BlDetailsTable {
  blNumber: string;
  hasMessage: boolean;
}

interface BlDetailsTableSlice {
  selectedBlNumber: string | null;
  data: typeof MainTableNewJson.data;
  rowColumns: IBlDetailsRowColumns;
}

interface IBlDetailsRowColumns {
  mainTableClass: string;
  documentMainViewClass: string;
  documentHistoryClass: string;
  outletClass: string;
}

const initialState: BlDetailsTableSlice = {
  selectedBlNumber: null,
  data: MainTableNewJson.data,
  rowColumns: {
    mainTableClass: 'col-lg-12',
    documentMainViewClass: 'd-none',
    documentHistoryClass: 'd-none',
    outletClass: 'd-none',
  },
};

type DocumentHistoryDetails = {
  document: string;
  event: string;
  documentNo: string;
  time: string;
  amount: string;
  docStatus:string
};

type DocumentStatus = {
  instruction: string;
  payment: string;
  release: string;
};

type Document = {
  blNumber: string;
  consignee: string;
  nominatedAgent: string;
  pol: string;
  pod: string;
  end: string;
  instruction: string;
  payment: string;
  release: string;
  time: string;
  payable: string;
  due: string;
  currency: string;
  vessel: string;
  eta: string;
  status: DocumentStatus;
  history: DocumentHistoryDetails[];
};

export const blDetailsTableSlice = createSlice({
  name: 'documentHistory',
  initialState,
  reducers: {
    updateBlDetailsRowColumns: (
      state,
      action: PayloadAction<IBlDetailsRowColumns>
    ) => {
      state.rowColumns = action.payload;
    },
    updateBlDetailsTable: (
      state,
      action: PayloadAction<typeof MainTableNewJson.data>
    ) => {
      state.data = action.payload;
    },
    updateSelectedBlNumberInBlDetailsTable: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedBlNumber = action.payload;
    },
    updateSelectedBlDetails: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  updateBlDetailsTable,
  updateSelectedBlNumberInBlDetailsTable,
  updateSelectedBlDetails,
  updateBlDetailsRowColumns,
} = blDetailsTableSlice.actions;

export default blDetailsTableSlice.reducer;
