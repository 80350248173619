import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getCustomersDetailsByPartnerId } from "./action/ProfileAccount";

interface CustomerMapping {
  customerMapId: any;
  customerId: any;
  linerId: any;
  mapCode: string;
  internalCode: string;
  readonly: boolean;
}

interface CustomerMappingList {
  list: CustomerMapping[];
}

interface ICustomerMappingList {
  list: CustomerMappingList[];
  next: string | null;
  previous: string | null;
}

interface Email {
  mstPartnerEmailId: any;
  mstPartnerId: any;
  emailId: string;
  status: string;
  readonly: boolean;
}

interface EmailList {
  list: Email[];
}

interface IEmailList {
  list: EmailList[];
  next: string | null;
  previous: string | null;
}

interface PartnerAccountDetails {
  mstAccountId: any;
  mstPartnerId: any;
  bankName: string;
  accountName: string;
  accountAlias: string;
  accountNo: string;
  accountCurrency: string;
  localBankCode: string;
  branchAddress: string;
  ibanNo: string;
  swiftCode: string;
  readonly: boolean;
}

interface PartnerAccountDetailsList {
  list: PartnerAccountDetails[];
}

interface IPartnerAccountDetailsList {
  list: PartnerAccountDetailsList[];
  next: string | null;
  previous: string | null;
}

interface ProfileAccount {
  dataLoading: boolean;
  data: allList;
  rowColumns: IAccountDetailsRowColumns;
  toggle: boolean;
  linerOptions: any;
}

interface IAccountDetailsRowColumns {
  mainTableClass: string;
  outletClass: string;
  colClass: string;
  custMapTableClass: string;
  custMapoutletClass: string;
}

interface allList {
  updatedAccount: PartnerAccountDetails;
  customermappingList: ICustomerMappingList;
  partnerAccountList: IPartnerAccountDetailsList;
  emailList: IEmailList;
  mstPartnerId: any;
  companyName: string;
  partnerType: string;
  parentPartnerId: any;
  isPartnerActive: string;
  privilegeId: string;
  verifiedEmailDt: string;
  isVerifyEmailSent: string;
  kycStatus: string;
  country: string;
  defaultCurrency: string;
  defaultTimezone: string;
  partnerIdentityNo: string;
  partnerIdentityId: string;
  issueDt: string;
  validTillDt: string;
  attPath: string;
  addrNm: string;
  address: string;
  contactPerson: string;
  isDefault: string;
  phnNo: string;
  mstAddrId: string;
}

const initialState: ProfileAccount = {
  data: {
    updatedAccount: {
      mstAccountId: null,
      mstPartnerId: null,
      bankName: "",
      accountName: "",
      accountAlias: "",
      accountNo: "",
      accountCurrency: "",
      localBankCode: "",
      branchAddress: "",
      ibanNo: "",
      swiftCode: "",
      readonly: false,
    },
    customermappingList: {
      next: null,
      previous: null,
      list: [],
    },
    partnerAccountList: {
      next: null,
      previous: null,
      list: [],
    },
    emailList: {
      next: null,
      previous: null,
      list: [],
    },
    mstPartnerId: null,
    companyName: "",
    partnerType: "",
    parentPartnerId: null,
    isPartnerActive: "",
    privilegeId: "",
    verifiedEmailDt: "",
    isVerifyEmailSent: "",
    kycStatus: "",
    country: "",
    defaultCurrency: "",
    defaultTimezone: "",
    partnerIdentityNo: "",
    partnerIdentityId: "",
    issueDt: "",
    validTillDt: "",
    attPath: "",
    addrNm: "",
    address: "",
    contactPerson: "",
    isDefault: "",
    phnNo: "",
    mstAddrId: "",
  },
  dataLoading: true,
  rowColumns: {
    mainTableClass: "col-lg-12",
    outletClass: "d-none",
    colClass: "col-lg-2",
    custMapTableClass: "col-lg-12",
    custMapoutletClass: "d-none",
  },
  toggle: false,
  linerOptions: [],
};

export const ProfileAccountSlice = createSlice({
  name: "profileAccount",
  initialState,
  reducers: {
    updateProfileList: (state, action: PayloadAction<ProfileAccount>) => {
      state = action.payload;
    },
    updateBlDetailsRowColumns: (
      state,
      action: PayloadAction<IAccountDetailsRowColumns>
    ) => {
      state.rowColumns = action.payload;
    },
    updateAccountDetails: (
      state,
      action: PayloadAction<IPartnerAccountDetailsList>
    ) => {
      state.data.partnerAccountList = action.payload;
    },
    updateCustomerMappingListDetails: (
      state,
      action: PayloadAction<ICustomerMappingList>
    ) => {
      state.data.customermappingList = action.payload;
    },
    updateToggle: (state, action: PayloadAction<ProfileAccount>) => {
      state.toggle = action.payload.toggle;
    },
    updateLinerOptions: (state, action: PayloadAction<any>) => {
      state.linerOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomersDetailsByPartnerId.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(
      getCustomersDetailsByPartnerId.fulfilled,
      (state: any, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );
  },
});

export const {
  updateProfileList,
  updateBlDetailsRowColumns,
  updateAccountDetails,
  updateCustomerMappingListDetails,
  updateToggle,
  updateLinerOptions,
} = ProfileAccountSlice.actions;

export default ProfileAccountSlice.reducer;
