import { Card, Button, InputGroup, Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faPlus, faTasks, faTheaterMasks, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { assignInstructionsToSelf } from '../../blRequestInstruction/components/actions/Instruction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { updateAssigneTo, updateSelectedTableData } from '../../dashboard/slices/dashboardSlice';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { AESEncrypt } from '../../../../encrypt-util';
interface IProps {
  isDisabled?: boolean;
  blEventId?: number;
  blNum?:string;
  selectedRows?: any[];
}
 
const SnoozedToButton = ({ isDisabled,blNum,blEventId,selectedRows }: IProps) => {
    const { t } = useTranslation();
    const handleToggleClick = () => {
        setdropdownDownVisible(true);
      }; 
    const [dropdownDownVisible, setdropdownDownVisible] = useState(false);  
    const profileDetails = useSelector(
        (state: RootState) => state.userProfile.profile
      );   
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSnooz, setIsSnooz] = useState<boolean>(false);
  const [noOfDays, setnoOfDays] = useState<string>();
  const data = useSelector((state:RootState)=> state.snooze.snoozeData)
  const selectedEventId = useSelector(
    (state: RootState) => state.documentHistory.selectedEventId?.toString()
  );


  const blEventId_docInsDashboard:any = useSelector(
    (state: RootState) => state.dashboard.instructionDashboardList.result.filter((Item)=> Item.blNumber == blNum )[0]?.blEventId
  );

  const dashboard = (() => {
    if (
      matchPath('/instruction/:blnum', location.pathname) ||
      location.pathname.includes('instruction/bl')
    )
      return '/instruction';
    if (
      matchPath('/payment/:blnum', location.pathname) ||
      location.pathname.includes('payment/bl')
    )
      return '/payment';
    return '';
  })();

  type FormValues = {
        noOfDays:string;
      };

      const validationSchema = Yup.object().shape({
       
      });

      const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
      } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
      });

  const handleChange = () => {
    setIsSnooz(!isSnooz);
  };
  return (

    <><Dropdown
          navbar={true}
          style={{
              marginRight: '0.5rem',
              position: 'relative',
              float: 'left',
          }}
          onClick={handleToggleClick}
      >
              <Dropdown.Toggle bsPrefix='toggle' as={Link} to=''>
                  <div>
                      <Button
                          variant='ms-3 btn btn-falcon-primary z-inxe-agent btn-sm'
                          size='sm'
                          title='Snooze'
                          onClick={() => {

                             setTimeout(() => {
                                if (location.pathname.includes('bl')
                                ){
                                  navigate(`${dashboard}/bl/${AESEncrypt(blNum??'')}/snooze/${AESEncrypt(selectedEventId??'')}`);
                              }else{
                                navigate(`/instruction/snooze/${AESEncrypt(`${blEventId}`)}`);
                              }
                       }, 500);

                           /*   if (!selectedRows?.length || selectedRows?.length == 0 || selectedRows?.length < 1) {
                                  toast.dark('Only selected instruction can be snoozed.');
                              } else if (selectedRows?.filter((v: any) => v?.status != 'Submitted').length != 0) {
                                  toast.dark('Only submitted Instruction can be snoozed.');
                              } else if (selectedRows?.filter((v: any) => v?.inst_id !=804).length != 0) {
                                toast.dark('Only submitted Instruction can be snoozed.');
                            }else{
                             
                               
                              }   */
                             
                            
                          }}
                          disabled={isDisabled}
                      >
                          <FontAwesomeIcon
                              icon={faBell}
                              style={{
                                  fontSize: '14px',
                                  color: '#f5822a',
                                  marginRight: '0.5rem',
                              }}
                              title='Snooze' />
                          <span>Snooze</span>
                          
                      </Button>


                  </div>
              </Dropdown.Toggle>
        

          {dropdownDownVisible && (
              <Dropdown.Menu
                  className='dropdown-caret dropdown-menu-card  dropdown-menu-end d-none'
                  style={{ height: '90px' }}
              >
                  {<div>
                      <div className='row g-2 align-items-sm-center'>
                          <div className='col'>
                              <div className='row align-items-center'>
                                <Form.Label className='fs--1'>
                                        <input
                                        type="checkbox"
                                        checked={isSnooz}
                                        onChange={handleChange}
                                        />
                                        Snooze
                                    </Form.Label>
                              </div>
                          </div>
                          <div className='col-lg-5 mb-3'>
                <Form.Group controlId='invoiceChanges.returnType'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:returnLoc')}&nbsp;
                  </Form.Label>
                  <Form.Select
                    aria-label={t('resource_2:returnType')}
                    {...register('noOfDays')}
                    className={`fs--1 form-control ${
                      errors.noOfDays ? 'is-invalid' : ''
                    }`}
                    disabled={false}
                    value={noOfDays}
                    onChange={(e) => {
                        setnoOfDays(e.target.value);
                      
                    }}>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                    
                  </Form.Select>
                  <div className='invalid-feedback'>
                    {errors.noOfDays?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
                      </div>

                      <div
                          style={{ width: '23rem' }}
                          className='row g-2 align-items-sm-center'
                      >

                      </div>
                  </div>}
              </Dropdown.Menu>
          )}
      </Dropdown>
      
     {/*  <Button
          className=''
          variant='falcon-primary z-inxe-agent'
          size='sm'
          onClick={() => {
              if (!selectedRows?.length || selectedRows?.length == 0) {
                  toast.dark('Only selected instruction can be assigned.');
              } else if (selectedRows?.filter((v: any) => v?.status != 'Submitted').length != 0) {
                  toast.dark('Only submitted Instruction can be assigned.');
              } else {
                  //call api
                  dispatch(
                      assignInstructionsToSelf(
                          selectedRows.map((v: any) => v.bl_event_id)
                      )
                  )
                      .unwrap()
                      .then((response: any) => {
                          if (response.responseCode == 'SUCCESS') {
                              toast.success(response.responseMessage);
                              dispatch(updateAssigneTo('1'));
                              //reset selection
                              dispatch(updateSelectedTableData([]));
                          } else {
                              toast.error(response.responseMessage);
                          }
                      });
              }
          } }
          disabled={isDisabled}
      >
              <FontAwesomeIcon
                  icon={faUserCheck}
                  className='me-2'
                  style={{ color: '#f5822a' }} />
              <span>Self Assign</span>
          </Button> */}</>  
  );
};
export default SnoozedToButton;
