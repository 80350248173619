import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateDataTableFilterState } from "./slices/DataTable";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

const SearchFilter = ({ objectKey, value, uniqueFilterKey , closeFilter}) => {
  const filterState = useSelector((state) => state.dataTable.filterState);
  const [inputValue, setInputValue] = useState(
    filterState?.[uniqueFilterKey]?.[objectKey] || ""
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const focusRef = useRef(null);

  useEffect(()=>{
    setTimeout(()=>{
      focusRef.current.focus()
    },200)
  },[])
  
  const handleSearch = () => {
    // Trim blank spaces from user input when they submit search
    let output = inputValue
    if(typeof inputValue === "string")output = inputValue.trim()
    
    dispatch(
      updateDataTableFilterState({
        ...filterState,
        [uniqueFilterKey]: {
          ...filterState[uniqueFilterKey],
          [objectKey]: output,
        },
      })
    );

    const search = location.search
      ? {
          ...queryString.parse(location.search),
          seek: undefined,
          direction: undefined,
        }
      : null;

    if (!inputValue && search) {
      navigate(`${location.pathname}?${queryString.stringify(search)}`);
    }
    closeFilter()
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleBlur = () => {
    handleSearch();
  };

  return (
    <>
      <InputGroup className="position-relative">
        <FormControl
          autoFocus = {false}
          ref={focusRef}
          value={inputValue}
          onChange={({ target: { value } }) => {
            setInputValue(value);
          }}
          onKeyDown={handleKeyPress} // Listen for Enter key press
          onBlur={handleBlur} // Listen for blur event
          size="sm"
          id="search"
          placeholder="Search..."
          type="search"
          className="shadow-none"
          autoComplete="off"
        />
        <InputGroup.Text className="bg-transparent" onClick={handleSearch}>
          <FontAwesomeIcon icon="search" className="fs--1 text-600" />
        </InputGroup.Text>
      </InputGroup>
    </>
  );
};

export default SearchFilter;
