import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"

export default function ToggleDiv(props:any){
    const [show, setShow] = useState(false)
    useEffect(()=>{
        if(props.initial === true || props.initial === false)setShow(props.initial)
    },[])
    return <>
        <div>
            <button
            type="button"
            className="m-1 "
            id="toggle"
            style={{borderWidth:0, background:'transparent'}}
            aria-controls="childDiv"
            onClick={()=>{setShow(!show)}}
            >
                {<FontAwesomeIcon icon={show?faMinus:faAdd} color="#f5822a" size="xs"/>}
            </button>
            <label className='fs--1 mb-2' style={{color:"#2C7BE5"}} htmlFor="toggle">
                {(show?props?.hideText:props?.showText)??''}
            </label>
        </div>
        <div className={props.className}>
            <div className={show?"":"d-none"}>
                <div id='childDiv'>
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </>
}