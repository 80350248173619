import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { blDetailsTable } from '../data/blDetails';
import MainTableNewJson from '../../../../services/mainTable.json';

interface BlDetailsTable {
  blNumber: string;
  hasMessage: boolean;
}

interface InstructionDetailsTableSlice {
  selectedBlNumber: string | null;
  data: typeof MainTableNewJson.data;
  rowColumns: IBlDetailsRowColumns;
}

interface IBlDetailsRowColumns {
  mainTableClass: string;
  documentMainViewClass: string;
  documentHistoryClass: string;
  outletClass: string;
}

const initialState: InstructionDetailsTableSlice = {
  selectedBlNumber: null,
  data: MainTableNewJson.data,
  rowColumns: {
    mainTableClass: 'col-lg-12',
    documentMainViewClass: 'd-none',
    documentHistoryClass: 'd-none',
    outletClass: 'd-none',
  },
};

type DocumentHistoryDetails = {
  document: string;
  event: string;
  documentNo: string;
  time: string;
  amount: string;
  docStatus:string
};

type DocumentStatus = {
  instruction: string;
  payment: string;
  release: string;
};

type Document = {
  blId: number | string | null;
  docId: number | string | null;
  instId: number | string | null;
  blNo: string;
  custName: string;
  createdUser: string;
  instType: string;
  status: string;
  timestamp: string;
  history: DocumentHistoryDetails[];
};

export const instructionDetailsTableSlice = createSlice({
  name: 'documentHistory',
  initialState,
  reducers: {
    updateBlDetailsRowColumns: (
      state,
      action: PayloadAction<IBlDetailsRowColumns>
    ) => {
      state.rowColumns = action.payload;
    },
    updateBlDetailsTable: (
      state,
      action: PayloadAction<typeof MainTableNewJson.data>
    ) => {
      state.data = action.payload;
    },
    updateSelectedBlNumberInBlDetailsTable: (
      state,
      action: PayloadAction<string>
    ) => {
      state.selectedBlNumber = action.payload;
    },
    updateSelectedBlDetails: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  updateBlDetailsTable,
  updateSelectedBlNumberInBlDetailsTable,
  updateSelectedBlDetails,
  updateBlDetailsRowColumns,
} = instructionDetailsTableSlice.actions;

export default instructionDetailsTableSlice.reducer;
