/***
 * returns format string with date only
 * for datepicker->dateformat value
 * based on the locale of the user
 */
export function getDateFormat(){
    const locale = new Intl.DateTimeFormat().resolvedOptions().locale
    const format = new Intl.DateTimeFormat(locale,
        {year:'numeric',month: '2-digit',day:'2-digit'})
        .formatToParts().map(part =>{
            switch(part.type){
                case 'day': return 'dd';
                case 'month':return 'MM';
                case 'year': return 'yyyy';
                default: return part.value
            }
        }).join('');
    return format
}
/***
 * returns format string with date and time
 * for datepicker dateformat value
 * based on the locale of the user
 */
export function getDateTimeFormat(){
    const locale = new Intl.DateTimeFormat().resolvedOptions().locale
    const format = new Intl.DateTimeFormat(locale,
        {year:'numeric',month: '2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit'})
        .formatToParts().map(part =>{
            switch(part.type){
                case 'day': return 'dd';
                case 'month':return 'MM';
                case 'year': return 'yyyy';
                case 'minute': return 'mm';
                case 'hour':return 'hh';
                case 'dayPeriod':return 'a';
                default: return part.value
            }
        }).join('');
    return format
}

