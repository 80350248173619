import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store';
import { useDispatch } from 'react-redux';
import { toggleDocumentHistoryColumnShort } from '../../blDetails/slices/documentHistorySlice';
import { useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAsyncDebounce } from 'react-table';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  approveRejectPickUp,
  getInstructionList,
  getPickUp,
  getReleaseTypeList,
  getReturnTypeList,
  savePickUpInstruction,
} from '../../dashboard/actions/instruction';
import {
  faCheck,
  faRedo,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { error, profile } from 'console';
import { toast } from 'react-toastify';
import { AESDecrypt } from '../../../../encrypt-util';
import i18next from 'i18next';
import { commonApproveReject } from '../../dashboard/actions/instruction';
import { updateDashbaordRefresh, updateSortStateDataTable } from '../../../common/components/DataTable/slices/DataTable';

import { useBackNavigation } from '../../../../hooks/BackNavigation';
import useDataCountry from '../../../../hooks/useDataCountry';
import SnoozedToButton from '../../instructionDashboard/components/SnoozedTo';

const PickUpInstruction = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { blNum, encryptdoc } = useParams();
  const docId = AESDecrypt(encryptdoc);
  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );
  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );
  const onBack = useBackNavigation();
  const _onCloseForm = () => {
    function extractValuesFromUrl(url: any) {
      const params = new URLSearchParams(url);
      const sortParam = params.get('sort');

      if (sortParam) {
        const arr: any = sortParam.split('%2C');
        return arr;
      } else {
        return [];
      }
    }

    dispatch(toggleDocumentHistoryColumnShort(false));
    // dispatch(
    //   updateBlDetailsRowColumns({
    //     ...rowColumns,
    //     mainTableClass: 'col-lg-2',
    //     documentMainViewClass: 'col-lg-10',
    //     documentHistoryClass: 'col-lg-12',
    //     outletClass: 'd-none',
    //   })
    // );
    // if (matchPath('/instruction/:blnum/:docid/:id', location.pathname)) {
    //   navigate(`/instruction`);
    // } else if (selectedRouter === 'instruction') {
    //   navigate(
    //     `/instruction/${encodeURIComponent(blNum || '')}${
    //       window.location.search || ''
    //     }`
    //   );
    setTimeout(() => {
      onBack();

      if (selectedRouter === 'instruction') {
        if (window.location.search) {
          const paramVal = extractValuesFromUrl(window.location.search);
          const splitedval = paramVal[0].split(',');
          setTimeout(() => {
            dispatch(
              updateSortStateDataTable({
                key: splitedval[0],
                value: splitedval[1],
              })
            );
          }, 100);
        }
      }
      // else if (matchPath('/payment/:blnum/:docid/:id', location.pathname)) {
      //   navigate(`/payment`);
      // } else if (selectedRouter === 'payment') {
      //   navigate(`/payment/${encodeURIComponent(blNum || '')}`);
      // } else if (location.pathname.includes('808')) {
      //   navigate(`/bl/${encodeURIComponent(blNum || '')}`);
      // }
    }, 500);
  };
  /*   const dataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  ); */
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const blEventId_docInsDashboard:any = useSelector(
    (state: any) => state.dashboard.instructionDashboardList.result.filter((Item:any)=> Item.bl_id == AESDecrypt(blNum) )[0]?.bl_event_id
  );

  const dataCountry = useDataCountry();

  const rbac = useSelector((state: RootState) => state.userProfile);
  const pickUpLocList = useSelector(
    (state: RootState) => state.invoiceChange.releaseTypeList
  );

  const pickUpForm = useSelector((state: RootState) => state.pickup.pickUpData);

  const selectedLinerId = useSelector(
    (state: RootState) => state.navbarTop.linerId
  );

  //const onSubmit = handleSubmit(async (payload) => {});

  const [pickUpLoc, setPickUpLoc] = useState('0');
  const [rejectRemarks, setRejectionRemarks] = useState('');
  const [userRemark, setUserRemark] = useState('');
  const [emailId, setEmailId] = useState('');
  type FormValues = {
    pickUpLoc: string;
    destuffingTp: string;
    returnLoc: string;
    rejectRemarks: string;
    userRemark: string;
  };
  useEffect(() => {
    if (!disabledPath) {
      setValue('pickUpLoc', '0');
      setValue('destuffingTp', '1');
      setValue('returnLoc', '0');
    }
  }, [location.pathname]);

  const validationSchema = Yup.object().shape({
    pickUpLoc: Yup.string().required(
      t('resource_3:isRequired', { field: t('resource_2:releaseType') })
    ).notOneOf(['0'],t('resource_3:isRequired', { field: t('resource_2:releaseType') })),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const [country, setCountry] = useState('');
  const [status, setStatus] = useState('');

  const disabledPath = [
    { path: '/bl/:blNum/808/:eventId' },
    { path: 'instruction/bl/:blNum/808/:eventId' },
    { path: '/instruction/:blNum/808/:blId' },
    {
      path: '/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[3] !== 'instruction',
    },
    {
      path: 'instruction/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    {
      path: 'payment/bl/:blNum/:encrypt/:encryptdoc',
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    { path: '/instruction/:blNum/:encrypt/:encryptdoc' },
  ].some(({ path, condition }: { path: string; condition?: boolean }) => {
    return (
      (condition ?? true) &&
      matchPath({ path, caseSensitive: true, end: true }, location.pathname)
    );
  });

  const disableFields =
    profileDetails?.partnerType === 'liner' ||
    (!!disabledPath && profileDetails?.partnerType !== 'liner');

  useEffect(() => {
    dispatch(getReleaseTypeList({partnerCountry: country || profileDetails.partnerCountry, linerId: selectedLinerId}));
    // const userSelectedCountry = [
    //   {
    //     loginId: profileDetails.email,
    //     country:
    //       country || linerDataCountry || profileDetails.partnerCountry || '',
    //   },
    // ];
    // window.localStorage.setItem(
    //   '@user-selected-datacountry',
    //   JSON.stringify(userSelectedCountry)
    // );
    i18next.changeLanguage(
      country || linerDataCountry || profileDetails.partnerCountry || ''
    );
  }, [country,profileDetails.partnerCountry, selectedLinerId]);

  const _onPickUpLocChange = useAsyncDebounce((value: string) => {
    setPickUpLoc(value);
  }, 200);

  const onSubmit = handleSubmit(async (payload) => {
    try {
      dispatch(
        savePickUpInstruction({
          ...payload,
          blId: AESDecrypt(blNum),
        })
      );
      dispatch(
        getInstructionList({
          blNum: AESDecrypt(blNum),
          country: dataCountry || /* profileDetails.partnerCountry || */ '',
        })
      );
      onBack()
    } catch (err: any) {}
  });

  useEffect(() => {
    if (disabledPath && selectedLinerId) {
      dispatch(
        getPickUp({
          docId,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      )
        .unwrap()
        .then((v: any) => {
          setPickUpLoc(v?.data?.pickUpLoc);
          // setReturnLoc(v?.data?.returnLoc);
          setUserRemark(v?.data?.userRemarks);
          setRejectionRemarks(v?.data?.rejectionRemarks);
          setCountry(v?.data?.country);
          setEmailId(v?.data?.email);
          setStatus(v?.data?.status);
        });
    }
  }, [docId, selectedLinerId]);

  const onApproveReject = (status: string, type: string) => {
    if (status && type) {
      if (status === 'R' && (rejectRemarks === '' || rejectRemarks == null)) {
        toast.dark(t('resource_message:remarksMandatory'));
      } else {
        dispatch(
          commonApproveReject({
            instructionId: docId ? parseInt(docId) || null : null,
            instructionType: type || null,
            linerRemarks: rejectRemarks || null,
            customerRemarks: userRemark || null,
            status: status || null,
            emailTo: emailId || null,
          })
        )
          .unwrap()
          .then((response: any) => {
            toast.success(response?.responseMsg);
            dispatch(updateDashbaordRefresh(true))
          })
          .catch((error: any) => {
            toast.error(error);
          });

        //update instruction list and redirect to previous page
        dispatch(
          getInstructionList({
            blNum: AESDecrypt(blNum),
            country: dataCountry || profileDetails.partnerCountry || '',
          })
        );
        onBack()
      }
    }
  };

  const resetForm = () => {
    setPickUpLoc('0');
    setUserRemark('');
  };

  return (
    <Card className='h-100 overflow-auto'>
      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={_onCloseForm}
        />

        <h2 className='fs-0 text-primary mb-1'>
          {t('resource_2:releaseInstructions')}
        </h2>
      </Card.Header>
      <Card.Body>
        <div className='container'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {t('resource_message:pickUpInstruction') != '' && (
                <div className='col-lg-12 mb-1'>
                  <Form.Label style={{ color: '#f5822a' }}>
                    <ul style={{ paddingLeft: '15px' }}>
                      {t('resource_message:pickUpInstruction')
                        .split('\n')
                        .map((v: any) => (
                          <li>{v}</li>
                        ))}
                    </ul>
                  </Form.Label>
                </div>
              )}
              <div className='col-lg-4 mb-3'>
                <Form.Group controlId='pickUpInstruction.pickUpLoc'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:pickUpLoc')}&nbsp;
                  </Form.Label>
                  <Form.Select
                    aria-label={t('resource_2:pickUpLoc')}
                    {...register('pickUpLoc')}
                    className='fs--1'
                    disabled={disableFields}
                    value={pickUpLoc}
                    onChange={(e) => {
                      setPickUpLoc(e.target.value);
                      setValue('pickUpLoc', e.target.value);
                      _onPickUpLocChange(e.target.value);
                      clearErrors()
                    }}
                  >
                    {pickUpLocList.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.pickUpLoc && <Form.Label className="fs--1 is-invalid mandatory m-0 p-0">
                    {errors.pickUpLoc.message}</Form.Label>}
                </Form.Group>
              </div>
              {/*<div className='col-lg-4 mb-3'>
                <Form.Group controlId='pickUpInstruction.destuffingTp'>
                  <Form.Label className='fs--1'>
                    <span className='mandatory'>*</span>
                    {t('resource_2:destuffingTp')}&nbsp;
                  </Form.Label>
                  <Form.Select
                    aria-label={t('resource_2:destuffingTp')}
                    {...register('destuffingTp')}
                    className='fs--1'
                    disabled={disableFields}
                    value={destuffingTp}
                    onChange={(e) => {
                      setDestuffingTp(e.target.value);
                      setValue('destuffingTp', e.target.value);
                      _onDestuffingTpChange(e.target.value);
                    }}
                  >
                    {destuffingTpList.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>*/}
              {/*  <div className='col-lg-4 mb-3'>
                <Form.Group controlId='pickUpInstruction.returnLoc'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:returnLoc')}&nbsp;
                  </Form.Label>
                  <Form.Select
                    aria-label={t('resource_2:returnLoc')}
                    {...register('returnLoc')}
                    className='fs--1'
                    disabled={disableFields}
                    value={returnLoc}
                    onChange={(e) => {
                      setReturnLoc(e.target.value);
                      _onReturnLocChange(e.target.value);
                    }}
                  >
                    {returnTypeList.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div> */}
              {(rbac.rbacDetails.returnCntApproveReject === 'Y' ||
                disableFields) && (
                <div className='col-lg-4 mb-3'>
                  <Form.Group controlId='pickUpInstruction.rejectionRemark'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:rejectRemark')}
                    </Form.Label>
                    <Form.Control
                      className={'fs--1 form-control'}
                      {...register('rejectRemarks')}
                      as='textarea'
                      onChange={(e) => setRejectionRemarks(e.target.value)}
                      //maxLength={150}
                      value={rejectRemarks}
                      disabled={
                        pickUpForm?.status === 'Completed' ||
                        pickUpForm?.status === 'Rejected' ||
                        profileDetails?.partnerType === 'customer'
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                </div>
              )}
              <div className='col-lg-4 mb-3'>
                <Form.Group controlId='pickUpInstruction.userRemark'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:userRemark')}
                  </Form.Label>
                  <Form.Control
                    className={'fs--1 form-control'}
                    {...register('userRemark')}
                    as='textarea'
                    onChange={(e) => setUserRemark(e.target.value)}
                    maxLength={100}
                    value={userRemark}
                    disabled={
                      disableFields
                    }
                  />
                </Form.Group>
              </div>
              {disableFields && (
                <div className='col-lg-5 mb-3'>
                  <Form.Group controlId='invoiceChanges.status'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:status')}&nbsp;
                    </Form.Label>
                    <Form.Control
                      className={'fs--1 form-control'}
                      type='text'
                      value={status}
                      disabled={true}
                    />
                  </Form.Group>
                </div>
              )}
              <>
                {country === dataCountry &&
                rbac.rbacDetails.pickUpApproveReject === 'Y' ? (
                  <>
                    {pickUpForm?.status === 'Completed' ||
                    pickUpForm?.status === 'Rejected' ? (
                      <></>
                    ) : (
                      <>
                        <div className='d-flex justify-content-center align-items-center'>
                          <Button
                            disabled={
                              pickUpForm?.status === 'Completed' ||
                              pickUpForm?.status === 'Rejected'
                                ? disableFields
                                : false
                            }
                            onClick={(e) => {
                              onApproveReject('A', 'PICK_UP');
                            }}
                            variant='success'
                            className='me-2 approve'
                            size='sm'
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              className='me-2 approve'
                            />
                            {t('resource_1:approve')}
                          </Button>

                          <Button
                            variant='danger'
                            className='reject me-2'
                            size='sm'
                            disabled={
                              pickUpForm?.status === 'Completed' ||
                              pickUpForm?.status === 'Rejected'
                                ? disableFields
                                : false
                            }
                            onClick={(e) => {
                              onApproveReject('R', 'PICK_UP');
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              className='me-2 reject'
                            />
                            {t('resource_1:reject')}
                          </Button>
                          {rbac.rbacDetails.snoozeCUD === 'Y' && !location.pathname.includes('/bl')? <SnoozedToButton    blNum={AESDecrypt(blNum)} blEventId={blEventId_docInsDashboard}  /> :""  }
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  !disabledPath &&
                  rbac.rbacDetails.pickUpCUD && (
                    <div className='d-flex justify-content-center align-items-center'>
                      <Button
                        className='me-2'
                        variant='falcon-primary'
                        size='sm'
                        type='submit'
                      >
                        <FontAwesomeIcon icon={faSave} className='me-2' />
                        {t('resource_1:submit')}
                      </Button>

                      <Button
                        className='me-2'
                        variant='falcon-danger'
                        size='sm'
                        onClick={resetForm}
                      >
                        <FontAwesomeIcon icon={faRedo} className='me-2' />
                        {t('resource_1:reset')}
                      </Button>
                    </div>
                  )
                )}
              </>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PickUpInstruction;
