import { useDispatch, useSelector } from 'react-redux';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import { updateBlDetailsRowColumns } from '../blDetails/slices/blDetailsTable';
import { Card } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDataTableColumn } from '../../common/types/dataTable';
import DataTable from '../../common/components/DataTable';
import { getCoreror } from '../dashboard/actions/instruction';
import { useEffect } from 'react';
import OdexSpinner from '../../common/components/OdexSpinner';
import { updateSelectedEventIdDH } from '../blDetails/slices/documentHistorySlice';
import { AESDecrypt } from '../../../encrypt-util';
import { t } from 'i18next';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';

import { useBackNavigation } from '../../../hooks/BackNavigation';
import useDataCountry from '../../../hooks/useDataCountry';

const Coreor = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { blNum } = useParams();
  const data = useSelector((state: RootState) => state.coreor.data);
  const dataLoading = useSelector(
    (state: RootState) => state.coreor.dataLoading
  );
  const onBack = useBackNavigation();
  const { t } = useTranslation();

  const linerId = useSelector((state:RootState)=>state.navbarTop.linerId)

  const dataCountry = useDataCountry();

  const rowColumns = useSelector(
    (state: RootState) => state.blDetailsTable.rowColumns
  );

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const openLink = () => {
    {
      window.open('http://41.160.4.49:9080/apex/cap.zul');
    }
  };

  const openLinkKE = () => {
    {
      window.open(' https://www.kwatos.kpa.co.ke/');
    }
  };

  useEffect(() => {
    if (blNum && linerId)
      dispatch(
        getCoreror({
          blNum: AESDecrypt(blNum) || null,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : linerId,
        })
      );
  }, [blNum, linerId]);
  const _onCloseForm = () => {
    dispatch(
      updateBlDetailsRowColumns({
        ...rowColumns,
        mainTableClass: 'col-lg-2',
        documentMainViewClass: 'col-lg-10',
        documentHistoryClass: 'col-lg-12',
        outletClass: 'd-none',
      })
    );
    dispatch(updateSelectedEventIdDH(null));

    setTimeout(() => {
      onBack();
    }, 500);
  };

  const columns: IDataTableColumn[] = [
    {
      accessor: 'containerNo',
      Header: 'Container No',
      minWidth: 120,
      width: 160,
      maxWidth: 150,
    },
    {
      accessor: 'containerType',
      Header: 'Type',
      minWidth: 120,
      width: 160,
      maxWidth: 150,
    },
    {
      accessor: 'containerSize',
      Header: 'Size',
      minWidth: 180,
      width: 160,
      maxWidth: 180,
    },
    {
      accessor: 'truckerCode',
      Header: t('resource_2:truckerCode'),
      minWidth: 100,
      width: 350,
      maxWidth: 360,
    },
    {
      accessor: 'releaseDate',
      Header: 'Release Date',
      minWidth: 100,
      width: 160,
      maxWidth: 120,
    },
    {
      accessor: 'lastFreeDate',
      Header: 'Last Free Date',
      minWidth: 100,
      width: 160,
      maxWidth: 120,
    },
    {
      accessor: 'releasePin',
      Header: t('resource_3:pickUpNumber'),
      minWidth: 100,
      width: 160,
      maxWidth: 120,
    },
    {
      accessor: 'releaseLoc',
      Header: 'Release Loc',
      minWidth: 100,
      width: 160,
      maxWidth: 120,
    },
  ];

  return (
    <Card className='w-100 border-top ' style={{ position: 'relative', minHeight:'100%' }}>
      <Card.Header className='d-flex justify-content-start align-items-center sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          onClick={_onCloseForm}
          className='cursor-pointer fa-lg'
        />
        {dataCountry === 'ZA'? (
          <span
            title={
              'Container Release : Please refer to NAVIS for confirmation of your container release.'
            }
            style={{
              width: '100%',
              display:'flex',
              alignContent:'center', 
              justifyContent:'flex-start',
              paddingLeft:'15px'
            }}
            className='fs-0'
          >
              <span
                style={{
                  fontSize: '14px',
                  color: '#f5822a',
                  fontWeight: 'bold',
                }}
                className='fs-0'
              >
                Container Release: Please refer to
              
              {dataCountry =='KE'&& linerId=='1'?<>
                <a
                  onClick={openLinkKE}
                  target='_blank'
                  style={{
                    color: '#2c7be5',
                    cursor: 'pointer',
                    padding: '5px'
                  }}
                  className='fs-0'
                >
                   KWATOS{' '}
                </a>
              </>:<>
                <a
                  onClick={openLink}
                  target='_blank'
                  style={{
                    color: '#2c7be5',
                    cursor: 'pointer',
                    padding: '5px'
                  }}
                  className='fs-0'
                >
                  NAVIS{' '}
                </a>
              </> }
                for confirmation of your container release.{''}
              </span>

          </span>
        ) :
        dataCountry == 'KE' && linerId == '1'?
        (
          <span
            title={
              'Container Release : Please refer to KWATOS for confirmation of your container release.'
            }
            style={{
              width: '100%',
              display:'flex',
              alignContent:'center', 
              justifyContent:'flex-start',
              paddingLeft:'15px'
            }}
            className='fs-0'
          >
              <span
                style={{
                  fontSize: '14px',
                  color: '#f5822a',
                  fontWeight: 'bold',
                }}
                className='fs-0'
              >
                Container Release: Please refer to
                <a
                  onClick={()=>{window.open('https://www.kwatos.kpa.co.ke/')}}
                  target='_blank'
                  style={{
                    color: '#2c7be5',
                    cursor: 'pointer',
                    padding: '5px'
                  }}
                  className='fs-0'
                >
                  KWATOS{' '}
                </a>
                for confirmation of your container release.{''}
              </span>

          </span>
        ):
        (
          ''
        )}
      </Card.Header>
      <Card.Body className='h-100 p-0'>
        <>
          {dataLoading ? (
            <OdexSpinner />
          ) : (
            <>
              <DataTable
                columns={columns}
                gridName='COREOR'
                data={data.list}
                pagination={undefined}
                selection={undefined}
                sortable={undefined}
                perPage={undefined}
                searchable={undefined}
                showExport={undefined}
                showColumnFilter={undefined}
                customSort={true}
                uniqueFilterKey='coreor'
                maxHeight={'400px'}
              />
              {data.list.length === 0 ? (
                <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                  <span className='fs--1'>No data found</span>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </>
      </Card.Body>
    </Card>
  );
};

export default Coreor;
