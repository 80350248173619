import { Card } from 'react-bootstrap';
import dataTableBgImg from '../../../../src/assets/img/illustrations/cornerRight-1.png';
import React, { ReactNode } from 'react';

const CardWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Card
      className='w-100'
      style={{ minHeight: 100, marginLeft: 'auto', height: 'fit-content' }}
    >
      <Card.Body className='p-0' style={{ minHeight: 500 }}>
        <div
          className='bg-holder bg-card'
          style={{
            backgroundImage: `url(${dataTableBgImg})`,
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}
        />
        {children}
      </Card.Body>
    </Card>
  );
};

export default CardWrapper;
