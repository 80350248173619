import { useSelector } from 'react-redux';

export default function useDataCountry() {
  const profileDetails = useSelector((state) => state.userProfile.profile);

  const linerDataCountry = useSelector(
    (state) => state.userProfile.linerDataCountry
  );

  const linerCountryList = useSelector(
    (state) => state.userProfile.countryList
  );

  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );

  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;

  const storedValidCountry = linerCountryList?.some(
    (item) => item.countryCode === storedCountry
  )
    ? storedCountry
    : linerCountryList?.[0]?.countryCode;
  return (
    storedValidCountry ??
    linerDataCountry ??
    profileDetails.partnerCountry ??
    null
  );
}
