import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';

interface IFilterViewCommon {
  selectedConfig: string;
  initialLoadSelectedConfig: boolean;
  initialLoadLocalStorage: boolean;
}

const initialState: IFilterViewCommon = {
  selectedConfig:
    queryString?.parse?.(window.location.search)?.view?.toString() || '',
  initialLoadSelectedConfig: true,
  initialLoadLocalStorage: true,
};

export const fitlerViewCommonSlice = createSlice({
  name: 'filterViewCommon',
  initialState,
  reducers: {
    updateSelectedConfig: (state, action: PayloadAction<string>) => {
      state.selectedConfig = action.payload;
    },
    updateInitialLoadSelectedConfig: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.initialLoadSelectedConfig = action.payload;
    },
    updateInitialLoadLocalStorage: (state, action: PayloadAction<boolean>) => {
      state.initialLoadLocalStorage = action.payload;
    },
  },
});

export const {
  updateSelectedConfig,
  updateInitialLoadSelectedConfig,
  updateInitialLoadLocalStorage,
} = fitlerViewCommonSlice.actions;

export default fitlerViewCommonSlice.reducer;
