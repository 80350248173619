import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getCurrencyDDList, validateStandaloneInvoiceForPayment } from '../actions/payments';

export interface StandaloneInvoiceDetails {
  rowNumber?: number;
  invoiceId: number | null;
  invoiceNo: string | null;
  invoiceAmount: BigInteger | number | null;
  invoiceCurrency: string | null;
  message?: string | null;
  remove: boolean
  invDt: string;
  shipmentNumber: string;
  blNo: string;
  eximIndicator:string;
}

export interface StandaloneInvoicePaymentSlice {
  data: StandaloneInvoiceDetails[];
  countryDDList: any[]
}

const initialState: StandaloneInvoicePaymentSlice = {
  data: [],
  countryDDList: []
};

const standaloneInvoicePaymentSlice = createSlice({
  name: 'standaloneInvoicePayment',
  initialState,
  reducers: {
    updateStandaloneInvoiceList: (
      state,
      action: PayloadAction<StandaloneInvoiceDetails[]>
    ) => {
      state.data = action.payload;
    },
    clearStandaloneInvoiceListState: (
      state,
      action: PayloadAction<StandaloneInvoiceDetails[]>
    ) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      validateStandaloneInvoiceForPayment.pending,
      (state, action: any) => {
        //nothing
      }
    );
    builder.addCase(
      validateStandaloneInvoiceForPayment.fulfilled,
      (state, action: any) => {
        state.data = state.data.map((v: StandaloneInvoiceDetails) => {
          if (v.rowNumber === action?.payload?.rowNumber) {
            return {
              ...v,
              invoiceId: action?.payload?.invoiceId || null,
              message: action?.payload?.message || null,
              invDt: action?.payload?.invDt || null,
              eximIndicator: action?.payload?.eximIndicator || null,
              shipmentNumber: action?.payload?.shipmentNumber || null,
              blNo: action?.payload?.blNo || null
            };
          } else {
            return v;
          }
        });
      }
    );
    builder.addCase(
      getCurrencyDDList.pending,
      (state, action: any) => {
        //nothing
      }
    );
    builder.addCase(
      getCurrencyDDList.fulfilled,
      (state, action: any) => {
        state.countryDDList = action?.payload
      }
    );
  },
});

export const { updateStandaloneInvoiceList, clearStandaloneInvoiceListState } =
  standaloneInvoicePaymentSlice.actions;
export default standaloneInvoicePaymentSlice.reducer;
