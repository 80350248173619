import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IConfigurationData {
  data: any;
  configurationDataLoading: boolean;
}

interface IColumnConfiguration {
  configurationData: IConfigurationData;
}

const initialState: IColumnConfiguration = {
  configurationData: {
    data: [],
    configurationDataLoading: false,
  },
};

export const columnConfigurationSlice = createSlice({
  name: 'columnConfiguration',
  initialState,
  reducers: {
    updateConfigurationData: (state, action: PayloadAction<any>) => {
      state.configurationData.data = action.payload;
    },
  },
});

export const { updateConfigurationData } = columnConfigurationSlice.actions;

export default columnConfigurationSlice.reducer;
