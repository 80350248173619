import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BLDetailsList } from '../types';
import CustomerMsgTable from '../data/customerMsgTable.json';

interface CustomerMsgTableSlice {
  data: BLDetailsList[];
}

const initialState: CustomerMsgTableSlice = {
  data: CustomerMsgTable.data,
};

export const customerMsgTableSlice = createSlice({
  name: 'customerMsgTable',
  initialState,
  reducers: {
    updateCustomerMsgTable: (state, action: PayloadAction<BLDetailsList[]>) => {
      state.data = action.payload;
    },
  },
});

export const { updateCustomerMsgTable } = customerMsgTableSlice.actions;

export default customerMsgTableSlice.reducer;
