import { useEffect, useState } from "react"

const useCDNCountry = ()=> {
  const [country, setCountry] = useState('');
    useEffect(()=>{
        const countryMeta = document.querySelector<HTMLMetaElement>("meta[name='user-country']");
        setCountry(countryMeta?.content??'');
    },[])
   return country
}
export default useCDNCountry