import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function InfoDiv({children, title, onClick}:{children:any, title:string, onClick?:any}){
    return <div style={{position:'relative'}}>
        <FontAwesomeIcon icon={faInfoCircle} className='infoTooltipIcon' title={title} onClick={()=>{
            if(onClick)onClick()
        }}/>
        {children}
    </div>
}