import { Button, Card, Form, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState, useRef } from 'react';
import DataTable from '../../common/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { IDataTableColumn } from '../../common/types/dataTable';
import {
  getCustomersDetailsByPartnerId,
  saveOrUpdateCustomersDetails,
  fetchLinerNameByCountry,
} from './action/ProfileAccount';
import {
  updateBlDetailsRowColumns,
  updateCustomerMappingListDetails,
  updateToggle,
} from './profileAccountSlice';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';

import {
  faTimes,
  faRedo,
  faPenToSquare,
  faFloppyDisk,
  faTrash,
  faAdd,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';

const CustomerMappingDtls = () => {
  const location2 = useLocation();
  const { t } = useTranslation();
  const { userID } = useParams();
  const dispatch2 = useDispatch<AppDispatch>();
  const profileDetails = useSelector((state: RootState) => state.userProfile.profile);
    
  type FormValues = {
    customerMapId: any,
    customerId: any,
    linerId: any,
    mapCode: string,
    internalCode: string,
    readonly: boolean,
  };

  const navigate = useNavigate();
  //Close Profile account
  const _onCloseForm = () => {
    dispatch2(
      updateToggle({
        ...partnerData,
        toggle: false,
      })
    );
    navigate('/user/profile');
  };
  
  const partnerData = useSelector((state: RootState) => state.partnerData);
  const { custMapId } = useParams();
  const [custMapDetails, setCustMapDetails] = useState<any>(
    custMapId !== '0' && custMapId === undefined
      ? {
          customerMapId: null,
          customerId: partnerData?.data?.mstPartnerId,
          linerId: '',
          mapCode: '',
          internalCode: '',
          readonly: false,
        }
      : partnerData?.data?.customermappingList?.list?.find(
          (v: any) => custMapId === v?.customerMapId?.toString()
        )
  );

  const [linerId, setLinerId] =  useState<any>('');
  const [mapCode, setMapCode] =  useState<any>('');
  const [internalCode, setInternalCode] =  useState<any>('');

  //slider
  useEffect(() => {
    if (matchPath('/user/profile/custMapDetails/:custMapId', location2.pathname)) {
      dispatch2(
        updateBlDetailsRowColumns({
          ...partnerData,
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
          colClass: 'col-lg-6',
          custMapTableClass: 'col-lg-4',
          custMapoutletClass: 'col-lg-8',
        })
      );
      if (custMapId === '0') {
        setCustMapDetails({
          customerMapId: null,
          customerId: partnerData.data.mstPartnerId,
          linerId: '',
          mapCode: '',
          internalCode: '',
          readonly: false,
        });
      } else {
        if(partnerData.data.customermappingList.list.map((v:any)=>v).length === 0 ){
          dispatch2(
              getCustomersDetailsByPartnerId({
                //  blId: blList.list.find((v) => v?.number === blNum)?.id || null,
              })
            )
            .unwrap()
            .then((response: any) => {
                setCustMapDetails(
                  response.customermappingList.list.find(
                    (v: any) => custMapId === v.customerMapId.toString()
                  )
                );
            });
        } else {
          setCustMapDetails(
            partnerData.data.customermappingList.list.find(
              (v: any) => custMapId === v.customerMapId.toString()
            )
          );
        }
        
      }
      dispatch2(
        fetchLinerNameByCountry({
          country_code: profileDetails?.partnerCountry || partnerData?.data?.country?.substring(0, 2) || null,
        })
      );
      
    } else if (matchPath('/user/profile', location2.pathname)) {
      dispatch2(
        updateBlDetailsRowColumns({
          ...partnerData,
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
          colClass: 'col-lg-2',
          custMapTableClass: 'col-lg-12',
          custMapoutletClass: 'd-none',
        })
      );
    }
  }, [location2.pathname]);

  useEffect(() => {
    setLinerId(custMapDetails?.linerId);
    setValue('linerId', custMapDetails?.linerId);
    setMapCode(custMapDetails?.mapCode);
    setValue('mapCode', custMapDetails?.mapCode);
    setInternalCode(custMapDetails?.internalCode);
    setValue('internalCode', custMapDetails?.internalCode);
  }, [custMapDetails]);

  

  const validationSchema2 = Yup.object().shape({
    linerId: Yup.string().nullable()
      .required(t('resource_3:isRequired', { field: 'Liner' }))
      ,
    mapCode: Yup.string().required(
      t('resource_3:isRequired', { field: 'Code' })
    ),
  });

  //Setup
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema2),
    mode: 'onChange',
  });

  //SAVE profile details
  const onSubmit = handleSubmit(async (payload) => {
    const finalList =
      custMapDetails.customerMapId === null
        ? partnerData.data.customermappingList.list
            .map((v: any) => v)
            .concat({
              customerMapId: null,
              customerId: partnerData.data.mstPartnerId,
              linerId: linerId,
              mapCode: mapCode,
              internalCode: internalCode,
              readonly: false,
            })
        : partnerData.data.customermappingList.list.map((v: any) => {
            if (custMapDetails.customerMapId === v.customerMapId) {
              return {
                ...v,
                customerId: partnerData.data.mstPartnerId,
                linerId: linerId,
                mapCode: mapCode,
                internalCode: internalCode,
                readonly: false,
              };
            } else {
              return v;
            }
          });

    try {
      dispatch2(
        saveOrUpdateCustomersDetails({
          ...partnerData,
          updatedCustomermappingList: finalList,
          updatedPartnerAccountList: partnerData.data.partnerAccountList.list.map((v:any)=>v),
        })
      )
        .unwrap()
        .then((response: any) => {
          if (response === '') {
            toast.dark('Please Try Again');
          } else {
            toast.dark(response.message.toString());
            dispatch2(
              updateToggle({
                ...partnerData,
                toggle: true,
              })
            );
            dispatch2(
              updateCustomerMappingListDetails({
                ...partnerData.data.customermappingList,
                list: finalList,
              })
            );
            
            navigate('/user/profile');
          }
        });
    } catch (err) {
      toast.dark('Please Try Again');
    }
  });

  return (
    <Card className='h-100'>
      <Card.Header className='border-bottom d-flex  align-items-center'>
        
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='cursor-pointer fa-lg  mb-1 me-1'
          onClick={_onCloseForm}
        />
        {custMapId === '0'?
        (<h2 className='fs-0 text-primary mb-1'>{'Add Customer Mapping'}</h2>)
        :(<h2 className='fs-0 text-primary mb-1'>{'Customer Mapping'}</h2>)}
        
      </Card.Header>
      <Card.Body >
        <div className='h-100'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div>
              
              <div className='row'>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='customerMappingDtls.linerId'>
                    <Form.Label className='fs--1 '>
                      <span className='mandatory'>*</span>
                      {'Liner'}
                    </Form.Label>
                    <Form.Select
                      {...register('linerId')}
                      className={`fs--1 form-control ${
                        errors.linerId ? 'is-invalid' : ''
                      }`}
                      onChange={(e) => {
                        setLinerId(e.target.value);
                        //_onAccountAliasChange(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (linerId !== '') {
                          setValue('linerId', e.target.value);
                        }
                      }}
                      size='sm'
                      value={linerId}
                    >
                      {/*{partnerData.linerOptions.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}*/}
                      <option key='' value='' >Select</option>
                      <option key='1' value='1' >Hapag-Lloyd</option>
                    </Form.Select>
                    <div className='invalid-feedback'>
                      {errors.linerId?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='customerMappingDtls.mapCode'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {'Customer Code'}
                    </Form.Label>
                    <Form.Control
                      {...register('mapCode')}
                      className={`fs--1 form-control ${
                        errors.mapCode ? 'is-invalid' : ''
                      }`}
                      type='text'
                      onChange={(e) => setMapCode(e.target.value)}
                      size='sm'
                      value={mapCode}
                    />
                    <div className='invalid-feedback'>
                      {errors.mapCode?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                <div className='col-lg-6 mb-3 ' hidden={true}>
                  <Form.Group controlId='customerMappingDtls.internalCode'>
                    <Form.Label className='fs--1'>
                      {'Match Code'}
                    </Form.Label>
                    <Form.Control
                      {...register('internalCode')}
                      className={`fs--1 form-control ${
                        errors.internalCode ? 'is-invalid' : ''
                      }`}
                      type='text'
                      onChange={(e) => setInternalCode(e.target.value)}
                      size='sm'
                      value={internalCode}
                    />
                    <div className='invalid-feedback'>
                      {errors.internalCode?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                
              </div>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-4'>
              <Button
                type='submit'
                className='me-2'
                variant='falcon-danger'
                size='sm'>
                <FontAwesomeIcon icon={faFloppyDisk} className='me-2' />
                {'Save'}
              </Button>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};
export default CustomerMappingDtls;
