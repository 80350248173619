import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';

import { Button, Card, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleLeft,
  faCreditCard,
  faSave,
} from '@fortawesome/free-regular-svg-icons';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { IDataTableColumn } from '../../common/types/dataTable';
import DataTable from '../../common/components/DataTable';
import {
  StandaloneInvoiceDetails,
  clearStandaloneInvoiceListState,
  updateStandaloneInvoiceList,
} from './slices/standaloneInvoicePaymentSlice';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  faAdd,
  faCheck,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { number } from 'yup';
import {
  addStandaloneInvoice,
  validateStandaloneInvoiceForPayment,
  getCurrencyDDList
} from './actions/payments';
import { toast } from 'react-toastify';

import dataTableBgImgFoBlDetails from '../../../../src/assets/img/illustrations/corner-1.png';
import dataTableBgImgFoBlDetailsRight from '../../../../src/assets/img/illustrations/cornerRight-1.png';
import { useLoading } from '../../../middleware/loadingMiddleware';

const StandaloneInvoicePayment = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loadingCountry = useLoading(['getCurrencyDDList'])
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentNumber, setCurrentNumber] = useState(1);
  const standaloneInvoiceList = useSelector(
    (state: RootState) => state.standaloneInvoice.data
  );
  const countryDDList = useSelector(
    (state: RootState) => state.standaloneInvoice.countryDDList
  );
  const rbac = useSelector((state: RootState) => state.userProfile);
  if (rbac.rbacDetails.standaloneInvPymtCUD !== 'Y') {
    navigate('/payment');
  }
  const updateStandaloneInvoiceTable = (
    rowNumber: number,
    key: string,
    value: any
  ) => {
    const updatedData: StandaloneInvoiceDetails[] = standaloneInvoiceList.map(
      (invoice: any) => {
        if (rowNumber === invoice.rowNumber) {
          return {
            ...invoice,
            [key]: value,
          };
        } else {
          return invoice;
        }
      }
    );
    dispatch(updateStandaloneInvoiceList(updatedData));
    if (
      standaloneInvoiceList.find(
        (v: StandaloneInvoiceDetails) => v.rowNumber === rowNumber
      )
    ) {
      setTimeout(() => {
        validateInvoice(
          updatedData?.find(
            (v: StandaloneInvoiceDetails) => v.rowNumber === rowNumber
          ) || null
        );
      }, 200);
    }
  };
  const isEditable = matchPath(location.pathname, '/payment/addInvoice')
  const clearRows = () => {
    const newData: StandaloneInvoiceDetails = {
      rowNumber: currentNumber + 1,
      invoiceId: null,
      invoiceNo: '',
      invoiceAmount: null,
      invoiceCurrency:
        standaloneInvoiceList.find(
          (v: StandaloneInvoiceDetails) =>
            v.invoiceId != null && v.invoiceCurrency != ''
        )?.invoiceCurrency || countryDDList[0]?.currencyCode || 'USD',
      message: null,
      remove:true,
      invDt:'',
      eximIndicator:'',
      shipmentNumber:'',
      blNo:''
    };
    dispatch(updateStandaloneInvoiceList([newData]));

    setCurrentNumber(currentNumber + 1);
  };
  const addRow = () => {
    const newData: StandaloneInvoiceDetails = {
      rowNumber: currentNumber + 1,
      invoiceId: null,
      invoiceNo: '',
      invoiceAmount: null,
      invoiceCurrency:
        standaloneInvoiceList.find(
          (v: StandaloneInvoiceDetails) =>
            v.invoiceId != null && v.invoiceCurrency != ''
        )?.invoiceCurrency || countryDDList[0]?.currencyCode ||'USD',
      message: null,
      remove: true,
      invDt:'',
      eximIndicator:'',
      shipmentNumber:'',
      blNo:''
    };
    if (standaloneInvoiceList.length === 0) {
      dispatch(updateStandaloneInvoiceList([newData]));
    } else {
      dispatch(
        updateStandaloneInvoiceList([newData, ...standaloneInvoiceList])
      );
    }

    setCurrentNumber(currentNumber + 1);
  };
  const deleteRow = (rowNumber:number) => {
    const newData: StandaloneInvoiceDetails = {
      rowNumber: currentNumber + 1,
      invoiceId: null,
      invoiceNo: '',
      invoiceAmount: null,
      invoiceCurrency:
        standaloneInvoiceList.find(
          (v: StandaloneInvoiceDetails) =>
            v.invoiceId != null && v.invoiceCurrency != ''
        )?.invoiceCurrency || countryDDList[0]?.currencyCode || 'USD',
      message: null,
      remove: true,
      invDt:'',
      eximIndicator:'',
      shipmentNumber:'',
      blNo:''
    };
    const data = standaloneInvoiceList;
    const newList = data.filter((row)=> row.rowNumber != rowNumber);
    if (newList?.length < 1) {
      setCurrentNumber(currentNumber + 1);
      dispatch(updateStandaloneInvoiceList([{...newData}]))
    }else{
      dispatch(updateStandaloneInvoiceList(newList));
    }
  };
  const deleteRows = () => {
    const newData: StandaloneInvoiceDetails = {
      rowNumber: currentNumber + 1,
      invoiceId: null,
      invoiceNo: '',
      invoiceAmount: null,
      invoiceCurrency:
        standaloneInvoiceList.find(
          (v: StandaloneInvoiceDetails) =>
            v.invoiceId != null && v.invoiceCurrency != ''
        )?.invoiceCurrency || countryDDList[0]?.currencyCode || 'USD',
      message: null,
      remove: true,
      invDt:'',
      eximIndicator:'',
      shipmentNumber:'',
      blNo:''
    };
    // const data = standaloneInvoiceList.filter(
    //   (v: StandaloneInvoiceDetails) => v.check == false
    // );
    // if (data.length < 1) {
    //   setCurrentNumber(currentNumber + 1);
    //   dispatch(updateStandaloneInvoiceList([{...newData}]))
    // }
    // else{dispatch(updateStandaloneInvoiceList(data));}

  };
  useEffect(() => {
    if(!countryDDList || countryDDList.length < 1)dispatch(getCurrencyDDList());
  }, []);
  useEffect(()=>{
    if (location.pathname !== '/payment/addInvoice/invoicePayment') {
      clearRows();
    }
  },[countryDDList])

  const _onCloseForm = () => {
    navigate('/payment');
  };
  const TableCellInvoiceNumber = (rowData: any) => {
    const { rowNumber, invoiceNo } = rowData.row.original;
    if (isEditable) {
      return (
        <input
          type='text'
          className='fs--2 form-control'
          disabled={
            !isEditable
          }
          defaultValue={invoiceNo || ''}
          onBlur={(e) => {
            updateStandaloneInvoiceTable(
              rowNumber,
              'invoiceNo',
              e.target.value.trim()
            );
          }}
        />
      );
    } else {
      return <span>{invoiceNo}</span>;
    }
  };
  const TableCellInvoiceAmount = (rowData: any) => {
    const { rowNumber, invoiceAmount } = rowData.row.original;
    if (isEditable) {
      return (
        <input
          type='number'
          className='fs--2 form-control'
          disabled={!isEditable}
          title={'Currency'}
          defaultValue={invoiceAmount || null}
          onBlur={(e) => {
            const floatValue = parseFloat(e.target.value);
            if (floatValue >= 0.0 && floatValue <= 99999999.99) {
              updateStandaloneInvoiceTable(
                rowNumber,
                'invoiceAmount',
                floatValue != null && floatValue.toString().trim() === ''
                  ? null
                  : floatValue
              );
            } else {
              updateStandaloneInvoiceTable(rowNumber, 'invoiceAmount', null);
            }
          }}
        />
      );
    } else {
      return <span>{invoiceAmount}</span>;
    }
  };

  const TableCellInvoiceCurrency = (rowData: any) => {
    const { rowNumber, invoiceCurrency } = rowData.row.original;
    // return (
    //     <input
    //     type='text'
    //     className='fs--2 form-control'
    //     disabled={matchPath('/payment/addInvoice', location.pathname)?false:true}
    //     defaultValue={invoiceCurrency || ''}
    //     onBlur={(e)=>{
    //         updateStandaloneInvoiceTable(rowNumber, "invoiceCurrency", e.target.value);
    //     }}
    //    />
    // );
    if (isEditable) {
      return (
        <Form.Select
          disabled={
            !isEditable || loadingCountry
          }
          value={invoiceCurrency || ''}
          onChange={(e) => {
            updateStandaloneInvoiceTable(
              rowNumber,
              'invoiceCurrency',
              e.target.value
            );
          }}
          className='fs--1 form-control  form-select'>
          {
            countryDDList.map((r:any) => {
                return (
                    <>
                        <option value={r?.currencyCode} key={r?.currencyShortName}>
                            {r?.currencyShortName}
                        </option>
                    </>
                )
            })
          }
        </Form.Select>
      );
    } else {
      return <span>{invoiceCurrency}</span>;
    }
  };
  const invoiceTableColumnConfig: IDataTableColumn[] = [
    {
      accessor: 'rowNumber',
      Header: 'rowNumber',
    },
    {
      accessor: 'invoiceId',
      Header: 'invoiceId',
    },
    {
      accessor: 'invoiceNo',
      Header: t('resource_2:invoiceNo'),
      width: 150,
      maxWidth: 180,
      Cell: TableCellInvoiceNumber,
    },
    {
      accessor: 'invoiceAmount',
      Header: t('resource_1:amount'),
      width: 180,
      maxWidth: 180,
      Cell: TableCellInvoiceAmount,
    },
    {
      accessor: 'invoiceCurrency',
      Header: t('resource_1:currency'),
      width: 150,
      maxWidth: 150,
      Cell: TableCellInvoiceCurrency,
    },
    {
      accessor: 'remove',
      Header: '',
      width: 40,
      maxWidth: 40,
      Cell: (rowData: any) => {
        const { remove, rowNumber} = rowData.row.original;
        return (
          <Button 
          variant='falcon-warning'
          style={{background:'transparent', border:'none', boxShadow:'none', padding:'5px'}}
          disabled={!remove || ! isEditable}
          onClick={()=>{deleteRow(rowNumber)}}
          >
            <FontAwesomeIcon icon={faTrash} size='sm' />
          </Button>
          // <input
          //   type='checkbox'
          //   className='form-check-input'
          //   style={{
          //     cursor: 'pointer',
          //     marginTop: '7px',
          //   }}
          //   onChange={(e: ChangeEvent<HTMLInputElement>) => {
          //     const data = standaloneInvoiceList.filter(
          //       (v: StandaloneInvoiceDetails) => !v.check
          //     );
          //     const value: boolean = e?.target?.checked;
          //     if (data?.length >= 0 || check) {
          //       updateStandaloneInvoiceTable(rowNumber, 'check', !check);
          //     }
          //   }}
          //   checked={check}></input>
        );
      },
      disableSort: true,
    },
    {
      accessor: 'message',
      Header: '',
      width: 1,
      maxWidth: 1,
      Cell: (rowData: any) => {
        const { rowNumber, message } = rowData.row.original;
        return <span className='mandatory'>{message}</span>;
      },
    },
  ];

  const isvalidInvoices =
    standaloneInvoiceList.filter(
      (v: StandaloneInvoiceDetails) => v.invoiceId != null
    ).length === standaloneInvoiceList.length;

  const validateInvoice = (invoice: StandaloneInvoiceDetails | null) => {
    if (invoice != null) {
      if (
        invoice.invoiceNo != null &&
        invoice.invoiceNo.trim() != '' &&
        invoice.invoiceCurrency != null &&
        invoice.invoiceCurrency.trim() != '' &&
        invoice.invoiceAmount != null &&
        !Number.isNaN(invoice.invoiceAmount) &&
        invoice.invoiceAmount.toString().trim() != ''
      ) {
        dispatch(validateStandaloneInvoiceForPayment(invoice))
          .unwrap()
          .then((responseData: any) => {
            if (responseData) {
              if (responseData.invoiceId == null) {
                toast.dark(responseData.message);
              }
            }
          });
      }
    }
  };
  const saveOrPayInvoice = (type: string) => {
    dispatch(addStandaloneInvoice(standaloneInvoiceList))
      .unwrap()
      .then((responseData: any) => {
        if (responseData) {
          if (
            responseData &&
            responseData?.responseCode?.toString().toUpperCase() == 'SUCCESS'
          ) {
            toast.success(responseData.responseMsg);
            if (type == 'SAVE') {
              navigate('/payment');
            } else if (type == 'PAY') {
              navigate('/payment/addInvoice/invoicePayment');
            }
          } else {
            toast.error(responseData.responseMsg);
          }
        }
      });
  };

  return (
    <Card className='w-100 h-100 overflow-auto border-right d-flex flex-column'>
      <Card.Header className='d-flex align-items-center '>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          style={{ zIndex: 10 }}
          onClick={_onCloseForm}
        />

        <h2 className='fs-0 text-primary mb-1'>
          {t('resource_2:invoiceDetails')}
        </h2>
      </Card.Header>
      <Card.Body>
        <div
          className='bg-holder bg-card bg-card-blDetails'
          style={{
            backgroundImage: `url(${
              location.pathname.includes('invoicePayment')
                ? dataTableBgImgFoBlDetailsRight
                : dataTableBgImgFoBlDetails
            })`,
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}
        />
        <Form.Group as={Row} controlId='payableAmount.input'>
          <Form.Label column lg={4} className='fs--1'>
            {'Total Invoice Amount'}
          </Form.Label>
          <Col lg={7}>
            <InputGroup>
              <Col lg={5}>
                <Form.Select
                  value={
                    standaloneInvoiceList.filter(
                      (v: StandaloneInvoiceDetails) =>
                        v.invoiceCurrency ==
                        standaloneInvoiceList[0]?.invoiceCurrency
                    )[0]?.invoiceCurrency || ''
                  }
                  disabled={true}>
                  {
                      countryDDList.map((r:any) => {
                            return (
                                <>
                                    <option value={r?.currencyCode} key={r?.currencyShortName}>
                                        {r?.currencyShortName}
                                    </option>
                                </>
                            )
                        })
                  }
                </Form.Select>
              </Col>
              <Col lg={7}>
                <Form.Control
                  aria-label={t('resource_2:payableAmount')}
                  aria-describedby='payable-amount'
                  value={standaloneInvoiceList
                    .filter(
                      (v: StandaloneInvoiceDetails) =>
                        v.invoiceCurrency ==
                        standaloneInvoiceList[0]?.invoiceCurrency
                    )
                    .reduce(
                      (total: any, v: StandaloneInvoiceDetails) =>
                        v.invoiceAmount != null
                          ? (total =
                              total + parseFloat(v.invoiceAmount.toString()))
                          : total === 0.0
                          ? 0.0
                          : total,
                      0.0
                    )}
                  disabled={true}
                />
              </Col>
            </InputGroup>
          </Col>
          <Col lg={1}></Col>
        </Form.Group>
        <hr />
        <div className=' odex-z-index--2' style={{ zIndex: 1 }}>
          <Button
            className='filters-header-odex-md btn btn-falcon-warning p-0'
            variant='falcon-warning'
            style={{
              width: '30px',
              height: '30px',
              marginRight: '10px',
              marginBottom: '10px',
            }}
            size='sm'
            onClick={addRow}
            disabled={!isEditable || loadingCountry}>
            <FontAwesomeIcon icon={faAdd} size='sm' />
          </Button>
          {/* <Button
            className='filters-header-odex-md btn btn-falcon-warning p-0'
            variant='falcon-warning'
            style={{
              width: '30px',
              height: '30px',
              marginRight: '10px',
              marginBottom: '10px',
            }}
            size='sm'
            disabled={
              matchPath('/payment/addInvoice', location.pathname)
                ? standaloneInvoiceList.length > 1 &&
                  standaloneInvoiceList.filter(
                    (v: StandaloneInvoiceDetails) => v.check == true
                  ).length > 0
                  ? false
                  : true
                : true
            }
            onClick={deleteRows}>
            <FontAwesomeIcon icon={faTrash} size='sm' />
          </Button> */}
          {/* {standaloneInvoiceList.length > 0 && location.pathname.includes('/payment/addInvoice') ? (
                        <div
                        className='falcon-primary'>
                            {standaloneInvoiceList.filter((v:StandaloneInvoiceDetails)=>v.invoiceCurrency ==standaloneInvoiceList[0]?.invoiceCurrency)[0].invoiceCurrency || ''}{' '}
                            <span >
                            {standaloneInvoiceList.filter((v:StandaloneInvoiceDetails)=>v.invoiceCurrency ==standaloneInvoiceList[0]?.invoiceCurrency).reduce((total:any,v:StandaloneInvoiceDetails)=>v.invoiceAmount != null ?total = total + parseFloat(v.invoiceAmount.toString()): total === 0? 0:total,0)}
                            ({standaloneInvoiceList.filter((v:StandaloneInvoiceDetails)=>v.invoiceCurrency ==standaloneInvoiceList[0]?.invoiceCurrency).length})
                            </span>
                        </div>
                    ) : (
                    ''
                    )} */}
          <DataTable
            gridName='STANDALONE'
            columns={invoiceTableColumnConfig}
            data={standaloneInvoiceList || []}
            shortPrepend='st_'
            perPage={100}
            sortable={true}
            searchable={false}
            showColumnFilter={false}
            selection={false}
            pagination={false}
            seekPagination={false}
            showExport={false}
            localFilter={true}
            uniqueFilterKey='standalone'
            maxHeight='30vh'
            staticHiddenCols={['rowNumber', 'invoiceId', 'message']}
          />
          {standaloneInvoiceList.length > 0 && (
            <div className='w-100 h-100 d-flex justify-content-center align-items-center mt-4'>
              <Button
                variant='falcon-primary'
                className='me-4 approve'
                size='sm'
                disabled={
                  isEditable
                    ? !isvalidInvoices
                    : true
                }
                onClick={() => {
                  saveOrPayInvoice('SAVE');
                }}>
                <FontAwesomeIcon icon={faSave} className='me-2 mt-1 ' />
                <span>{t('resource_1:save')}</span>
              </Button>
              <Button
                variant='falcon-primary'
                className='me-2'
                size='sm'
                disabled={
                  isEditable
                    ? !isvalidInvoices
                    : true
                }
                onClick={() => {
                  saveOrPayInvoice('PAY');
                }}>
                <FontAwesomeIcon icon={faCreditCard} className='me-2 approve' />
                {t('resource_1:pay')}
              </Button>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
export default StandaloneInvoicePayment;
