import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getColumnViews,
  saveColumnViewSettings,
} from '../../../actions/dataTable';

interface IFilterModalState {
  filterKey: string;
  filterType: string;
  options: any;
  numberFilter: any;
}

interface IDataTableSlice {
  filterState: any;
  dateState: any;
  dateRange: any;
  localFilterState: any;
  selectedShortcut: string;
  selectedShortcutPreview: string;
  sortState: {
    key: string | null;
    type?: string;
    value?: any;
  };
  sortModal: boolean;
  selectedSortKey: string;
  filterModalState: IFilterModalState;
  showFilterModal: boolean;
  selectedUniqueKeyFilter: string;
  loadingGridConfigs: boolean;
  grids: any[];
  invoiceGrids: any[];
  paymentGrids: any[];
  receiptGrids: any[];
  getInGetOutGrids: any[];
  reportGrids: any[];
  mappingGrids: any[];
  instructionsGrids: any[];
  registrationMasterGrid: any;
  paymentFundGrids: any[];
  userGrids: any[];
  standingInstructionGrids: any[];
  GridColumn: any;
  selectedConfigId: any;
  selectedViewFiled: {};
  showConfigForm: boolean;
  isLoadingConfigUpdate: boolean;
  enableEditRemoveShared: boolean;
  enableEditRemoveSystem: boolean;

  systemEditRemoveView: boolean;
  paramPaginationVal: any;
  hiddenColsDataTable: any;
  colsDataTable: any;
  setColsDataTable: string[];
  refreshDashboard: boolean;
}

const initialState: IDataTableSlice = {
  filterState: {},
  dateState: '',
  dateRange: {},
  localFilterState: {},
  selectedShortcut: '',
  selectedShortcutPreview: '',
  sortState: {
    key: null,
    type: undefined,
  },
  sortModal: false,
  selectedSortKey: '',
  filterModalState: {
    filterKey: '',
    filterType: '',
    options: [],
    numberFilter: {},
  },
  showFilterModal: false,
  selectedUniqueKeyFilter: '',
  loadingGridConfigs: false,
  enableEditRemoveShared: false,
  enableEditRemoveSystem: false,
  grids: [],
  invoiceGrids: [],
  paymentGrids: [],
  receiptGrids:[],
  getInGetOutGrids:[],
  reportGrids: [],
  mappingGrids: [],
  instructionsGrids: [],
  registrationMasterGrid: [],
  paymentFundGrids: [],
  userGrids: [],
  standingInstructionGrids: [],
  selectedConfigId: {},
  showConfigForm: false,
  isLoadingConfigUpdate: false,
  systemEditRemoveView: true,
  paramPaginationVal: {},
  hiddenColsDataTable: {},
  colsDataTable: {},
  setColsDataTable: [],
  GridColumn: [],
  selectedViewFiled: {},
  refreshDashboard: false
};

export const dataTableSlice = createSlice({
  name: 'dataTable',
  initialState,
  reducers: {
    updateDataTableFilterState: (state, action: PayloadAction<any>) => {
      state.filterState = action.payload;
    },
    updateDataTableSortState: (state, action: PayloadAction<any>) => {
      state.sortState = action.payload;
    },
    updateDateState: (state, action: PayloadAction<any>) => {
      state.dateState = action.payload;
    },

    updateDateRange: (state, action: PayloadAction<any>) => {
      state.dateRange = action.payload;
    },

    updateSelectedShorcut: (state, action: PayloadAction<string>) => {
      state.selectedShortcut = action.payload;
    },
    updateSelectedShortcutPreview: (state, action: PayloadAction<string>) => {
      state.selectedShortcutPreview = action.payload;
    },
    updateSortStateDataTable: (state, action: PayloadAction<any>) => {
      state.sortState = action.payload;
    },
    updateSortModalDT: (state, action: PayloadAction<boolean>) => {
      state.sortModal = action.payload;
    },
    updateSelectedSortKeyDT: (state, action: PayloadAction<string>) => {
      state.selectedSortKey = action.payload;
    },
    toggleFilterModalDT: (state, action: PayloadAction<boolean>) => {
      state.showFilterModal = action.payload;
    },

    updateFilterModalStateDT: (
      state,
      action: PayloadAction<IFilterModalState>
    ) => {
      state.filterModalState = action.payload;
    },
    updateSelectedUniqueKeyFilter: (state, action: PayloadAction<string>) => {
      state.selectedUniqueKeyFilter = action.payload;
    },
    UpdateEnableEditRemoveSystem: (state, action: PayloadAction<boolean>) => {
      state.enableEditRemoveSystem = action.payload;
    },
    UpdateEnableEditRemoveShared: (state, action: PayloadAction<boolean>) => {
      state.enableEditRemoveShared = action.payload;
    },
    updateSelectedConfigId: (state, action: PayloadAction<any>) => {
      state.selectedConfigId = action.payload;
    },

    updateSelectedViewFiled: (state, action: PayloadAction<any>) => {
      state.selectedViewFiled = action.payload;
    },

    updateShowConfigForm: (state, action: PayloadAction<boolean>) => {
      state.showConfigForm = action.payload;
    },
    updateParams: (state, action: PayloadAction<any>) => {
      state.paramPaginationVal = action.payload;
    },
    updateHiddenColsDataTable: (state, action: PayloadAction<any>) => {
      state.hiddenColsDataTable = action.payload;
    },
    updateColsDataTable: (state, action: PayloadAction<any>) => {
      state.colsDataTable = { ...state.colsDataTable, ...action.payload };
    },
    updateSetColsDataTable: (state, action: PayloadAction<string[]>) => {
      state.setColsDataTable = action.payload;
    },
    updateDashbaordRefresh:(state, action: PayloadAction<boolean>)=>{
      state.refreshDashboard = action.payload
    },
    clearGrids: (state) =>{
      state.grids = []
      state.instructionsGrids = []
      state.invoiceGrids = []
      state.registrationMasterGrid = []
      state.paymentFundGrids = []
      state.paymentGrids = []
      state.receiptGrids = []
      state.getInGetOutGrids = []
      state.userGrids = []
      state.standingInstructionGrids = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getColumnViews.pending, (state, action) => {
      state.loadingGridConfigs = true;
    });

    builder.addCase(getColumnViews.fulfilled, (state, action) => {
      state.loadingGridConfigs = false;

      switch (action?.payload?.gridName) {
        case 'BL_DASHBOARD_LINER':
          state.grids = action?.payload?.data;
          break;
        case 'BL_DASHBOARD_CUSTOMER':
            state.grids = action?.payload?.data;
            break;
        case 'INV_DASHBOARD':
          state.invoiceGrids = action?.payload?.data;
          break;
        case 'INST_DASHBOARD':
          state.instructionsGrids = action.payload.data;
          break;

        case 'REGISTRATION_DASHBOARD':
          state.registrationMasterGrid = action.payload.data;
          break;

        case 'PAYMENT_FUNDING':
          state.paymentFundGrids = action?.payload?.data;
          break;

        case 'USER_DASHBOARD':
          state.userGrids = action?.payload.data;
          break;

        case 'STANDING_INSTRUCTION':
          state.standingInstructionGrids = action?.payload.data;
          break;
        
        case "PAYMENT_DASHBOARD":
          state.paymentGrids = action?.payload.data
          break
        case "PAYMENT_RECEIPT":
            state.receiptGrids = action?.payload.data
          break
        case "PICKUP_LOC_INSTRACT_REPORT":
          state.reportGrids = action?.payload.data
          break
        case "CUSTOMER_DASHBOARD":
          state.mappingGrids = action?.payload.data
          break
        case "GET_IN_OUT_DASHBOARD":
            state.getInGetOutGrids = action?.payload.data
          break    
        default:
          break; 
      }
    });

    builder.addCase(saveColumnViewSettings.pending, (state, action) => {
      state.isLoadingConfigUpdate = true;
    });
    builder.addCase(saveColumnViewSettings.fulfilled, (state, action) => {
      state.isLoadingConfigUpdate = false;
    });
  },
});

export const {
  updateDataTableFilterState,
  updateDateState,
  updateDateRange,
  updateSelectedUniqueKeyFilter,
  updateSelectedShorcut,
  updateSelectedShortcutPreview,
  updateSortStateDataTable,
  updateSortModalDT,
  updateSelectedSortKeyDT,
  toggleFilterModalDT,
  updateFilterModalStateDT,
  updateSelectedConfigId,
  UpdateEnableEditRemoveSystem,
  UpdateEnableEditRemoveShared,
  updateParams,
  updateHiddenColsDataTable,
  updateColsDataTable,
  updateSetColsDataTable,
  clearGrids,
  updateDashbaordRefresh
} = dataTableSlice.actions;

export default dataTableSlice.reducer;
