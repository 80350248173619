import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getCoreror } from "../dashboard/actions/instruction";

interface ICoreorList {
  list: any[];
  next: string | null;
  previous: string | null;
}

export interface ICoreor {
  data: ICoreorList;
  dataLoading: boolean;
}

const initialState: ICoreor = {
  data: {
    list: [],
    next: null,
    previous: null,
  },
  dataLoading: true,
};

export const coreorSlice = createSlice({
  name: "coreor",
  initialState,
  reducers: {
    updateCoreorList: (state, action: PayloadAction<ICoreorList>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCoreror.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getCoreror.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
  },
});
export const { updateCoreorList } = coreorSlice.actions;

export default coreorSlice.reducer;
