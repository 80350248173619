import { useParams } from "react-router-dom"
import { AESDecrypt } from "../../encrypt-util"
import InvoiceChanges from "../../app/features/blRequestInstruction/components/InvoiceChanges"
import PickUpInstruction from "../../app/features/blRequestInstruction/components/PickUpInstruction"
import CarrierHaulage from "../../app/features/blRequestInstruction/components/CarrierHaulage"
import NominateAgent from "../../app/features/dashboard/components/NominateAgent"
import ReleaseInstruction from "../../app/features/blRequestInstruction/components/ReleaseInstruction"
import Coreor from "../../app/features/coreor/Coreor"
import OblDetails from "../../app/common/components/OblDetails"
import BlanketDelegation from "../../app/features/dashboard/components/BlanketDelegation"
import OverstayExtensionRequest from "../../app/features/blRequestInstruction/components/OverstayExtensionRequest"

const map = {
    802 : <InvoiceChanges />,
    808 : <PickUpInstruction />,
    801 : <CarrierHaulage />,
    811 : <NominateAgent />,
    805 : <NominateAgent />,
    812 : <NominateAgent />,
    814 : <NominateAgent />,
    813 : <NominateAgent />,
    815 : <NominateAgent />,
    804 : <ReleaseInstruction />,
    205 : <Coreor />,
    710 : <OblDetails />,
    705 : <OblDetails />,
    201 : <OblDetails />,
    810 : <BlanketDelegation />,
    816 : <OverstayExtensionRequest/>

}
const InstructionMapper =()=>{
    const {encrypt} = useParams()
    const instruction = AESDecrypt(encrypt)
    return map[instruction]
}

export default InstructionMapper