import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BLDetailsList } from '../../dashboard/types';
import { PaymentBlDetails } from '../../payment/paymentSlice';

interface MultiBlPaymentTypes {
  selectedRowsMultiPay: PaymentBlDetails[];
  selectedRowsMultiPayTable: any;
  selectedAmountAll: any;
  selectedRowsInvoice: any[];
}

const initialState: MultiBlPaymentTypes = {
  selectedRowsMultiPay: [],
  selectedRowsMultiPayTable: {},
  selectedAmountAll: [],
  selectedRowsInvoice: [],
};

export const multiBlPaymentSlice = createSlice({
  name: 'multiBlPayment',
  initialState,
  reducers: {
    updateSelectedBlListMultiPay: (
      state,
      action: PayloadAction<PaymentBlDetails[]>
    ) => {
      state.selectedRowsMultiPay = action.payload;
    },
    updateSelectedBlListMultiPayTable: (state, action: PayloadAction<any>) => {
      state.selectedRowsMultiPayTable = action.payload;
    },
    updateSelectedAmount: (state, action: PayloadAction<any>) => {
      state.selectedAmountAll = action.payload;
    },
    updateSelectedRowsInvoice: (state, action: PayloadAction<any[]>) => {
      state.selectedRowsInvoice = action.payload;
    },
  },
});

export const {
  updateSelectedBlListMultiPay,
  updateSelectedBlListMultiPayTable,
  updateSelectedAmount,
  updateSelectedRowsInvoice,
} = multiBlPaymentSlice.actions;

export default multiBlPaymentSlice.reducer;
