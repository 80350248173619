import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BLDetailsList } from '../types';
import DocPendingData from '../data/docPending.json';

interface DocPendingTableSlice {
  data: BLDetailsList[];
}

const initialState: DocPendingTableSlice = {
  data: DocPendingData.data,
};

export const docPendingTableSlice = createSlice({
  name: 'docPendingTable',
  initialState,
  reducers: {
    updateDocPendingTableSlice: (
      state,
      action: PayloadAction<BLDetailsList[]>
    ) => {
      state.data = action.payload;
    },
  },
});

export const { updateDocPendingTableSlice } = docPendingTableSlice.actions;

export default docPendingTableSlice.reducer;
