import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getReturnContainer,
  addReturnInstruction,
} from '../../../dashboard/actions/instruction';

interface IReturnForm {
  senderEmail: string | null;
  intendedDate: string | null;
  truckerCode: string | null;
  returnLocation: string | null;
  deliveryName: String | null;
  country: string | null;
  status: string | null;
}

interface IReturnContainerDetails {
  //vesselType: any;
  containerNo: string | null;
  IntendedDate: string | null;
  truckerCode: string | null;
  returnLocation: string | null;
  deliveryName: String | null;
}

interface IReturnList {
  list: any[];
  next: string | null;
  previous: string | null;
}

export interface IReturnDetails {
  data: IReturnList;
  isReturnDetailsLoading: boolean;
  dataLoading: boolean;
  returnForm: IReturnForm;
  selectedDate: any;
  selectedReturnLocation:any;
  selectedDeliveryName: any;
}

const initialState: IReturnDetails = {
  data: {
    list: [],
    next: null,
    previous: null,
  },
  dataLoading: true,
  isReturnDetailsLoading: false,
  returnForm: {
    senderEmail: '',
    intendedDate: '',
    truckerCode: '',
    country: '',
    status: '',
    returnLocation: '',
    deliveryName: '',
  },
  selectedDate: {},
  selectedReturnLocation: {},
  selectedDeliveryName: {},
};

export const ReturnSlice = createSlice({
  name: 'return',
  initialState,
  reducers: {
    updateReturnList: (state, action: PayloadAction<IReturnList>) => {
      state.data = action.payload;
    },
    updateReturnSelectedDateDV: (state, action: PayloadAction<any>) => {
      state.selectedDate = action.payload;
    },
    updateSelectedLocation: (state, action: PayloadAction<any>) => {
      state.selectedReturnLocation = action.payload;
    },
    updateSelectedDeliveryName: (state, action: PayloadAction<any>) => {
      state.selectedDeliveryName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addReturnInstruction.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addReturnInstruction.fulfilled, (state, action) => {
      state.dataLoading = false;
    });
    builder.addCase(getReturnContainer.pending, (state, action) => {
      state.data = {
        list: [],
        next: null,
        previous: null,
      };
      state.dataLoading = true;
    });
    builder.addCase(getReturnContainer.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });

    // builder.addCase(getDemurrageView.pending, (state, action) => {
    //  state.dataLoading = true;
    // });
    /// builder.addCase(getDemurrageView.fulfilled, (state: any, action) => {
    //  state.dataLoading = false;
    //  state.demurrageForm = {
    //number: action?.payload?.number || '',
    //    senderEmail: action?.payload.data?.customerEmailId || '',
    // IntendedDate:action?.payload.data.
    // };
    //});
  },
});
export const { updateReturnList, updateReturnSelectedDateDV,updateSelectedLocation,updateSelectedDeliveryName } =
ReturnSlice.actions;

export default ReturnSlice.reducer;
