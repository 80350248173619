import { createSlice } from '@reduxjs/toolkit';
import { getReportTypes } from '../dashboard/actions/instruction';
import List from 'react-widgets/cjs/List';
interface IDashSlice {
  showColumnSettings:boolean;
  selectedInstruct:string;
  reportList:Array<any>
}

const initialState: IDashSlice = {
  showColumnSettings:false,
  selectedInstruct:'',
  reportList:[]
};

export const reportDashboardSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {
    toggleColumnSettingsPayDash:(state,action)=>{
        state.showColumnSettings = action.payload
    },
    selectReportInstruction:(state, action)=>{
      state.selectedInstruct = action.payload
    }
  },
  extraReducers:(builder)=>{
    builder.addCase(getReportTypes.fulfilled, (state, action)=>{
      state.reportList = action.payload
    })
  }
});

export const { 
  toggleColumnSettingsPayDash,
  selectReportInstruction

} = reportDashboardSlice.actions;

export default reportDashboardSlice.reducer;
