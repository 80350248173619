import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface CreditBalanceDetails {
  paymentRef: string;
  time: string;
  amount: string;
}

interface CreditBalance {
  data: CreditBalanceDetails[];
  selectedCreditData?:any;
}

const initialState: CreditBalance = {
  data: [
    {
      paymentRef: 'PTMNO2366',
      time: '18-02-2023 05:30',
      amount: 'USD 100',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '10-02-2023 01:00',
      amount: 'USD 200',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '11-02-2023 03:30',
      amount: 'USD 100',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '12-02-2023 05:20',
      amount: 'USD 200',
    },
  ],
  selectedCreditData:[]
};

export const creditBalanceSlice = createSlice({
  name: 'creditBalance',
  initialState,
  reducers: {
    updateCreditBalanceList: (
      state,
      action: PayloadAction<CreditBalanceDetails[]>
    ) => {
      state.data = action.payload;
    },
    selectedCredit: (
      state,
      action: PayloadAction<any>
    ) => {
      state.selectedCreditData = action.payload;
    },
  },
});

export const { updateCreditBalanceList,selectedCredit } = creditBalanceSlice.actions;

export default creditBalanceSlice.reducer;
