import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateDataTableFilterState } from './slices/DataTable';
import moment from 'moment';
import { getDateTimeFormat } from '../../../../helpers/dateFormat';
import { updateDateState } from './slices/DataTable';
import { updateDateRange } from './slices/DataTable';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const DateRangeFilter = ({
  filterState123,
  value,
  objectKey,
  uniqueFilterKey,
  closeFilter,
}) => {
  const filterState = useSelector((state) => state.dataTable.filterState);

  const dispatch = useDispatch();

  const [isDate, setDate] = useState(true);
  const [isDay, setDay] = useState(false);
  const [isMinute, setMinute] = useState(false);
  const [relativeDays, setRelativeDays] = useState(0);
  const [relativeMinutes, setRelativeMinutes] = useState(0);

  const [isDayButton, setDayButton] = useState(false);
  const [isMinuteButton, setMinuteButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [relativeDatePick, setRelativeDatePick] = useState({
    from: filterState?.[uniqueFilterKey]?.[objectKey]?.from
      ? new Date(filterState?.[uniqueFilterKey]?.[objectKey]?.from)
      : null,
    to: filterState?.[uniqueFilterKey]?.[objectKey]?.to
      ? new Date(filterState?.[uniqueFilterKey]?.[objectKey]?.to)
      : null,
  });

  const selectedConfigId = useSelector(
    (state) => state.dataTable.selectedConfigId
  );

  const [relativeDayPick, setRelativeDayPick] = useState({
    from: null,
    to: null,
  });

  const [relativeMinutePick, setRelativeMinutePick] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    setRelativeDayPick({
      from: null,
      to: null,
    });

    setRelativeMinutePick({
      from: null,
      to: null,
    });

    setDate(true);
    setDay(false);
    setMinute(false);
  }, [selectedConfigId]);

  useEffect(() => {
    if (filterState)
      setRelativeDatePick({
        from: filterState?.[uniqueFilterKey]?.[objectKey]?.from
          ? new Date(filterState?.[uniqueFilterKey]?.[objectKey]?.from)
          : null,
        to: filterState?.[uniqueFilterKey]?.[objectKey]?.to
          ? new Date(filterState?.[uniqueFilterKey]?.[objectKey]?.to)
          : null,
      });
  }, [filterState]);

  useEffect(() => {
    if (relativeDayPick.from) {
      if(relativeDayPick.to == null || relativeDayPick.to == 0){
        let newobj = {}
        newobj = { ...relativeDayPick, to: 0 }
        dispatch(updateDateRange(newobj));
      }else{
        dispatch(updateDateRange(relativeDayPick));
      }
    }
  }, [relativeDayPick]);

  const _updateRelativePickState = () => {
    if (
      (relativeDayPick.from == '' || relativeDayPick.from == null) &&
      (relativeDayPick.to == '' || relativeDayPick.to == null)
    ) {
      dispatch(
        updateDataTableFilterState({
          ...filterState,
          [uniqueFilterKey]: {
            ...filterState?.[uniqueFilterKey],

            [objectKey]: {
              ...filterState[objectKey],
              from: null,
              to: null,
            },
          },
        })
      );
    } else {
      dispatch(
        updateDataTableFilterState({
          ...filterState,
          [uniqueFilterKey]: {
            ...filterState?.[uniqueFilterKey],
            [objectKey]: {
              ...filterState?.[uniqueFilterKey]?.[objectKey],
              from: moment()
                .subtract(Math.abs(relativeDayPick.from), 'days')
                .startOf('day')
                .toISOString(),
              to:
                relativeDayPick.to < 0
                  ? moment()
                      .subtract(Math.abs(relativeDayPick.to), 'days')
                      .endOf('day')
                      .toISOString()
                  : moment().toISOString(),
            },
          },
        })
      );
    }

    closeFilter();
  };

  const _updateRelativePickStateM = () => {
    if (
      (relativeMinutePick.from == '' || relativeMinutePick.from == null) &&
      (relativeMinutePick.to == '' || relativeMinutePick.to == null)
    ) {
      dispatch(
        updateDataTableFilterState({
          ...filterState,
          [uniqueFilterKey]: {
            ...filterState?.[uniqueFilterKey],

            [objectKey]: {
              ...filterState[objectKey],
              from: null,
              to: null,
            },
          },
        })
      );
    } else {
      dispatch(
        updateDataTableFilterState({
          ...filterState,
          [uniqueFilterKey]: {
            ...filterState?.[uniqueFilterKey],
            [objectKey]: {
              ...filterState?.[uniqueFilterKey]?.[objectKey],
              from: moment()
                .subtract(Math.abs(relativeMinutePick.from), 'minutes')
                .toISOString(),
              to:
                relativeMinutePick.to < 0
                  ? moment()
                      .subtract(Math.abs(relativeMinutePick.to), 'minutes')
                      .toISOString()
                  : moment().toISOString(),
            },
          },
        })
      );
    }
  };

  const _reset = () => {
    setRelativeDays(0);
    setRelativeMinutes(0);
  };

  const clearDateRange = () => {
    dispatch(
      updateDataTableFilterState({
        ...filterState,
        [uniqueFilterKey]: {
          ...filterState?.[uniqueFilterKey],

          [objectKey]: {
            ...filterState[objectKey],
            from: null,
            to: null,
          },
        },
      })
    );

    setRelativeDatePick({
      from: null,
      to: null,
    });
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-start'>
      <div className='d-flex justify-content-start align-items-center'>
        <Form.Check
          type='radio'
          id='dateRange.absoluteDate'
          label=' Date'
          className='text-dark cursor-pointer me-3'
          style={{ marginRight: '16px' }}
          checked={isDate}
          onClick={() => {
            _reset();
            setDate(true);
            dispatch(updateDateState('date'));
            setDay(false);
            setMinute(false);

            setDayButton(false);
            setMinuteButton(false);
          }}
        />

        <Form.Check
          type='radio'
          id='dateRange.relativeDay'
          label='Days'
          className='text-dark cursor-pointer me-3'
          style={{ marginRight: '16px' }}
          checked={isDay}
          onClick={() => {
            _reset();
            setDate(false);
            setDay(true);
            dispatch(updateDateState('day'));
            setMinute(false);

            setDayButton(true);
            setMinuteButton(false);
          }}
        />

        <Form.Check
          type='radio'
          id='dateRange.relativeminute'
          label=' Minutes'
          className='text-dark cursor-pointer'
          checked={isMinute}
          onClick={() => {
            _reset();

            setDate(false);
            setDay(false);
            setMinute(true);
            dispatch(updateDateState('minute'));

            setDayButton(false);
            setMinuteButton(true);
          }}
        />
      </div>
      <div className='d-flex flex-row justify-content-right align-items-center mt-2'>
        {isDate && (
          <>
            <div className='d-flex flex-row justify-content-right align-items-center mt-0 me-2'>
              <DatePicker
                selected={relativeDatePick.from}
                showTimeSelect
                onChange={(date) => {
                  if (date) {
                    setRelativeDatePick({
                      ...relativeDatePick,
                      from: date,
                    });
                  } else {
                    clearDateRange();
                  }
                }}
                className='form-control'
                placeholderText='Start'
                timeIntervals={5}
                dateFormat={getDateTimeFormat()}
                isClearable
                disabled={!isDate}
              />
            </div>
            <div className='d-flex flex-row justify-content-right align-items-center mt-0 me-2'>
              <DatePicker
                selected={relativeDatePick.to}
                showTimeSelect
                onChange={(date) => {
                  if (date) {
                    setRelativeDatePick({
                      ...relativeDatePick,
                      to: date,
                    });
                  } else {
                    clearDateRange();
                  }
                }}
                disabled={!isDate}
                className='form-control'
                placeholderText='End'
                timeIntervals={5}
                dateFormat={getDateTimeFormat()}
                isClearable
              />
            </div>
            <Button
              title='Submit'
              variant='primary'
              className='btn-falcon-primary'
              disabled={!isDate}
              size='sm'
              onClick={() => {
                dispatch(
                  updateDataTableFilterState({
                    ...filterState,
                    [uniqueFilterKey]: {
                      ...filterState?.[uniqueFilterKey],
                      [objectKey]: {
                        ...filterState[objectKey],
                        from: relativeDatePick?.from?.toISOString() || null,
                        to: relativeDatePick?.to?.toISOString() || null,
                      },
                    },
                  })
                );
                closeFilter();
              }}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </>
        )}
      </div>

      <div className='d-flex flex-row justify-content-right align-items-center mt-0'>
      {isDay && (
  <>
    <div className='d-flex flex-row justify-content-right align-items-center mt-0'>
      <div className='d-flex flex-row justify-content-right align-items-center mt-0 me-2 position-relative' >
        <Form.Control
          placeholder='Start'
          type='number'
          onChange={(e) => {
            if (e.target.value > 0) {
              setRelativeDayPick({
                ...relativeDayPick,
                from: e.target.value * -1,
              });
            } else {
              setRelativeDayPick({
                ...relativeDayPick,
                from: e.target.value,
              });
            }
          }}
          disabled={!isDay}
          value={relativeDayPick.from}
          className='position-relative' 
        />
        {relativeDayPick.from && (
          <Button
            variant='link'
            className='text-danger p-0 position-absolute top-50 end-0 translate-middle'
            style={{ fontSize: '17px', marginTop: '0px', marginRight: '28px'}}
            onClick={() => setRelativeDayPick({ ...relativeDayPick, from: '' })}
          >
            <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#1B5694' }}/>
          </Button>
        )}
      </div>
      <div className='d-flex flex-row justify-content-right align-items-center mt-0 me-2 position-relative' > 
        <Form.Control
          type='number'
          placeholder='End'
          disabled={!isDay}
          value={relativeDayPick.to}
          onChange={(e) => {
            if (e.target.value > 0) {
              setRelativeDayPick({
                ...relativeDayPick,
                to: e.target.value * -1,
              });
            } else {
              setRelativeDayPick({
                ...relativeDayPick,
                to: e.target.value,
              });
            }
          }}
          className='position-relative'
        />
        {relativeDayPick.to && (
          <Button
            variant='link'
            className='text-danger p-0 position-absolute top-50 end-0 translate-middle'
            style={{ fontSize: '17px', marginTop: '0px', marginRight: '28px' }}
            onClick={() => setRelativeDayPick({ ...relativeDayPick, to: '' })}
          >
            <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#1B5694' }} />
          </Button>
        )}
      </div>

      <Button
        title='Submit'
        variant='primary'
        className='btn-falcon-primary'
        disabled={!isDayButton}
        onClick={() => _updateRelativePickState()}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </div>
  </>
)}
</div>

{isMinute && (
  <>
    <div className='d-flex flex-row justify-content-right align-items-center mt-0'>
      <div className='d-flex flex-row justify-content-right align-items-center mt-0 me-2 position-relative'>
        <Form.Control
          type='number'
          placeholder='Start'
          disabled={!isMinute}
          value={relativeMinutePick.from}
          onChange={(e) => {
            if (e.target.value > 0) {
              setRelativeMinutePick({
                ...relativeMinutePick,
                from: e.target.value * -1,
              });
            } else {
              setRelativeMinutePick({
                ...relativeMinutePick,
                from: e.target.value,
              });
            }
          }}
          className='position-relative'
          isClearable
          onClear={() => setRelativeMinutePick({ ...relativeMinutePick, from: '' })}
        />
        {relativeMinutePick.from && (
          <Button
            variant='link'
            className='text-danger p-0 position-absolute top-50 end-0 translate-middle'
            style={{ fontSize: '17px', marginTop: '0px', marginRight: '28px' }}
            onClick={() => setRelativeMinutePick({ ...relativeMinutePick, from: '' })}
          >
            <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#1B5694' }}/>
          </Button>
        )}
      </div>
      <div className='d-flex flex-row justify-content-right align-items-center mt-0 me-2 position-relative'>
        <Form.Control
          type='number'
          placeholder='End'
          disabled={!isMinute}
          value={relativeMinutePick.to}
          onChange={(e) => {
            if (e.target.value > 0) {
              setRelativeMinutePick({
                ...relativeMinutePick,
                to: e.target.value * -1,
              });
            } else {
              setRelativeMinutePick({
                ...relativeMinutePick,
                to: e.target.value,
              });
            }
          }}
          className='position-relative'
          isClearable
          onClear={() => setRelativeMinutePick({ ...relativeMinutePick, to: '' })}
        />
        {relativeMinutePick.to && (
          <Button
            variant='link'
            className='text-danger p-0 position-absolute top-50 end-0 translate-middle'
            style={{ fontSize: '17px', marginTop: '0px', marginRight: '28px' }}
            onClick={() => setRelativeMinutePick({ ...relativeMinutePick, to: '' })}
          >
            <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#1B5694' }} />
          </Button>
        )}
      </div>

      <Button
        title='Submit'
        variant='primary'
        className='btn-falcon-primary'
        disabled={!isMinuteButton}
        onClick={() => _updateRelativePickStateM()}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </div>
  </>
)}


      <span className='mt-2 text-warning fs--1 ms-3'>{errorMessage}</span>
    </div>
  );
};

export default DateRangeFilter;
