import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from '../../../assets/img/team/avatar.png';
import Avatar from '../../common/Avatar';
import LinerLogo from '../../../assets/images/liner-logo.png';
import Flex from '../../common/Flex';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../axiosSetup';
import { toast } from 'react-toastify';
import Divider from '../../common/Divider';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const profileDetails = useSelector((state) => state.userProfile.profile);

  const windowLoc = window.location.href.indexOf('.odexglobal.com');
  const windowurl = window.location.href.substring(0, windowLoc);

  return (
    <Dropdown navbar={true} as='li'>
      <Dropdown.Toggle
        bsPrefix='toggle'
        as={Link}
        to='#!'
        className='pe-0 ps-2 nav-link '>
        <Flex direction={'row'} justifyContent='end' alignItems={'center'}>
          {/*  <Avatar
            src={profileDetails?.partnerType === 'liner' ? LinerLogo : team3}
            className='ms-2'
            size='xl'
          /> */}
          <div
            className={
              profileDetails?.partnerType === 'liner'
                ? 'linerProfileIcon'
                : 'customerProfile'
            }>
            {profileDetails?.partnerType === 'liner'
              ? profileDetails?.username?.substring(0, 1)
              : profileDetails?.username?.substring(0, 1)}
          </div>
        </Flex>
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
        <div className='bg-white rounded-2 py-2 dark__bg-1000'>
          <Dropdown.Item disabled>
            <span className='text-secondary' style={{ fontSize: '16px' }}>
              {profileDetails.username}
            </span>
          </Dropdown.Item>
          <Divider></Divider>
          <Dropdown.Item as={Link} to='/user/profile'>
            {t('resource_3:ProfileAccount')}
          </Dropdown.Item>
          {profileDetails?.partnerType === 'liner' && (
            <>
              <Divider></Divider>
              <Dropdown.Item as={Link} to='/settings'>
                Settings
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Divider />
          <Dropdown.Item as={Link} to='/resetPassword'>
            {t('resource_2:changePassword')}
          </Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Item
            as='div'
            onClick={async () => {
              try {
                await axios.get(
                  //  `${process.env.REACT_APP_AUTH_SERVER_HOST}/do/logout`
                  `${windowurl}.odexglobal.com/drop`
                );
                window.location.href = `${windowurl.replace(
                  'https://',
                  'https://'
                )}.odexglobal.com/auth/do/logout`;
                // window.open(
                //   `${process.env.REACT_APP_CLIENT_APP_HOST}/do/logout`
                // );

                /*    await axios.get(
                  `${process.env.REACT_APP_CLIENT_APP_HOST}/drop`
                ); */
                // setTimeout(() => {
                //   window.open(`${process.env.REACT_APP_CLIENT_APP_HOST}/drop`);
                // }, 4000);

                /*   window.location.href = `${process.env.REACT_APP_CLIENT_APP_HOST}/home`; */

                // setTimeout(() => {
                //   window.location.href = `${process.env.REACT_APP_CLIENT_APP_HOST}/home`;
                // }, 8000);
              } catch (error) {
                toast.error('Unable to logout');
              }
            }}

            // href='/logout'
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
