import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getAllUserDetailsByUser } from './action/UserDetails';

interface userGroup {
  mstGrpId: number | string | null,
  usrGrpNm: string | null,
}



interface UserDetails {
    mstPartnerId: number | string | null,
    mstUsrId: number | string | null,
    loginId: string | null,
    usrNm: string | null,
    contactNo: string | null,
    title: string | null,
    contactPerson: string | null,
    mstGrp: userGroup[],
    status: string | null,
    countryShortCode: string | null,
    countryName: string | null;
    isRootUser: string | null;
    partnerNm: string | null;
  }

interface IUserDetailsList {
    list: UserDetails[];
    next: string | null;
    previous: string | null;
}
interface data{
  userData: IUserDetailsList;
  mstPartnerId: number | string | null,
  mstUsrId: number | string | null,
  loginId: string | null,
  usrNm: string | null,
  contactNo: string | null,
  title: string | null,
  contactPerson: string | null,
  mstGrp: userGroup[],
  grpNm: string | null,
  status: string | null,
  countryShortCode: string | null,
  countryName: string | null;
  isRootUser: string | null;
  partnerNm: string | null;
}

interface IRowColumns {
  mainTableClass: string;
  outletClass: string;
}

interface UserDetailsSlice {
    data: data;
    dataLoading: boolean;
    showColumnSettings: boolean;
    rowColumns: IRowColumns;
    toggle: boolean,
}

const initialState: UserDetailsSlice = {
    data:  {
      /*partnerId: null,
      userData: {
        list: [],
        next: '',
        previous: '',
      }*/
      mstPartnerId: null,
      mstUsrId: null,
      loginId: '',
      usrNm: '',
      contactNo: '',
      title: '',
      contactPerson: '',
      mstGrp: [],
      grpNm: '',
      status: '1',
      countryShortCode: '',
      countryName: '',
      isRootUser: '',
      partnerNm: '',
      userData: {
        list: [],
        next: '',
        previous: '',
      },
    },
    rowColumns: {
      mainTableClass: 'col-lg-12',
      outletClass: 'd-none',
    },
    dataLoading: true,
    showColumnSettings: false,
    toggle: false,
}

export const userDetailsListReducers = createSlice({
    name: 'userList',
    initialState,
    
    reducers: {
      updateUserDetails: (state, action: PayloadAction<data>) => {
        state.data = action.payload;
      },
      updateUserDetailsList: (state, action: PayloadAction<IUserDetailsList>) => {
        state.data.userData = action.payload;
      },
      toggleColumnSettingsDashboard: (state, action: PayloadAction<boolean>) => {
        state.showColumnSettings = action.payload;
      },
      updateRowColumns: (state, action: PayloadAction<IRowColumns>) => {
        state.rowColumns = action.payload;
      },
      updateToggle: (state, action: PayloadAction<UserDetailsSlice>) => {
        state.toggle = action.payload.toggle
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getAllUserDetailsByUser.pending, (state, action) => {
        state.dataLoading = true;
      });
      builder.addCase(getAllUserDetailsByUser.fulfilled, (state: any, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      });
    
    
    },
  });

  export const {
    updateUserDetails,
    updateUserDetailsList,
    toggleColumnSettingsDashboard,
    updateRowColumns,
    updateToggle,
  } = userDetailsListReducers.actions;
  
  export default userDetailsListReducers.reducer;