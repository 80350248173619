import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface CreateUserForm {
  loginId: string;
  title: string;
  username: string;
  partnerId: string;
  status: string;
  password: string;
  confirmPassword: string;
}

export interface CreateUserState {
  createUserForm: CreateUserForm;
}

const initialState: CreateUserState = {
  createUserForm: {
    loginId: '',
    title: '',
    username: '',
    partnerId: '',
    status: '',
    password: '',
    confirmPassword: '',
  },
};

export interface CreateUserFormInputs {
  key: keyof CreateUserForm;
  value: string;
}

export const createUserSlice = createSlice({
  name: 'createUser',
  initialState,
  reducers: {
    updateCreateUserFormInputs: (
      state,
      action: PayloadAction<CreateUserFormInputs>
    ) => {
      switch (action.payload.key) {
        case 'loginId':
          state.createUserForm.loginId = action.payload.value;
          break;
        case 'title':
          state.createUserForm.title = action.payload.value;
          break;
        case 'status':
          state.createUserForm.status = action.payload.value;
          break;
        case 'username':
          state.createUserForm.username = action.payload.value;
          break;
        case 'partnerId':
          state.createUserForm.partnerId = action.payload.value;
          break;
        case 'password':
          state.createUserForm.password = action.payload.value;
          break;
        case 'confirmPassword':
          state.createUserForm.confirmPassword = action.payload.value;
          break;
        default:
          break;
      }
    },
  },
});

export const { updateCreateUserFormInputs } = createUserSlice.actions;

export default createUserSlice.reducer;
