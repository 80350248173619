const PrivacyPolicy = () => {
    return(
        <div>
            <p>This Privacy Policy describes the information, as part of the normal operation of our services; we collect from you and what may happen to that information.By accepting the Privacy Policy and the User Agreement in registration, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy.This Privacy Policy is referred to and subject to the Terms of Use, User E-consent and the User Licence Agreement.</p>
            <p><b>Information Collected under the Website</b><br></br>The types of personal information ODEX may collect through the Website shall include but not limit to: 
                <ul>
                    <li>Contact information, Individual and/or business (such as name, company name, job position, postal address, telephone numbers and email address)</li>
                    <li>Contact information of others to whom users ask us to send information, as permitted by law</li>
                    <li>Cookie data and other browser-related information as further described in the “Information We Collect By Automated Means” section of this Online Privacy Notice, and Other information that may be provided through the Sites (including through comments or questions fields)</li>
                </ul>
            </p>
            <p><b>IP addresses and cookies</b></p>
            <p>
                <ul>
                    <li>We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration</li>
                    <li>For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on the hard drive of your computer.  Cookies contain information that is transferred to your computer's hard drive.  They help us to improve our site and to deliver a better and more personalised service.  They enable us to:
                        <ul>
                            <li>estimate our usage size and usage pattern;</li>
                            <li>store information about your preferences, and so allow us to customise our site according to your individual interests;</li>
                            <li>speed up your searches; and</li>
                            <li>recognise you when you return to our site.</li>
                        </ul>
                    </li>
                    <li>Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to our site. You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies.  However, if you select this setting you may be unable to access certain parts of our Website.</li>
                    <li>Please note that our advertisers may also use cookies, over which we have no control.</li>
                </ul>
            </p>
            <p>When you use our Website, we collect and store your personal information from you. Our primary goal in doing so is to provide a safe, efficient, smooth and customized experience. Once you give us your personal information, you are not anonymous to us. To fully use our Website, you will need to register using our online registration form, where you may be required to provide us with your contact and identity information, residence / place of business information, billing information, bank account details and other personal information as indicated on the forms throughout the Website. Where possible, we indicate which fields are required and which fields are optional. You may provide us with a User ID. We may automatically track certain information about you based upon your behaviour on our Website. We use this information to protect and serve our users. This information is compiled and analyzed on an aggregated basis. If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we may collect such information into a file specific to you.</p>
        </div>
    );
}

export default PrivacyPolicy;