import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../../../axiosSetup";
import axios, { AxiosError } from "axios";
import queryString from "query-string";
import { updateLinerOptions } from "../profileAccountSlice";

export const getCustomersDetailsByPartnerId = createAsyncThunk(
  "/getProfile",
  async (id: any) => {
    try {
      const response = await Axios.post(
        `/api/user-management/v1/customer/viewCustomersDetails`
      );
      return {
        partnerAccountList: {
          next: null,
          previous: null,
          list: response?.data[0]?.partnerAccountList.map((v: any) => ({
            ...v,
            mstAccountId: v.mstAccountId,
            mstPartnerId: v.mstPartnerId,
            bankName: v.bankName,
            accountName: v.accountName,
            accountAlias: v.accountAlias,
            accountNo: v.accountNo,
            accountCurrency: v.accountCurrency,
            localBankCode: v.localBankCode,
            branchAddress: v.branchAddress,
            ibanNo: v.ibanNo,
            swiftCode: v.swiftCode,
          })),
        },
        emailList: {
          next: null,
          previous: null,
          list: response?.data[0]?.emailList.map((v: any) => ({
            ...v,
            mstPartnerEmailId: v.mstPartnerEmailId,
            mstPartnerId: v.mstPartnerId,
            emailId: v.emailId,
            status: v.status,
          })),
        },
        customermappingList: {
          next: null,
          previous: null,
          list: response?.data[0]?.customermappingList.map((v: any) => ({
            ...v,
            customerMapId: v.customerMapId,
            customerId: v.customerId,
            linerId: v.linerId,
            mapCode: v.mapCode,
            internalCode: v.internalCode,
          })),
        },

        mstPartnerId: response.data[0].mstPartnerId,
        companyName: response.data[0].partnerNm,
        partnerType: response.data[0].partnerType,
        parentPartnerId: response.data[0].parentPartnerId,
        isPartnerActive: response.data[0].isPartnerActive,
        privilegeId: response.data[0].privilegeId,
        verifiedEmailDt: response.data[0].verifiedEmailDt,
        isVerifyEmailSent: response.data[0].isVerifyEmailSent,
        kycStatus: response.data[0].kycStatus,
        country: response.data[0].country,
        defaultCurrency: response.data[0].defaultCurrency,
        defaultTimezone: response.data[0].defaultTimezone,
        partnerIdentityNo: response.data[0].partnerIdentityNo,
        partnerIdentityId: response.data[0].partnerIdentityId,
        issueDt: response.data[0].issueDt,
        validTillDt: response.data[0].validTillDt,
        attPath: response.data[0].attPath,
        addrNm: response.data[0].addrNm,
        address: response.data[0].address,
        contactPerson: response.data[0].contactPerson,
        isDefault: response.data[0].isDefault,
        phnNo: response.data[0].phnNo,
        mstAddrId: response.data[0].mstAddrId,
      };
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const saveOrUpdateCustomersDetails = createAsyncThunk(
  "/saveProfile",
  async (payload: any) => {
    try {
      const response = await Axios({
        url: `/api/user-management/v1/customer/saveOrUpdateCustomersDetails`,
        method: "POST",
        data: {
          ...payload.data,
          partnerNm: payload.data.companyName,
          partnerAccountList: payload.updatedPartnerAccountList.map(
            (v: any) => ({
              mstAccountId: v?.mstAccountId,
              mstPartnerId: v?.mstPartnerId,
              bankName: v?.bankName,
              accountName: v?.accountName,
              accountAlias: v?.accountAlias,
              accountNo: v?.accountNo,
              accountCurrency: v?.accountCurrency,
              localBankCode: v?.localBankCode,
              branchAddress: v?.branchAddress,
              ibanNo: v?.ibanNo,
              swiftCode: v?.swiftCode,
            })
          ),
          emailList: payload.data.emailList.list,
          customermappingList: payload.updatedCustomermappingList.map(
            (v: any) => ({
              customerMapId: v.customerMapId,
              customerId: v.customerId,
              linerId: v.linerId,
              mapCode: v.mapCode,
              internalCode: v.internalCode,
            })
          ),
        },
      });

      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const fetchLinerNameByCountry = createAsyncThunk(
  "registration/getPartnerDDList2",
  async (state: any, { dispatch }) => {
    try {
      const response = await Axios.post(
        `/api/user-management/v1/customer/getPartnerDDList?countryCode=${state.country_code}&type=liner`
      );
      const options =
        response?.data?.map((item: any) => ({
          value: item?.mstPartnerId,
          text: item?.partnerNm,
        })) || [];
      dispatch(updateLinerOptions(options));
      return options;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
);
