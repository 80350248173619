import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BLDetailsList } from '../types';
import RequestedTableData from '../data/requestedTable.json';

interface RequesetedTableSlice {
  data: BLDetailsList[];
}

const initialState: RequesetedTableSlice = {
  data: RequestedTableData.data,
};

export const requestedTableSlice = createSlice({
  name: 'requestedTable',
  initialState,
  reducers: {
    updateRequestedTableList: (
      state,
      action: PayloadAction<BLDetailsList[]>
    ) => {
      state.data = action.payload;
    },
  },
});

export const { updateRequestedTableList } = requestedTableSlice.actions;

export default requestedTableSlice.reducer;
