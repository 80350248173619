import React from 'react';

import Lottie from 'lottie-react';
import infiniteLoop from '../../../assets/img/animated-icons/infinite-loop.json';

const OdexSpinner = () => {
  return (
    <div className='d-flex justify-content-center align-items-center w-100 flex-1'>
      <Lottie
        animationData={infiniteLoop}
        loop={true}
        style={{ height: '80px', width: '80px' }}
      />
    </div>
  );
};

export default OdexSpinner;
