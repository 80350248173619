import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { AxiosError } from 'axios';
import { sleep } from '../../../../utils/sleep';
import { InstructionList } from '../../../dashboard/types';
import axiosOdex, { axiosCacheInstance } from '../../../../../axiosSetup';
import { toast } from 'react-toastify';
import { updateAttachmentList } from '../instructionSlice/DemurrageSlice';

// import { Axios } from 'axios';

export const addInstruction = createAsyncThunk(
  'instruction/addInstruction',
  async (state: InstructionList) => {
    //  const response = await axios.post('/api/user-management/v1/bl',state);
    const response = await axios.post(
      'https://jsonplaceholder.typicode.com/users',
      state
    );

    // const   response=state
    //await sleep(2000);

    /*   return {
    next: null,
    previous: null,
    list: response,
  }; */
    return response;
  }
);
export const approveInstruction = createAsyncThunk(
  'instruction/approveInstruction',
  async (state: InstructionList) => {
    //  const response = await axios.post('/api/user-management/v1/bl',state);
    const response = await axios.post(
      'https://jsonplaceholder.typicode.com/users',
      state
    );

    // const   response=state
    //await sleep(2000);

    /*   return {
   next: null,
   previous: null,
   list: response,
 }; */
    return response;
  }
);
export const rejectInstruction = createAsyncThunk(
  'instruction/rejectInstruction',
  async (state: InstructionList) => {
    //  const response = await axios.post('/api/user-management/v1/bl',state);
    const response = await axios.post(
      'https://jsonplaceholder.typicode.com/users',
      state
    );

    // const   response=state
    //await sleep(2000);

    /*   return {
   next: null,
   previous: null,
   list: response,
 }; */
    return response;
  }
);

export const addAgentNnominate = createAsyncThunk(
  'instruction/addAgentDelegation',
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/agentNomination/${
          payload.agentPartnerId && payload.agentNominationId !== '0'
            ? 'updateAgentNominationDetails'
            : 'saveAgentNominationDetails'
        }`,
        {
          requestedPartnerId: '57',
          agentPartnerId: payload?.agentPartnerId,
          taxId: payload?.taxId,
          companyName: payload?.companyName,
          emailId: payload?.email,
          allowFreightInvoice: payload?.allowFreightInvoice,
          blNo: payload?.blNo,
          fileName: payload?.fileName,
          byteArr: payload?.byteArr,
          address: payload?.address,
          blId: payload?.blId,
          agentNominationId:
            payload.agentPartnerId && payload.agentNominationId !== '0'
              ? payload?.agentNominationId
              : null,
          status: payload.agentPartnerId ? payload.status : '',
          timePendingInvoice: payload?.timePendingInvoice || null,
          blAccess: payload?.blAccess || null,
          agentType: payload?.agentType || null,
          customerRemarks: payload?.customerRemarks || null,
          accessList: payload?.accessList || [],
        }
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);

      return {};
    }
  }
);

export const addMultiAgentNnominate = createAsyncThunk(
  'instruction/addMultiAgentNnominate',
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/agentNomination/${
          payload.agentNominationId && payload.agentNominationId !== '0'
            ? 'updateMultiAgentNominationDetails'
            : 'saveMultiAgentNominationDetails'
        }`,
        payload?.blId && payload.blId !== null
          ? payload.blId
              .split(',')
              .filter(
                (value: any, index: any, self: any) =>
                  self.indexOf(value) === index
              )
              .map((id: any) => {
                return {
                  requestedPartnerId: payload?.requestedPartnerId,
                  agentPartnerId: payload?.agentPartnerId,
                  taxId: payload?.taxId,
                  companyName: payload?.companyName,
                  emailId: payload?.email,
                  allowFreightInvoice: payload?.allowFreightInvoice,
                  fileName: payload?.fileName,
                  byteArr: payload?.byteArr,
                  serverFilePath: payload?.serverPath,
                  tempFileName: payload?.tempFileName,
                  address: payload?.address,
                  blId: id,
                  agentNominationId:
                    payload.agentPartnerId && payload.agentNominationId !== '0'
                      ? payload?.agentNominationId
                      : null,
                  status: payload.agentPartnerId ? payload.status : '',
                  timePendingInvoice: payload?.timePendingInvoice || null,
                  blAccess: payload?.blAccess || null,
                  agentType: payload?.agentType || null,
                  customerRemarks: payload?.customerRemarks || null,
                  accessList: payload?.accessList || [],
                  requestType: payload?.requestType || null,
                  partyName: payload?.partyName || null,
                  partyEmail: payload?.partyEmail || null,
                  isMultiReq: payload?.isMultiReq,
                };
              })
          : null
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);


      return {};
    }
  }
);

export const getInvoiceDetailsByPmtId = createAsyncThunk(
  'instruction/getInvoiceDetailsByPmtId',
  async (state: any) => {
    const response = await axiosOdex.get(
      `/api/edo-rest-v2/v2/payment/getInvoiceDetailsByPmtId?pmtId=${state}`
    );
    return {data: response.data.map((i:any)=>({
      invId: i.invoiceId,
      invNo: i.invoiceNo,
      invType: i.invType,
      invStatus: i.invStatus,
      invAmt: i.invoiceAmount
    }))};
  }
);

export const getMstCodeList = createAsyncThunk(
  'instruction/getMstCodeList',
  async (state: any) => {
    const response = await axiosCacheInstance.get(
      `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=INV_TYPE&linerId=${state.linerId}&countryCode=${state.dataCountry}`
    , {
      cache: {
        ttl: 10 * 60 * 1000
      }
    });
    // debugger
    return response;
  }
);

export const getAgentNnominate = createAsyncThunk(
  'instruction/getAgentNnominate',
  async (payload: any) => {
    const response = await axiosOdex.get(
      `/api/edo-rest-v2/v2/agentNomination/getAgentNominationDetails/${payload?.decrptdoc}?linerId=${payload?.selectedLinerId}`
    );

    return { list: response.data };
  }
);

export const getCreditBalance = createAsyncThunk(
  'creditBalanceBlPayment/getCreditBalance',
  async (payload: any) => {
    const response = await axiosOdex.get(
      `api/edo-rest-v1/v1/payment/listCreditBalance?linerId=${payload?.selectedLinerId}`
    );

    return response.data.list;
  }
);

export const addCarrierHaulage = createAsyncThunk(
  'instruction/addCarrierHaulage',
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        '/api/edo-rest-v1/v1/carrierHaulage',

        {
          blId: payload?.blId,
          deliveryOption: payload?.deliveryOption,
          warehouseName: payload?.warehouseName,
          // customerCode: payload?.customerCode,
          contactPerson: payload?.contactName,
          emailId: payload?.email,
          contactNo: payload?.contactNo,
          address: payload?.address,
          remarks: payload?.remark,
          refPoNo: payload?.refPoNo,
          areaCode: payload?.areaCode,
          linerId: payload?.linerId,
          status: 'A',
          placeId: payload?.placeId,
          indexName: 'explore.place.Esri',
          filterCountries: payload?.country,
          language: 'en',
          deliveryDate: payload?.deliveryDate,
          rejectionRemarks: '',
        }
      );

      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error( error);
      return { success: false, data: error.response?.data?.message || '' };
    }
  }
);
export const getCarrierHaulage = createAsyncThunk(
  'instruction/getCarrierHaulage',

  async (payload: any) => {
    const response = await axiosOdex.get(
      `/api/edo-rest-v2/v2/carrierHaulage/${payload.decrptdoc}?linerId=${payload?.selectedLinerId}`
    );
    return response.data;
  }
);

export const approveCarrierHaulage = createAsyncThunk(
  'instruction/approveCarrierHaulage',
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/carrierHaulage/approveRejectCh/${payload.carrierHaulageId}`,

        {
          blId: payload?.blId,
          deliveryOption: payload?.deliveryOption,
          warehouseName: payload?.warehouseName,
          // customerCode: payload?.customerCode,
          contactPerson: payload?.contactName,
          emailId: payload?.email,
          contactNo: payload?.contactNo,
          address: payload?.adress,
          remarks: payload?.remark,
          refPoNo: payload?.refPoNo,
          areaCode: payload?.areaCode,
          linerId: payload?.linerId,
          status: 'A',
        }
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);
      return {};
    }
  }
);
export const rejectCarrierHaulage = createAsyncThunk(
  'instruction/rejectarrierHaulage',
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/carrierHaulage/approveRejectCh/${payload.carrierHaulageId}`,

        {
          blId: payload?.blId,
          deliveryOption: payload?.deliveryOption,
          warehouseName: payload?.warehouseName,
          // customerCode: payload?.customerCode,
          contactPerson: payload?.contactName,
          emailId: payload?.email,
          contactNo: payload?.contactNo,
          address: payload?.adress,
          remarks: payload?.remark,
          rejectionRemarks: payload?.rejectionremark,
          refPoNo: payload?.refPoNo,
          areaCode: payload?.areaCode,
          status: 'Rejected',
        }
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);
      return {};
    }
  }
);

export const addInvoiceChange = createAsyncThunk(
  'instruction/addInstruction',
  async (state: any) => {
    const response = await axiosOdex.post(
      '/api/edo-rest-v1/v1/bp/createBp',
      {
        blId: state?.blId || null,
        taxId: state?.taxId || null,
        masterPartnerId: state?.requestedPartnerId,
        standingInstructionId: 1,
        emailId: state?.email || null,
        contactNo: state?.contactNo || null,
        address: state?.address || null,
        status: 'Submitted',
        requestedpartnernm: state?.requestedpartnernm,
        requestedPartnerId: state?.requestedPartnerId,
        poReference: state?.poReference,
        releaseType: state?.releaseType,
        returnType: state?.returnType,
        customerCode: state?.customerCode,
        userRemarks: state?.userRemarks,
        invChangegAccessList: state?.invChangegAccessList
        //loiUpload: state?.byteArr,
        // fileName: state?.fileName,
      }
    ).then(
      (data)=>{
        toast.dark('Successfully created');
        return data
      }
    ).catch((error)=>{
      toast.error(error.response.data.message)
      return error.response.data
    })
    
    return response.data;
  }
);

/* export const completeInvoiceChange = createAsyncThunk(
  'instruction/completeInvoiceChange',
  async (state: any) => {
    const response = await axiosOdex.patch(
      `/api/edo-rest-v1/v1/bp/approveRejectBp/${
        state?.invoiceChangeId || ''
      }`,
      {
        blId: state?.blId || '',
        status: 'A',
        invoiceChangeId: state?.invoiceChangeId || '',
        standingInstructionId: 1,
      }
    );

    return response.data;
  }
); */

export const approveRejectInvoiceChange = createAsyncThunk(
  'instruction/completeInvoiceChange',
  async (state: any) => {
    const response = await axiosOdex.patch(
      `/api/edo-rest-v1/v1/bp/approveRejectBp/${
        state?.invoiceChangeId || ''
      }`,
      {
        blId: state?.blId || '',
        status: state?.status || '',
        invoiceChangeId: state?.invoiceChangeId || '',
        remarks: state?.remarks || '',
        standingInstructionId: 1,
      }
    );

    return response.data;
  }
);

/* export const rejectInvoiceChange = createAsyncThunk(
  'instruction/rejectInvoiceChange',
  async (state: any) => {
    const response = await axiosOdex.patch(
      `/api/edo-rest-v1/v1/bp/approveRejectBp/${
        state?.invoiceChangeId || ''
      }`,
      {
        blId: state?.blId || '',
        status: 'R',
        invoiceChangeId: state?.invoiceChangeId || '',
        standingInstructionId: 1,
      }
    );

    return response.data;
  }
);
 */

export const getCodeList = createAsyncThunk(
  'common/getcodeList',
  async (payload: any, { dispatch }) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=${
          payload?.codeType
        }&linerId=${payload?.linerId.toString()}&countryCode=${
          payload?.partnerCountry
        }`,{
          cache: {
            ttl: 10 * 60 * 1000
          }
        }
      );

      const releaseTypeOptions = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));

      return releaseTypeOptions;
    } catch (e) {
      const error = e as AxiosError;
      return [];
    }
  }
);

export const addBlanketDeligation = createAsyncThunk(
  'instruction/BLANKET_DELIGATION',
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/instruction/addStandingInstruction`,

        {
          linerId:payload?.linerId,
          blId: null,
          startPol: payload?.startCountry || null,
          pol: payload?.polCountry || null,
          pod: payload?.podCountry || null,
          finalPod: payload?.endCountry || null,
          cargoType: payload?.cargoType,
          validFrom: payload?.validForm || '',
          validTo: payload?.validTill,
          status: payload?.status,

          instructionName: payload?.instructionName,
          standingInstructionId: payload?.standingInstructionId,
          agentNominationInput: {
            agentNominationId: null,
            agentPartnerId: payload?.agentPartnerId,
            blId: null,
            requestedPartnerId: payload?.requestedPartnerId,
            agentTaxId: payload?.taxId,
            companyName: payload?.companyName,
            emailId: payload?.email,
            address: payload?.address,
            blAccess: payload?.blAccess,
            accessList: payload?.accessList || [],
            requestType: payload?.requestType || '',
            partyName: payload?.partyName,
            partyEmail: payload?.partyEmail
          },
        }
      );

      return response;
    } catch (e) {
      const error = e as AxiosError;
      console.error( error);
      return {};
    }
  }
);

export const getBlanketDeligation = createAsyncThunk(
  'instruction/getBlanketDeligation',
  async (payload: any) => {
    const response = await axiosOdex.get(
      `/api/edo-rest-v1/v1/instruction/getStandingInstruction/${payload?.docId}?linerId=${payload?.selectedLinerId}`
    );
    return response?.data;
  }
);

export const assignInstructionsToSelf = createAsyncThunk(
  'common/assignInstructionsToSelf',
  async (payload: any[]) => {
    const response =  await axiosOdex.post(
      '/api/edo-rest-v1/v1/common/assignInstructionsToSelf',
      payload
    );
    return response?.data;
  }
);

export const getReleaseAttConf = createAsyncThunk(
  'demurrage/getReleaseAttConf',
  async (payload: {country:string; liner:any;},{dispatch}) =>{
    try{
    const response = await axiosOdex.get(
      `/api/edo-rest-v2/v2/common/attachments?countryCode=${payload.country}&linerId=${payload.liner}&instructionType=804`
    )
    dispatch(updateAttachmentList(
      response.data?.map?.((i:any)=>({
        id:i?.docId,
        name:i.docName,
        m:i.isMandatory=='Y',
      }))
    ))
    }
    catch(e){
      return e;
    }
}
)
