import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { InstructionList } from "../types";

import {
  addInstruction,
  approveInstruction,
  rejectInstruction,
} from "../../blRequestInstruction/components/actions/Instruction";
import { getCoreror } from "../actions/instruction";
import { getOBLDetails } from "../actions/instruction";

interface IInstructionList {
  list: InstructionList[];
  next: string | null;
  previous: string | null;
}

interface InstructionSlice {
  data: IInstructionList;
  dataLoading: boolean;
  showColumnSettings: boolean;
  addInstruction: boolean;
  approveInstruction: boolean;
  rejectInstruction: boolean;
}

const initialState: InstructionSlice = {
  data: {
    next: null,
    previous: null,
    list: [],
  },
  dataLoading: true,

  showColumnSettings: false,
  addInstruction: false,
  approveInstruction: false,
  rejectInstruction: false,
};

export const instructionSlice = createSlice({
  name: "instruction",
  initialState,
  reducers: {
    toggleAddInstruction: (state, action: PayloadAction<boolean>) => {
      state.addInstruction = action.payload;
    },
    toggleApproveInstruction: (state, action: PayloadAction<boolean>) => {
      state.approveInstruction = action.payload;
    },
    togglerejectInstruction: (state, action: PayloadAction<boolean>) => {
      state.rejectInstruction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addInstruction.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addInstruction.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(approveInstruction.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(approveInstruction.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(rejectInstruction.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(rejectInstruction.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getCoreror.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getCoreror.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getOBLDetails.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getOBLDetails.fulfilled, (state: any, action) => {
      state.dataLoading = false;

      state.data = action.payload;
    });
  },
});

export const { toggleAddInstruction, toggleApproveInstruction } =
  instructionSlice.actions;

export default instructionSlice.reducer;
