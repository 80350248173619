import { useSelector } from "react-redux";
import DataTable from "../../common/components/DataTable";
import { AppDispatch, RootState } from "../../store";
import { CreditBalanceBlPaymentDetails } from "./slices/creditBalance";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getCreditBalance } from "../blRequestInstruction/components/actions/Instruction";
import { useDispatch } from "react-redux";
import { updateSelectedBlListMultiPay } from "../multiBlPayment/slices/multiBlPaymentSlice";
import { selectedCredit } from "../../common/slices/creditBalanceSlice";
const CreditBalance = () => {
  const { t } = useTranslation();
  const data = useSelector(
    (state: RootState) => state.creditBalanceBlPayment.data
  );
  const dispatch = useDispatch<AppDispatch>();

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const date = new Date(dateString);
    return date.toLocaleString("en-US", options).replace(/,/, "");
  };
  const selecteCredData = useSelector(
    (state: RootState) => state.creditBalance.selectedCreditData
  );
  const selectedCreditAmount = selecteCredData
    .map((i: any) => Number(i.unAllocatedAmount))
    .reduce((partialSum: any, a: any) => partialSum + a, 0);
  const amountDetails = useSelector(
    (state: RootState) => state.blPayment.amountDetails
  );

  const _onSelect = (data: any) => {
    // if(selectedCreditAmount <= amountDetails){
    //   dispatch(selectedCredit(data.list));
    // }
    dispatch(selectedCredit(data.list));

    // dispatch(updateSelectedBlListMultiPayTable(data.rows));
  };

  const columns: {
    accessor: keyof CreditBalanceBlPaymentDetails;
    Header: string;
    minWidth: number;
    width: number;
    maxWidth: number;
    Cell?: any;
  }[] = [
    {
      accessor: "pmtReference",
      Header: t("resource_2:paymentRef"),
      minWidth: 120,
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "pmtTime",
      Header: t("resource_2:time"),
      minWidth: 120,
      width: 200,
      maxWidth: 150,
      Cell: (rowData: any) => {
        const { pmtTime } = rowData.row.original;
        return (
          <div style={{ width: "100%" }}>
            <span> {formatDate(pmtTime)}</span>
          </div>
        );
      },
    },
    {
      accessor: "unAllocatedAmount",
      Header: t("resource_1:amount"),
      minWidth: 120,
      width: 200,
      maxWidth: 150,
    },
  ];

  return (
    <>
      {data !== undefined && data.length >= 0 && (
        <DataTable
          columns={columns}
          data={data}
          gridName="CREDIT_BALANCE"
          selection
          searchable={false}
          pagination={false}
          autoHeight={true}
          onCustomSelectChange={_onSelect}
          sortable
          perPage={10}
          showColumnFilter={false}
          showExport={false}
          uniqueFilterKey="creditBalance"
        />
      )}
    </>
  );
};

export default CreditBalance;
