/**
 * Editable invoice details table to be shown on the quickpay screen
 */

import { Button } from "react-bootstrap";
import DataTable from "../../common/components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getUserDataList } from "./MappingActions";


export default function MappingUserList() {

    const { t } = useTranslation()
    const data = useSelector((state: RootState) => state.mappingDashboard.userListData)
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const {custId} = useParams()

    useEffect(()=>{
        if(custId)
        dispatch(getUserDataList({custId}))
    },[custId])

    const invoiceTableColumnConfig = [
        {
            accessor: 'id',
            Header: 'id',
        },
        {
            accessor: 'email',
            Header: t('resource_2:emailID'),
            width: 150,
            maxWidth: 180,
            Cell: (rowData: any) => {
                const { email, id } = rowData.row.original;

                return <span className="text-primary cursor-pointer" onClick={() => {
                    navigate(`/mappingdashboard/customerdetails/${custId}/edituser/${id}`)
                }}>{email}</span>

            },
        },
        {
            accessor: 'name',
            Header: 'User Name',
            width: 180,
            maxWidth: 180,
        },
        {
            accessor: 'group',
            Header: 'User Group',
            width: 250,
            maxWidth: 300,
            Cell: (rowData: any) => {
                const { userGrpName } = rowData.row.original;
                return <span>{userGrpName.join(', ')}</span>
            },
        },
        {
            accessor: 'status',
            Header: 'Status',
            width: 180,
            maxWidth: 180,
            Cell: (rowData: any) => {
                const { status } = rowData.row.original;

                return <span>{status ? 'Active' : 'Inactive'}</span>
            },
        }
    ];

    return <div className='col-lg-12 mt-2 mb-2 demmurageTable d-flex flex-column'>

        <Button
            variant='falcon-primary'
            style={{
                width: '80px',
                marginRight: '10px',
                marginBottom: '10px',
            }}
            size='sm'
            onClick={() => {
                navigate(`/mappingdashboard/customerdetails/${custId}/edituser`)
            }}>
            <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
            <span>User</span>
        </Button>

        <DataTable
            gridName='STANDALONE'
            columns={invoiceTableColumnConfig}
            data={data}
            shortPrepend='qp_'
            perPage={100}
            sortable={true}
            searchable={false}
            showColumnFilter={false}
            selection={false}
            pagination={false}
            seekPagination={false}
            showExport={false}
            localFilter={true}
            uniqueFilterKey='userList'
            autoHeight={true}
            maxHeight='35vh'
            tableMinHeight={300}
            staticHiddenCols={['id']}
        />
    </div>
}