import { faCircleCheck, faCircleXmark, faCheckCircle, faTimes, faTimesCircle,   } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function UploadIndicator({progress=null, fail, style}:{progress?:number|null, fail?:boolean, style:any}){
    const done = progress == 100
    const red = '#e63757'
    const blue = "#2c7be5"
    const grey = "#ccc"
    const green = '#00d27a'
    if(progress == null) return <></>
    progress = Math.max(progress, 0)

    if(fail) return <div style ={style}>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className='text-danger ps-1'
              size='lg'
            />
        </div>

    if(done) return <div style ={style}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                className='fa-circle-check'
                style={{color:'rgba(14, 163, 100, 0.8117647059)'}}
                size='lg'
              />
    </div>


    return <div style ={style}>
        <svg width={20} height={20} style={{ transform : 'rotate(-90deg)'}}>
            <circle cx={10} cy={10} r='8'
                fill="none" stroke={grey} strokeWidth={3}
            ></circle>
            <circle cx={10} cy={10} r='8'
                fill="none" stroke={blue} strokeWidth={3}
                strokeDasharray={50}
                strokeDashoffset={(100-progress)*0.5}
                style={{transition:'stroke-dashoffset 0.4s ease-out'}}
             
            ></circle>
        </svg>
    </div>   
}