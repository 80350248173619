import { createSlice } from "@reduxjs/toolkit";
import { BLDetailsList } from "../types";

import { getBlsListExportCSV } from "../actions/dashboard";

interface IBlList {
  list: BLDetailsList[];
  next: string | null;
  previous: string | null;
}

interface IDashboardCSVSlice {
  data: IBlList;
  showPayment: any;
  showInstruction: any;
  showRelease: any;
  dataLoading: boolean;
  showColumnSettings: boolean;
  showBlExists: boolean;
  showAddAgent: boolean;
  showNonimationReq: boolean;
  enableAddAgentButton: boolean;
  // columnConfiguration: IDataTableColumn[];
}

const initialState: IDashboardCSVSlice = {
  data: {
    next: null,
    previous: null,
    list: [],
  },
  dataLoading: true,
  showPayment: "",
  showInstruction: "",
  showRelease: "",
  showColumnSettings: false,
  showAddAgent: false,
  showBlExists: false,
  enableAddAgentButton: false,
  showNonimationReq: false,

  // columnConfiguration: columnConfig,
};

export const DashboardCSVSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getBlsListExportCSV.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getBlsListExportCSV.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
  },
});

export default DashboardCSVSlice.reducer;
