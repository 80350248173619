import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Home } from '../../../routes/routes';

interface INavbarVertical {
  routes: any;
}

const initialState: INavbarVertical = {
  routes: [Home],
};

export const navbarVerticalSlice = createSlice({
  name: 'navbarVertical',
  initialState,
  reducers: {
    updateNavbarVerticalRoutes: (state, action: PayloadAction<any>) => {
      state.routes = action.payload;
    },
  },
});

export const { updateNavbarVerticalRoutes } = navbarVerticalSlice.actions;

export default navbarVerticalSlice.reducer;
