import React from 'react';
import PropTypes from 'prop-types';
/* import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Background from '../components/common/Background';
import Flex from '../components/common/Flex';
import Section from '../components/common/Section';
import SignUpBox from './SignUpBox';
import bgShape from '../assets/img/illustrations/bg-shape.png';
import shape1 from '../assets/img/illustrations/shape-1.png';
import halfCircle from '../assets/img/illustrations/half-circle.png'; */
import LogoOne from '../../../assets/images/logo.png';
/* import OdexLoginBg from '../assets/images/odexlogin-bg.jpg'; */
import {
  Container,
  Row,
  Col,
  Carousel,
  Image,
  Card,
  Form,
} from 'react-bootstrap';
/* import LoginForm from '../app/features/login/Login/LoginForm'; */
import {
  faCheck,
  faLock,
  faLockOpen,
  faPlus,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faDocker } from '@fortawesome/free-brands-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import ForgotPass from './ForgotPass';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Container fluid>
        <Row>
          <Col
            style={{ position: 'relative',marginTop: '100px' }}
            className='p-0 col-4 form-login'>
            {/* <SignUpBox /> */}
            <div className='login-wrap'>
              <Card className='h-100 pt-4 pb-5 p-4'>
                <img src={LogoOne} className='logoWidth pb-5' />
                <Card.Header className='cardHeader textLowerCase'>
                  {t('resource_2:forgotPassword')}
                </Card.Header>
                <Card.Body className='p-2 bodyHeightForgot'>
                  <ForgotPass />
                </Card.Body>
                <div className='col-12 fs--1 copyWriteText'>
                  Copyright © EA Technologies 2023. All rights reserved
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
ForgotPassword.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
};

export default ForgotPassword;
