import { useState } from "react";
import { Form } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NumberRange = ({ objectKey, ky, value, setValue, numberFilter }) => {
  const [amount, setAmount] = useState({
    from: null,
    to: null,
  });

  const handleClear = () => {
    setValue(objectKey, { ...value?.[objectKey], [ky]: "" });
  };

  return (
    <Form.Group className="d-flex">
      <div className="d-flex" style={{ position: "relative", width: "135px" }}>
        <Form.Control
          type="number"
          min={numberFilter?.min}
          max={numberFilter?.max}
          placeholder={ky=='from'?'Min':'Max'}
          size="sm"
          value={value?.[objectKey]?.[ky]}
          onChange={(e) => {
            setValue(objectKey, {
              ...value?.[objectKey],
              [ky]: e.target?.value ? parseInt(e.target.value) : e.target.value,
            });
          }}
        />
        {value?.[objectKey]?.[ky] && (
          <FontAwesomeIcon
            icon={faTimes}
            onClick={handleClear}
            className="cursor point"
            style={{
              position: "absolute",
              top: "50%",
              right: "34px",
              transform: "translateY(-60%)",
              color: "#281E5D",
            }}
          />
        )}
      </div>
    </Form.Group>
  );
};

export default NumberRange;
