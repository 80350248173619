import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { boolean } from 'yup';
import { deleveryLocTypeList, extensionTypeList, getExtensionRequest, getOBLDetails, getOBLForOverStay, populateExtensionRequest } from '../../../dashboard/actions/instruction';
import { Item } from 'react-bootstrap/lib/Breadcrumb';

export interface overstayExtensionDetails {
    needExtensionFor:string | null;
    plannedPickupDate?: Date | null;
    userRemark?: string;
    status:string;
    rejectRemarks:string;
    extensionRequestId:number | null;
    customerCode?:string | null;
    customerEmailId?:string | null;

}

export interface overstayExtensionTable {
   
    needExtensionFor:string | null;
    plannedPickupDate?: Date | null;
    containerNo:string;
    deliveryLocationType:string;
    currency: string;

   
}

  export interface Isnooze {
    data: Array<overstayExtensionTable>;
    overstayExtension: overstayExtensionDetails;
    extensionTypeOptions:any[];
    deleveryTypeLocation:any[];
    //containerList:any[]
   // dataLoading: boolean;
    
  }
  

const initialState: Isnooze = {
    data: [],
    //  dataLoading: true,
    overstayExtension: {
    needExtensionFor: null,
    plannedPickupDate: null,
    userRemark:'',
    status:'',
    rejectRemarks:'',
    extensionRequestId:null,
    customerCode:''
   
  },
    extensionTypeOptions:[],
    deleveryTypeLocation:[],
    //containerList:[]



};

export const overstayExtensionSlice = createSlice({
  name: 'overstayExtension',
  initialState,
  reducers: {
    clearData:(state)=>{
      state.overstayExtension = {
        needExtensionFor: '0',
        plannedPickupDate: null,
        userRemark: '',
        status:'',
        rejectRemarks:'',
        extensionRequestId:null,
        customerCode:''
       
        }
      state.data = []
      
    },
    updateSelectedneedExtensionFor: (state, action: PayloadAction<any>) => {
        state.data.forEach((Item)=>{Item.needExtensionFor=action.payload})

    },
    updateSelectedDeleveryLocType:(state,action:PayloadAction<any>)=>{
      state.data.forEach((Item)=>
        {
          Item.deliveryLocationType=action.payload
        })
    },
    updateSelectedCcy:(state,action:PayloadAction<any>)=>{
      state.data.forEach((Item)=>{Item.currency=action.payload})
    },
    updateSelectedPlannedPickupDate: (state, action: PayloadAction<any>) => {
      state.data.forEach((Item)=>{Item.plannedPickupDate=action.payload})
    },
    updateSelectedContainerNo: (state, action: PayloadAction<any>) => {
        const row = state.data.find((Item)=>Item.containerNo==action.payload.containerNo)
        if(!row)return
        row.needExtensionFor = action.payload.needExtensionFor
        if(action.payload.needExtensionFor!='1004')row.deliveryLocationType = '0'
    },
    updateSelectedDeleveryLocTypeOnContainer:(state,action:PayloadAction<any>)=>{
      const row =state.data.find((Item)=>Item.containerNo==action.payload.containerNo )
      if(!row)return
      row.deliveryLocationType = action.payload.deliveryLocationType

    }, 
    updateSelectedCCyOnContainer:(state,action:PayloadAction<any>)=>{
      const row =state.data.find((Item)=>Item.containerNo==action.payload.containerNo )
      if(!row)return
      row.currency = action.payload.currency
    }, 
    updateSelectedPickupDate: (state, action: PayloadAction<any>) => {
      const row = state.data.find((Item)=>Item.containerNo==action.payload.containerNo)
      if(!row)return
      row.plannedPickupDate = action.payload.plannedPickupDate
    }, 

    clearTableInputValues:(state)=>{
      state.data.forEach((item)=>{
        item.needExtensionFor='0'
        item.plannedPickupDate=null
        item.currency=''
        item.deliveryLocationType='0'
      })
    }
},
  extraReducers: (builder) => {
  builder.addCase(getExtensionRequest.pending, (state, action) => {
     // state.dataLoading = true;
     state.overstayExtension = {
      needExtensionFor: '0',
      plannedPickupDate: null,
      userRemark: '',
      status:'',
      rejectRemarks:'',
      extensionRequestId:null,
      customerCode:''
      }
    state.data = []

    });

    builder.addCase(getExtensionRequest.fulfilled, (state, action) => {
      state.overstayExtension.status=action?.payload?.data?.status
      state.overstayExtension.rejectRemarks=action?.payload?.data?.remarks
      state.overstayExtension.userRemark= action?.payload?.data?.userRemarks
      state.overstayExtension.extensionRequestId=action?.payload?.data?.extensionRequestId
      state.overstayExtension.customerCode=action?.payload?.data?.customerCode
      state.overstayExtension.customerEmailId=action?.payload?.data?.customerEmailId
      state.data =[]

      action.payload.data?.containerDetails?.forEach?.((item:any)=>{
          state.data.push({
            needExtensionFor:item.pickupLocation??0,
            plannedPickupDate:new Date(item.pickupDate),
            deliveryLocationType:item.deliveryLocationType??0,
            containerNo:item.cntNo,
            currency: item.currency??''
          })
      })

    });

    builder.addCase(populateExtensionRequest.fulfilled, (state, action) => {
      state.overstayExtension.status=action?.payload?.data?.status
      state.overstayExtension.rejectRemarks=action?.payload?.data?.remarks
      state.overstayExtension.userRemark= action?.payload?.data?.userRemarks
      state.overstayExtension.extensionRequestId=action?.payload?.data?.extensionRequestId
      state.overstayExtension.extensionRequestId=action?.payload?.data?.customerCode
      state.overstayExtension.customerEmailId=action?.payload?.data?.customerEmailId
      
      action.payload.data?.containerDetails?.forEach?.((item:any)=>{
          const row = state.data.find((i)=>item.cntNo == i.containerNo)
          if(!row){return}
         
          row.needExtensionFor = item.pickupLocation??0
          row.plannedPickupDate = new Date(item.pickupDate)
          row.deliveryLocationType = item.deliveryLocationType??0
          row.currency = item.currency??''
      })

    });

    builder.addCase(extensionTypeList.fulfilled,(state,action)=>{
      state.extensionTypeOptions = action.payload;

 })
 builder.addCase(deleveryLocTypeList.fulfilled,(state,action)=>{
  state.deleveryTypeLocation = action.payload
 })

 builder.addCase(getOBLForOverStay.fulfilled,(state,action)=>{
  const containerList =  action.payload.containerList.map(
    (i:any)=>
      ({
        containerNo: i.containerNo,
        needExtensionFor:0,
        plannedPickupDate:  null,
        deliveryLocationType:0,
        currency:''
      }));

  state.data.forEach((i)=>{
    const row :overstayExtensionTable |null = containerList.find((x:overstayExtensionTable)=>i.containerNo === x.containerNo)
    if(!row)return
    row.needExtensionFor = i.needExtensionFor
    row.plannedPickupDate = i.plannedPickupDate
    row.deliveryLocationType = i.deliveryLocationType
    row.currency = i.currency
  })
  
  state.data = containerList
})
  },
});


export const { clearData,updateSelectedneedExtensionFor,updateSelectedContainerNo,updateSelectedPickupDate,updateSelectedPlannedPickupDate,updateSelectedDeleveryLocTypeOnContainer,updateSelectedDeleveryLocType,clearTableInputValues, updateSelectedCCyOnContainer, updateSelectedCcy } = overstayExtensionSlice.actions; 
export default overstayExtensionSlice.reducer;
