import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const getUsernameFromMetaTag = () => {
  const querySelector = document.querySelector("meta[name='user']");
  return querySelector ? querySelector.getAttribute('content') : null;
};

export interface INavbarTop {
  searchInputValue: string;
  searchType: string;
  blTopSearchState: any;
  invoiceTopSearchState: any;
  instructionTopSearchState: any;
  userTopSearchState: any;
  standingInstSearchState: any;
  selectedTopSearchFilter: any;
  paymentFundTopSearch: any;
  paymentTopSearchState: any;
  receiptTopSearchState: any;
  mappingTopSearchState:any;
  reportTopSearchState: any;
  selectedDashbaordName: string;
  prevSearchInput: string;
  userName: any;
  linerId: string;
}

const initialState: INavbarTop = {
  searchInputValue: '',
  searchType: '1',
  blTopSearchState: {},
  invoiceTopSearchState: {},
  instructionTopSearchState: {},
  userTopSearchState: {},
  paymentTopSearchState: {},
  receiptTopSearchState:{},
  standingInstSearchState: {},
  paymentFundTopSearch: {},
  mappingTopSearchState:{},
  reportTopSearchState:{},
  selectedTopSearchFilter: {},
  selectedDashbaordName: '',
  prevSearchInput: '',
  userName: getUsernameFromMetaTag(),
  linerId: '',
};

export const navbarTopSlice = createSlice({
  name: 'navbarTop',
  initialState,
  reducers: {
    updateSearchInputValueNT: (state, action: PayloadAction<string>) => {
      state.searchInputValue = action.payload;
    },
    updateTopSearchFilter: (state, action: PayloadAction<object>) => {
      state.selectedTopSearchFilter = action.payload;
    },
    updateDashbaordName: (state, action: PayloadAction<any>) => {
      state.selectedDashbaordName = action.payload;
    },
    updateBlTopSearchState: (state, action: PayloadAction<any>) => {
      state.blTopSearchState = action.payload;
    },
    updateInvoiceTopSearchState: (state, action: PayloadAction<any>) => {
      state.invoiceTopSearchState = action.payload;
    },
    updatePaymentTopSearchState: (state, action: PayloadAction<any>) => {
      state.paymentTopSearchState = action.payload;
    },
    updateReceiptTopSearchState: (state, action: PayloadAction<any>) => {
      state.receiptTopSearchState = action.payload;
    },
    updateMappingTopSearchState: (state, action: PayloadAction<any>) => {
      state.mappingTopSearchState = action.payload;
    },
    updateReportTopSearchState: (state, action: PayloadAction<any>) => {
      state.reportTopSearchState = action.payload;
    },
    updateInstructionTopSearchState: (state, action: PayloadAction<any>) => {
      state.instructionTopSearchState = action.payload;
    },
    updatePaymentFundTopSearch: (state, action: PayloadAction<any>) => {
      state.paymentFundTopSearch = action.payload;
    },
    updateUserTopSearchState: (state, action: PayloadAction<any>) => {
      state.userTopSearchState = action.payload;
    },
    setSearchType: (state, action: PayloadAction<string>) => {
      state.searchType = action.payload;
    },

    updatePrevSearchInput: (state, action: PayloadAction<string>) => {
      state.prevSearchInput = action.payload;
    },
    updateLinerId: (state, action: PayloadAction<any>) => {
      state.linerId = action.payload;
    },
  },
});

export const {
  updateSearchInputValueNT,
  updateTopSearchFilter,
  setSearchType,
  updateBlTopSearchState,
  updateInvoiceTopSearchState,
  updateDashbaordName,
  updatePrevSearchInput,
  updateInstructionTopSearchState,
  updatePaymentFundTopSearch,
  updateUserTopSearchState,
  updateLinerId,
  updatePaymentTopSearchState,
  updateReceiptTopSearchState,
  updateReportTopSearchState,
  updateMappingTopSearchState
} = navbarTopSlice.actions;

export default navbarTopSlice.reducer;
