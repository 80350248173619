import { useTranslation } from 'react-i18next';
import DataTable from '../../common/components/DataTable';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerCodeDataList, getUserDataList, mergeMatchCodesToUser } from './MappingActions';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRedo, faSave } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { clearMatchCodeList } from './MappingSlice';
import { useBackNavigation } from '../../../hooks/BackNavigation';


const MatchCodeList = () => {
    const { t } = useTranslation();
    const data = useSelector((state: RootState) => state.mappingDashboard.matchCodeList)
    const dispatch = useDispatch<AppDispatch>()
    const {custId} = useParams()

    const onBack = useBackNavigation()

    const columnConfig = [
        {
            accessor: 'email',
            Header: t('resource_1:email'),
            width: 180,
            maxWidth: 180
        },
        {
            accessor: 'name',
            Header: 'Company Name',
            width: 180,
            maxWidth: 180
        },
        {
            accessor: 'matchCode',
            Header: 'Match Code',
            width: 180,
            maxWidth: 180,
        },
        {
            accessor: 'customerCode',
            Header: 'Customer Code',
            width: 180,
            maxWidth: 180,
        },
    ];

    return (
        <div className='col-lg-12 mt-2 mb-2 demmurageTable d-flex flex-column'>
            <Form.Label style={{color:'#E63757'}}>NOTE: Below List of users will also merge into this customer</Form.Label>
            <DataTable
                gridName='MATCHCODE'
                columns={columnConfig}
                data={data}
                shortPrepend='qp_'
                perPage={100}
                sortable={true}
                searchable={false}
                showColumnFilter={false}
                selection={false}
                pagination={false}
                seekPagination={false}
                showExport={false}
                localFilter={true}
                uniqueFilterKey='userList'
                autoHeight={true}
                maxHeight='35vh'
                tableMinHeight={300}
                staticHiddenCols={[]}
            />

            <div className='d-flex flex-row align-items-center justify-content-center'>

            <div className='d-flex justify-content-center align-items-center'>
                <Button
                    className='me-2'
                    variant='falcon-primary'
                    size='sm'
                    onClick={() => {
                        const mstPartnerId = custId
                        dispatch(mergeMatchCodesToUser({custId:mstPartnerId})).unwrap().then(()=>{
                            onBack()
                            setTimeout(()=>{
                                dispatch(getUserDataList({custId:mstPartnerId}))
                                dispatch(getCustomerCodeDataList({custId:mstPartnerId}))
                            },2000)
                        }).catch(()=>{})
                    }}
                >
                    <FontAwesomeIcon icon={faSave} className='me-2' />
                    Confirm
                </Button>
            </div>

            <div className='d-flex justify-content-center align-items-center'>
                <Button
                    className='me-2'
                    variant='falcon-danger'
                    size='sm'
                    onClick={() => {
                        dispatch(clearMatchCodeList())
                    }}
                >
                    <FontAwesomeIcon icon={faRedo} className='me-2' />
                    {t('resource_1:cancel')}
                </Button>
            </div>
            </div>
        </div>
    );
};

export default MatchCodeList;
