import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BLDetailsList } from '../types';
//todo bllist
import {
  // getBlDashboardDefinition,
  getBlsList,
  getInstructionsListFilter,
  searchWithBlno,
  searchWithContainerno,
  searchWithInternalVoyageNo,
  searchWithInternalVoyageNoPaymentDashboard,
  searchWithShipmentNoBlDashboard,
  searchWithShipmentno,
  getInstructionListWithBlnumber,
  // getInstructionList,
} from '../../dashboard/actions/dashboard';
import { IDataTableColumn } from '../../../common/types/dataTable';
import { IConfigsDefinition } from '../../../utils/columnConfig';

interface IBlList {
  list: BLDetailsList[];
  next: string | null;
  previous: string | null;
}

interface RegisterDashboardSlice {
  data: IBlList;
  showPayment: any;
  registrationMasterDisable: any;
  showInstruction: any;
  showRelease: any;
  dataLoading: boolean;
  showColumnSettings: boolean;
  showBlExists: boolean;
  showAddAgent: boolean;
  showNonimationReq: boolean;
  enableAddAgentButton: boolean;
  blDashboardDefinition: any[];
  blDashboardDefinitionLoading: boolean;
  columns: IDataTableColumn[];
  configs: IConfigsDefinition;
  setDefaultViewBL: boolean;
  setDefaultViewInvoice: boolean;
  obldetails: boolean;
  // columnConfiguration: IDataTableColumn[];
}

const initialState: RegisterDashboardSlice = {
  data: {
    next: null,
    previous: null,
    list: [],
  },
  dataLoading: true,
  showPayment: '',
  showInstruction: '',
  showRelease: '',
  showColumnSettings: false,
  showAddAgent: false,
  showBlExists: false,
  enableAddAgentButton: false,
  registrationMasterDisable: false,
  showNonimationReq: false,
  blDashboardDefinition: [],
  blDashboardDefinitionLoading: false,
  columns: [],
  configs: {
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  },
  setDefaultViewBL: true,
  setDefaultViewInvoice: true,
  obldetails: false,

  // columnConfiguration: columnConfig,
};

export const registerDashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateSetDefaultViewBL: (state, action: PayloadAction<boolean>) => {
      state.setDefaultViewBL = action.payload;
    },
    updateSetDefaultViewInvoice: (state, action: PayloadAction<boolean>) => {
      state.setDefaultViewInvoice = action.payload;
    },
    updateRegistrationMaster: (state, action: PayloadAction<boolean>) => {
      state.registrationMasterDisable = action.payload;
    },
    updateDashboardBlList: (state, action: PayloadAction<IBlList>) => {
      state.data = action.payload;
    },

    updateColumnDefinitionConfigs: (
      state,
      action: PayloadAction<IConfigsDefinition>
    ) => {
      state.configs = action.payload;
    },
    updateColumnDefinition: (
      state,
      action: PayloadAction<IDataTableColumn[]>
    ) => {
      state.columns = action.payload;
    },
    getPaymentStatus: (state, action: PayloadAction<IBlList>) => {
      state.showPayment = action.payload;
    },
    getInstructionStatus: (state, action: PayloadAction<IBlList>) => {
      state.showInstruction = action.payload;
    },
    getReleaseStatus: (state, action: PayloadAction<IBlList>) => {
      state.showRelease = action.payload;
    },
    toggleColumnSettingsDashboard: (state, action: PayloadAction<boolean>) => {
      state.showColumnSettings = action.payload;
    },
    toggleAddAgent: (state, action: PayloadAction<boolean>) => {
      state.showAddAgent = action.payload;
    },
    toggleIsBlExists: (state, action: PayloadAction<boolean>) => {
      state.showBlExists = action.payload;
    },
    toggleAddAgentButton: (state, action: PayloadAction<boolean>) => {
      state.enableAddAgentButton = action.payload;
    },
    toggleNominationReq: (state, action: PayloadAction<boolean>) => {
      state.showNonimationReq = action.payload;
    },
    toggleObldetails: (state, action: PayloadAction<boolean>) => {
      state.obldetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getBlDashboardDefinition.pending, (state, action) => {
    //   state.blDashboardDefinitionLoading = true;
    // });
    // builder.addCase(getBlDashboardDefinition.fulfilled, (state, action) => {
    //   state.blDashboardDefinitionLoading = false;
    //   state.blDashboardDefinition = action.payload;
    // });
    // builder.addCase(getInstructionList.pending, (state, action) => {
    //   state.dataLoading = true;
    // });
    // builder.addCase(getInstructionList.fulfilled, (state: any, action) => {
    //   state.dataLoading = false;
    //   state.data = action.payload;
    // });

    builder.addCase(getInstructionsListFilter.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getInstructionsListFilter.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.data = action.payload;
    });

    builder.addCase(searchWithBlno.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(searchWithBlno.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(
      searchWithShipmentNoBlDashboard.pending,
      (state, action) => {
        state.dataLoading = true;
      }
    );
    builder.addCase(
      searchWithShipmentNoBlDashboard.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(searchWithInternalVoyageNo.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(searchWithInternalVoyageNo.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });

    builder.addCase(searchWithShipmentno.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(searchWithShipmentno.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(searchWithContainerno.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(searchWithContainerno.fulfilled, (state, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(
      searchWithInternalVoyageNoPaymentDashboard.pending,
      (state, action) => {
        state.dataLoading = true;
      }
    );
    builder.addCase(
      searchWithInternalVoyageNoPaymentDashboard.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getInstructionListWithBlnumber.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(
      getInstructionListWithBlnumber.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );
  },
});

export const {
  updateSetDefaultViewBL,
  updateDashboardBlList,
  updateColumnDefinitionConfigs,
  updateColumnDefinition,
  updateSetDefaultViewInvoice,
  toggleColumnSettingsDashboard,
  toggleAddAgent,
  toggleAddAgentButton,
  toggleNominationReq,
  toggleIsBlExists,
  getPaymentStatus,
  getInstructionStatus,
  getReleaseStatus,
  toggleObldetails,
  updateRegistrationMaster,
} = registerDashboardSlice.actions;

export default registerDashboardSlice.reducer;
