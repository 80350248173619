import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { data } from '../data/standingInstructionTable';

interface StandingInstruction {
  
}

interface IStandingInstructionList {
  list: StandingInstruction[];
  next: string | null;
  previous: string | null;
}

interface StandingInstructionTableData {
  instructionName: string;
  pol: string;
  pod: string;
  startDate: string;
  endDate: string;
  agentTaxId: string;
  companyName: string;
  submittedByEmail: string;
  freightInvoice: string;
  autoSubmit: string;
  instructionData: IStandingInstructionList;
}

interface IRowColumns {
  mainTableClass: string;
  outletClass: string;
}

interface StandingInstructionTable {
  instructionTypes : Array<any>;
  data: StandingInstructionTableData;
  selectedRows: any;
  enableDelete: boolean;
  CarrierHaulage:boolean;
  billToPartyChange:boolean;
  extensionReq:boolean;
  NominateAgent:boolean;
  enableBlPayment:boolean;
  dataLoading: boolean;
  showColumnSettings: boolean;
  rowColumns: IRowColumns;
  toggle: boolean,
}

const initialState: StandingInstructionTable = {
  instructionTypes:[],
  data: {
    instructionName: '',
    pol: '',
    pod: '',
    startDate: '',
    endDate: '',
    agentTaxId: '',
    companyName: '',
    submittedByEmail: '',
    freightInvoice: '',
    autoSubmit: '',
    instructionData: {
      list: [],
      next: '',
      previous: '',
    }
  },
  selectedRows: {},
  enableDelete: false,
  CarrierHaulage:false,
  billToPartyChange:false,
  extensionReq:false,
  NominateAgent:false,
  enableBlPayment:false,
  rowColumns: {
    mainTableClass: 'col-lg-12',
    outletClass: 'd-none',
  },
  dataLoading: false,
  showColumnSettings: false,
  toggle: false,
};

export const standingInstructionTableSlice = createSlice({
  name: 'standingInstructionTable',
  initialState,
  reducers: {
    // updateStanInsTabData: (
    //   state,
    //   action: PayloadAction<StandingInstructionTableData[]>
    // ) => {
    //   state.data = action.payload;
    // },
    updateStanInsSelectedRows: (state, action: PayloadAction<any>) => {
      state.selectedRows = action.payload;
    },
    toggleEnableDelete: (state, action: PayloadAction<boolean>) => {
      state.enableDelete = action.payload;
    },
    enableCarrierHaulage: (state, action: PayloadAction<boolean>) => {

      state.CarrierHaulage=action.payload;
             

    },
    enableNominateAgent: (state, action: PayloadAction<boolean>) => {

      state.NominateAgent=action.payload;
             

    },
    enablebillToPartyChange: (state, action: PayloadAction<boolean>) => {
      state.billToPartyChange=action.payload;
    },
    
    enableBlPayment: (state, action: PayloadAction<boolean>) => {
      state.billToPartyChange=action.payload;
    },
    enableextensionReq: (state, action: PayloadAction<boolean>) => {
      state.extensionReq=action.payload;
    },
    toggleColumnSettingsDashboard: (state, action: PayloadAction<boolean>) => {
      state.showColumnSettings = action.payload;
    },
    updateRowColumns: (state, action: PayloadAction<IRowColumns>) => {
      state.rowColumns = action.payload;
    },
    updateToggle: (state, action: PayloadAction<StandingInstructionTable>) => {
      state.toggle = action.payload.toggle;
    },
    updateStandingInstructionTypes: (state, action: PayloadAction<Array<any>>) =>{
      state.instructionTypes = action.payload;
    }
  },
});

export const {
  //updateStanInsTabData,
  updateStanInsSelectedRows,
  toggleEnableDelete,
  enableCarrierHaulage,
  enablebillToPartyChange,
  enableextensionReq,
  enableNominateAgent,
  enableBlPayment,
  toggleColumnSettingsDashboard,
  updateRowColumns,
  updateToggle,
  updateStandingInstructionTypes
} = standingInstructionTableSlice.actions;

export default standingInstructionTableSlice.reducer;
