import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { boolean } from 'yup';
import { getSnooze } from '../actions/instruction';
import { data } from '../../standingInstruction/data/standingInstructionTable';

export interface snoozeDetails {
   
    snooze:string | null;
    noOfDays: number|null;
    userSnoozeRemark: string;
    remarkby:string
    emailTo:string;
    subject:string;
    externalRemarks:boolean;
    
}


interface IsnoozeList {
    list: snoozeDetails[];
    /*   next: string | null;
      previous: string | null; */
  }

  export interface Isnooze {
    data: IsnoozeList;
    snoozeData: snoozeDetails;
   // dataLoading: boolean;
    
  }
  

const initialState: Isnooze = {
    data: {
        list: [],
        /*   next: null,
          previous: null, */
      },
    //  dataLoading: true,
    snoozeData: {
    noOfDays: null,
    snooze: null,
    userSnoozeRemark:'',
    emailTo:'',
    subject:'',
    remarkby:'',
    externalRemarks:false
     
    }



};

export const snoozeSlice = createSlice({
  name: 'snooze',
  initialState,
  reducers: {
    clearData:(state)=>{
      state.snoozeData = {
        noOfDays: null,
        snooze: null,
        userSnoozeRemark:'',
        emailTo:'',
        subject:'',
        remarkby:'',
        externalRemarks:false
        }
      state.data.list = []
      
    }
},
  extraReducers: (builder) => {
    builder.addCase(getSnooze.pending, (state, action) => {
     // state.dataLoading = true;
     state.snoozeData = {
      noOfDays: null,
      snooze: null,
      userSnoozeRemark:'',
      emailTo:'',
      subject:'',
      remarkby:'',
      externalRemarks:false
      }

    });
  
    builder.addCase(getSnooze.fulfilled, (state, action) => {
      state.snoozeData = {
        noOfDays: action?.payload?.data?.snoozeDays,
        snooze:action?.payload?.data?.snoozeStatus,
        userSnoozeRemark:action?.payload?.data?.snoozeRemarks,
        emailTo:action?.payload?.data?.snoozeEmailId,
        subject:action?.payload?.data?.snoozeSubject,
        remarkby:action?.payload?.data?.snoozedBy,
        externalRemarks:action?.payload?.data?.externalRemarks
        
      };
    });
  },
});


export const { clearData } = snoozeSlice.actions; 
export default snoozeSlice.reducer;
