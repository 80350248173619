import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'react-bootstrap';

import Flex from '../../common/Flex';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircleQuestion,
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';


const HelpDocDropDown = () => {
  const profileDetails = useSelector((state) => state.userProfile.profile);

  const [configList, setConfigList] = useState([])
  useEffect(()=>{
    try{
    if(profileDetails?.partnerCountry){
      setConfigList(require(`../help-config/${profileDetails?.partnerCountry}.json`))
    }
    }catch{
      setConfigList([])
    }
  }, [profileDetails?.partnerCountry]) 
  
  if(profileDetails?.partnerType !== 'customer' || configList?.length < 1)return <></>

  return (
    <Dropdown navbar={true} as='li'>
        <Dropdown.Toggle
          bsPrefix='toggle'
          as={Link}
          to='#!'
          className='pe-0 ps-2 nav-link '>
          <Flex
            direction={'row'}
            justifyContent='end'
            alignItems={'center'}
            alignContent=''
            inline=''
            wrap=''
            className=''
            children=''
            breakpoint=''>
            
              <Button
                variant='ms-3 btn btn-falcon-primary z-inxe-agent btn-sm'
                size='sm'
                title='Help Document'
                className='ms-3'>
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className='me-2 '
                  style={{ fontSize: '14px', color: '#f5822a' }}
                  title='Help Document'
                />
                <span>Help</span>
              </Button>
          </Flex>
        </Dropdown.Toggle>
        <Dropdown.Menu className='dropdown-caret dropdown-menu-card dropdown-menu-end'>
        <div className='bg-white rounded-2 py-2 dark__bg-1000'>
          
          {configList.map((item, index)=>
          item.support?
            <Dropdown.Item onClick={()=>{
              navigator.clipboard.writeText(item.support)
              toast('Copied to clipboard')
              }}>
              <div class='row g-2 align-items-sm-center'>
                <div class='col-auto'> Support Mail</div>
                <div class='col'>
                  <div class='row align-items-center'>
                    <div class='col col-lg-8'>
                      <span style={{ fontSize: '16px !important' }}>

                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className='me-2 '
                          style={{
                            fontSize: '16px !important',
                            color: '#f5822a',
                          }}
                          title='Support Mail'
                        />
                      </span>
                      <span class='fs-0 mb-3 mb-sm-0 text-primary'>
                        {item.support}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown.Item>
          :<>
            {index!=0 &&<Dropdown.Divider />}
            <Dropdown.Item style={{cursor:'default'}}>
              <div className='d-flex flex-row bd-highlight  justify-content-between'>
                <div className='me-3'>{item.title} {' '}</div>
                <div style={{alignSelf:'flex-end'}}>
                <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
                  {item.video?.length> 0 && 
                    <span onClick={()=>window.open(item.video, '_blank')} to={''}
                    style={{cursor:'pointer'}}>
                        <FontAwesomeIcon icon={faYoutube} className='me-2' />
                    </span>
                  }

                  {item.pdf?.length > 0 &&
                    <span onClick={()=>window.open(item.pdf, '_blank')} to={''}
                    style={{cursor:'pointer'}}>
                      <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                    </span>
                  }
                  
                </div>
              </div>
            </Dropdown.Item>
          </>
          )}

        </div>
        </Dropdown.Menu>
    </Dropdown>
  )
};

export default HelpDocDropDown;
