import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { boolean } from 'yup';

interface IThemeSettingsSlice {
  userPrimaryColor: string;
  showMainLayout: boolean;
}

const initialState: IThemeSettingsSlice = {
  userPrimaryColor: '',
  showMainLayout: true,
};

export const themeSettingsSlice = createSlice({
  name: 'themeSettings',
  initialState,
  reducers: {
    toggleMainLayout(state, action: any){
      state.showMainLayout = action.payload?? !state.showMainLayout
    },
    updateUserPrimaryColor: (state, action: PayloadAction<string>) => {
      state.userPrimaryColor = action.payload;
    },
  },
});

export const { updateUserPrimaryColor,toggleMainLayout} = themeSettingsSlice.actions;

export default themeSettingsSlice.reducer;
