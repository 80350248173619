import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BLDetailsList } from '../types';
import ReleasedTableData from '../data/releasedTable.json';

interface ReleasedTableSlice {
  data: BLDetailsList[];
}

const initialState: ReleasedTableSlice = {
  data: ReleasedTableData.data,
};

export const releasedTableSlice = createSlice({
  name: 'releasedTable',
  initialState,
  reducers: {
    updateReleasedTableList: (
      state,
      action: PayloadAction<BLDetailsList[]>
    ) => {
      state.data = action.payload;
    },
  },
});

export const { updateReleasedTableList } = releasedTableSlice.actions;

export default releasedTableSlice.reducer;
