import { createSlice } from '@reduxjs/toolkit';
import { getCodeSearchListData, getCompanyDetails, getCustomerCodeDataList, getGroupTypes, getMatchCodeListData, getUserDataList } from './MappingActions';
import { Interface } from 'readline';

interface ICompanyDetails{
  companyName: string;
  phoneNo:string;
  taxId:string;
  address:string;
  country:string;
}

interface IEditUser{
  email: string;
  name:string;
  group:Array<string>;
  password:string;
  status:boolean;
  country:string;
  id: string|null,
  grpType: Array<string>,
  isRootUser: string,
  title: string,
  userGrpName: Array<string>,
  phnNo: string|null,
}

interface IMappingUserList{
  id: string;
  email:string;
  name:string;
  group: Array<string>;
  status: boolean;
  country: string;
  grpType: Array<string>,
  isRootUser: string,
  title: string,
  userGrpName: Array<string>,
  mstpartnerId: string,
  phnNo: string,
}

interface ICustomerCodeList{
  liner: string;
  customerCode: string;
  matchCode: string;
  id:string;
}

interface IAddCustomerCodeList{
  id: string,
  liner:string;
  customerCode: string;
  matchCode: string;
  name: string;
  address: string;
  taxId: string;
  country: string;
  selected: boolean;
}

interface IMatchCodeList{
  matchCode: string;
  customerCode: string;
  email: string;
  name: string;
}

interface IMappingDashSlice {
  showColumnSettings:boolean;
  mappingTopSearch: string;
  userListData: Array<IMappingUserList>,
  groupList: Array<any>,
  companyDetails: ICompanyDetails;
  userEditingState: IEditUser;
  customerCodeDataList: Array<ICustomerCodeList>;
  codeSearchListData: Array<IAddCustomerCodeList>|null;
  addCustomerCodeFilter:{
    liner:string;
    code:string;
  },
  matchCodeList: Array<IMatchCodeList>|null
}

const initialState: IMappingDashSlice = {
  showColumnSettings:false,
  mappingTopSearch: '',
  userListData:[],
  groupList:[],
  companyDetails:{
    companyName:'',
    phoneNo:'',
    address:'',
    taxId:'',
    country:''
  },
  userEditingState:{
    name: '',
    email: '',
    password: '',
    group: [],
    country: '',
    status: true,
    id: '',
    grpType: [],
    isRootUser: '',
    title: '',
    userGrpName: [],
    phnNo: ''
  },
  customerCodeDataList:[],
  codeSearchListData:null,
  addCustomerCodeFilter:{
    liner:'',
    code:''
  },
  matchCodeList: []
};

export const mappingDashboardSlice = createSlice({
  name: 'mappingDashboard',
  initialState,
  reducers: {
    toggleColumnSettingsPayDash:(state,action)=>{
        state.showColumnSettings = action.payload
    },
    setMappingTopSearchFilter:(state, action)=>{
      state.mappingTopSearch = action.payload
    },
    setCompanyDetails:(state:any,action)=>{
      Object.entries(action.payload).forEach(([key,val])=>{
        if(Object.hasOwn(state.companyDetails,key))state.companyDetails[key]=val
      })
    },
    setUserEditDetails:(state:any,action)=>{
      Object.entries(action.payload).forEach(([key,val])=>{
        if(Object.hasOwn(state.userEditingState,key))state.userEditingState[key]=val
      })
    },
    populateEditUserData:(state, action)=>{
      const row = state.userListData.find((i)=>action.payload == i.id)
      if(!row){
        state.userEditingState = {  
          name: '',
          email: '',
          password: '',
          group: [],
          country: state.companyDetails.country,
          status: true,
          id: null,
          grpType: [],
          isRootUser: '',
          title: '',
          userGrpName: [],
          phnNo: null
      }
      return
    }
      state.userEditingState = {...row, password:''}
    },

    selectCustomerCode: (state, action)=>{
      const row = state.codeSearchListData?.find((i)=>i.id == action.payload.id)
      if(!row) return
      row.selected = action.payload.val
    },

    setAddCodeFilters: (state:any, action)=>{
      Object.entries(action.payload).forEach(([key,val])=>{
        if(Object.hasOwn(state.addCustomerCodeFilter, key)) state.addCustomerCodeFilter[key] = val
      })
    },
    
    clearMatchCodeList: (state)=>{
      state.matchCodeList = null
    },

    clearCodeSearchList: (state)=>{
      state.codeSearchListData = null
    },
  },
  extraReducers:(builder)=>{
    builder.addCase(getCompanyDetails.pending, (state, action)=>{
      state.companyDetails = {
        companyName:'',
        phoneNo:'',
        address:'',
        taxId:'',
        country:''
      }
    })
    builder.addCase(getCompanyDetails.fulfilled,(state,action:any)=>{
      state.companyDetails = action.payload
    })

    builder.addCase(getUserDataList.pending,(state)=>{
      state.userListData = []
    })
    builder.addCase(getUserDataList.fulfilled,(state, action)=>{
      state.userListData = action.payload
    })

    builder.addCase(getCustomerCodeDataList.pending,(state)=>{
      state.customerCodeDataList = []
    })
    builder.addCase(getCustomerCodeDataList.fulfilled,(state,action)=>{
      state.customerCodeDataList = action.payload
    })

    builder.addCase(getCodeSearchListData.pending, (state,action)=>{
      state.codeSearchListData = []
    })
    builder.addCase(getCodeSearchListData.fulfilled, (state,action)=>{
      state.codeSearchListData = action.payload
    })

    builder.addCase(getMatchCodeListData.pending, (state)=>{
      state.matchCodeList = []
    })
    builder.addCase(getMatchCodeListData.fulfilled, (state, action)=>{
      state.matchCodeList = action.payload
    })

    builder.addCase(getGroupTypes.pending, (state)=>{
      state.groupList = []
    })
    builder.addCase(getGroupTypes.fulfilled, (state, action)=>{
      state.groupList = action.payload
    })
  }
});

export const { 
  toggleColumnSettingsPayDash,
  setMappingTopSearchFilter,
  setCompanyDetails,
  setUserEditDetails,
  populateEditUserData,
  selectCustomerCode,
  setAddCodeFilters,
  clearMatchCodeList,
   clearCodeSearchList
} = mappingDashboardSlice.actions;

export default mappingDashboardSlice.reducer;
