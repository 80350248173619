/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Button, Card, Dropdown, Form, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCaretDown,
  faCaretUp,
  faFilter,
  faSort,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import SimpleBar from 'simplebar-react';

import SearchFilter from '../../../app/common/components/DataTable/SearchFilter';
import SingleSelect from '../../../app/common/components/DataTable/SingleSelect';
import MultiSelect from '../../../app/common/components/DataTable/MultiSelect';

import React, { useEffect, useState, useRef } from 'react';
import DateRangeFilter from '../../../app/common/components/DataTable/DateRangeFilter';
import NumberRange from '../../../app/common/components/DataTable/NumberRange';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  toggleFilterModalDT,
  updateDataTableFilterState,
  updateFilterModalStateDT,
  updateSelectedUniqueKeyFilter,
  updateSortModalDT,
  updateSortStateDataTable,
} from '../../../app/common/components/DataTable/slices/DataTable';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateInitialLoadSelectedConfig } from '../../../app/common/slices/filterView';
import OdexSpinner from '../../../app/common/components/OdexSpinner';
import { updateParams } from '../../../app/common/components/DataTable/slices/DataTable';

const FilterSwitch = ({
  type,
  objectKey,
  value,
  setValue,
  options,
  numberFilter,
  uniqueFilterKey,
  closeFilter,
}) => {
  const filterState = useSelector((state) => state.dataTable.filterState);
  const dispatch = useDispatch();
  switch (type) {
    case 'search':
      return (
        <SearchFilter
          objectKey={objectKey}
          value={value}
          setValue={setValue}
          uniqueFilterKey={uniqueFilterKey}
          closeFilter={closeFilter}
        />
      );
    case 'single':
      return (
        <SingleSelect
          objectKey={objectKey}
          value={value}
          setValue={setValue}
          options={options}
          uniqueFilterKey={uniqueFilterKey}
        />
      );
    case 'multiple':
      return (
        <MultiSelect
          objectKey={objectKey}
          value={value}
          setValue={setValue}
          options={options}
          uniqueFilterKey={uniqueFilterKey}
        />
      );
    case 'date-range':
      return (
        <DateRangeFilter
          objectKey={objectKey}
          value={value}
          setValue={setValue}
          uniqueFilterKey={uniqueFilterKey}
          closeFilter={closeFilter}
        />
      );

    case 'number-range':
      return (
        <div className='d-flex flex-row justify-content-right align-items-center mt-0'>
          <>
            <div className='d-flex flex-row justify-content-right align-items-center mt-0 me-2'>
              <NumberRange
                objectKey={objectKey}
                ky='from'
                value={value}
                setValue={setValue}
                numberFilter={numberFilter}
              />
            </div>
            <div className='d-flex flex-row justify-content-right align-items-center mt-0 me-2'>
              <NumberRange
                objectKey={objectKey}
                value={value}
                ky='to'
                setValue={setValue}
                numberFilter={numberFilter}
              />
            </div>

            <Button
              title='Submit'
              variant='primary'
              size='sm'
              className='btn-falcon-primary'
              // disabled={!isDayButton}
              // onClick={() => _updateRelativePickState()}
              onClick={() => {
                dispatch(
                  updateDataTableFilterState({
                    ...filterState,
                    [uniqueFilterKey]: {
                      ...filterState?.[uniqueFilterKey],
                      [objectKey]: value?.[objectKey] || {
                        from: null,
                        to: null,
                      },
                    },
                  })
                );
                closeFilter();
              }}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </>
        </div>
      );

    default:
      return;
  }
};

const AdvanceTable = ({
  getTableProps,
  getTableBodyProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  bodyClassName,
  tableProps,
  customSort = false,
  staticHiddenCols,
  localFilter,
  uniqueFilterKey,
  tableMinHeight = 200,
  addTbodyBreak = false,
  autoHeight = false,
  dataLoading,
  maxHeight,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const filterRef = useRef(
    Array(
      headers.filter((c) => !staticHiddenCols?.includes?.(c?.id)).length
    ).fill(React.createRef())
  );

  const initialLoadSelectedConfig = useSelector(
    (state) => state.filterViewCommon.initialLoadSelectedConfig
  );

  // Sorting State

  const sortState = useSelector((state) => state.dataTable.sortState);
  const selectedConfigId = useSelector(
    (state) => state.dataTable.selectedConfigId
  );

  const sortModal = useSelector((state) => state.dataTable.sortModal);

  const selectedUniqueKeyFilter = useSelector(
    (state) => state.dataTable.selectedUniqueKeyFilter
  );

  const selectedSortKey = useSelector(
    (state) => state.dataTable.selectedSortKey
  );

  // Filter State

  const filterModalState = useSelector(
    (state) => state.dataTable.filterModalState
  );

  const showFilterModal = useSelector(
    (state) => state.dataTable.showFilterModal
  );

  const filterStateIds = headers
    .filter((v) => v?.columnFilterType)
    .map((i) => ({ id: i.id, type: i.columnFilterType }));

  const filterInitialState = {};

  for (let i of filterStateIds) {
    filterInitialState[i.id] =
      i.type === 'multiple'
        ? []
        : i.type === 'date-range'
        ? { from: null, to: null }
        : '';
  }

  const filterKey = queryString
    ?.parse(window.location.search)
    ?.view?.toString();
  const initialFilterState = filterKey
    ? window?.localStorage?.getItem?.(filterKey)
    : null;

  const filtrState = useSelector((state) => state.dataTable.filterState);

  const [filterState, setFilterState] = useState(
    initialFilterState
      ? JSON?.parse?.(initialFilterState)?.filterState
      : filterInitialState
  );

  const onSetFilterState = (key, value) => {
    setFilterState({
      ...filterState,
      [key]: value,
    });
  };

  const getFilterStore = () => {
    const filterStateIds = headers
      .filter((v) => v?.columnFilterType)
      .map((i) => ({ id: i.id, type: i.columnFilterType }));

    const filterInitialState = {};

    for (let i of filterStateIds) {
      filterInitialState[i.id] =
        i.type === 'multiple'
          ? []
          : i.type === 'date-range'
          ? { from: null, to: null }
          : i.type === 'number-range'
          ? { from: null, to: null }
          : '';
    }

    setFilterState(filterInitialState);

    dispatch(
      updateDataTableFilterState({
        ...filtrState,
        [uniqueFilterKey]: filterInitialState,
      })
    );
  };

  const simpleBarRef = useRef(null);

  useEffect(() => {
    simpleBarRef.current.recalculate();
    simpleBarRef.current.scrollRight = 0;

    if (initialLoadSelectedConfig) {
      dispatch(updateInitialLoadSelectedConfig(false));

      getFilterStore();
    }
  }, []);

  const getFilterTextClass = (column) => {
    if (
      (column?.columnFilterType === 'date-range' ||
        column?.columnFilterType === 'number-range') &&
      (filtrState?.[uniqueFilterKey]?.[column.id]?.from ||
        filtrState?.[uniqueFilterKey]?.[column.id]?.to)
    ) {
      return 'text-primary';
    } else if (
      column?.columnFilterType === 'multiple' &&
      filtrState?.[uniqueFilterKey]?.[column.id]?.length > 0
    ) {
      return 'text-primary';
    } else if (
      (column?.columnFilterType === 'search' ||
        column?.columnFilterType === 'single') &&
      filtrState?.[uniqueFilterKey]?.[column.id]
    ) {
      return 'text-primary';
    }

    return 'text-400';
  };

  const { t } = useTranslation();

  const onSortStateSelect = (selectedSortKey, sortType) => () => {
    const queryParams = queryString.parse(location.search);

    dispatch(
      updateSortStateDataTable({
        key: selectedSortKey,
        value: sortType,
      })
    );
    dispatch(
      updateParams({
        key: selectedSortKey,
        value: sortType,
      })
    );

    if (sortType) {
      const sortString = {
        sort: `${selectedSortKey},${sortType}`,
        seek: undefined,
        direction: undefined,
      };

      navigate(
        `${location.pathname}?${queryString.stringify({
          ...queryParams,
          ...sortString,
        })}`
      );
    } else {
      const sortString = {
        sort: undefined,
        seek: undefined,
        direction: undefined,
      };

      navigate(
        `${location.pathname}?${queryString.stringify({
          ...queryParams,
          ...sortString,
        })}`
      );
    }

    dispatch(updateSortModalDT(false));
  };

  const getRowValues = (page) => {
    try {
      if (localFilter && filtrState?.[uniqueFilterKey]) {
        let finalPage = page;

        for (let key of Object.keys(filtrState?.[uniqueFilterKey])) {
          if (typeof filtrState?.[uniqueFilterKey]?.[key] === 'string')
            finalPage = finalPage?.filter?.((i) => {
              return filtrState?.[uniqueFilterKey]?.[key]
                ? i?.original?.[key]
                    ?.toLowerCase()
                    .includes?.(
                      filtrState?.[uniqueFilterKey]?.[key].toLowerCase()
                    )
                : true;
            });
          else if (
            typeof filtrState?.[uniqueFilterKey]?.[key] === 'object' &&
            filtrState?.[uniqueFilterKey]?.[key]?.length > 0
          ) {
            finalPage = finalPage.filter((i) =>
              filtrState?.[uniqueFilterKey]?.[key]?.includes?.(
                i?.original?.[key]
              )
            );
          } else if (
            typeof filtrState?.[uniqueFilterKey]?.[key] === 'object' &&
            filtrState?.[uniqueFilterKey]?.[key]?.from &&
            filtrState?.[uniqueFilterKey]?.[key]?.to
          ) {
            finalPage = finalPage?.filter?.(
              (i) =>
                new Date(
                  filtrState?.[uniqueFilterKey]?.[key]?.from
                )?.getTime?.() <= new Date(i?.original?.[key])?.getTime() &&
                new Date(
                  filtrState?.[uniqueFilterKey]?.[key]?.to
                )?.getTime?.() >= new Date(i?.original?.[key])?.getTime()
            );
          }
        }

        return finalPage;
      }
      return page;
    } catch (error) {
      console.error('Get Rows Error: ', error);

      return page;
    }
  };

  return (
    <SimpleBarReact ref={simpleBarRef} className='flex-1' autoHide={false}>
      {page?.length <= 2 ? (
        <Modal
          show={showFilterModal}
          onHide={() => dispatch(toggleFilterModalDT(false))}
          backdrop={false}
          centered>
          <Modal.Body>
            <div className='d-flex flex-row justify-content-end align-items-center mb-3'>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => dispatch(toggleFilterModalDT(false))}
                className='cursor-pointer'
                size='sm'
              />
            </div>
            <FilterSwitch
              type={filterModalState.filterType}
              objectKey={filterModalState.filterKey}
              value={filterState}
              setValue={onSetFilterState}
              options={filterModalState?.options || []}
              numberFilter={filterModalState?.numberFilter || {}}
              uniqueFilterKey={selectedUniqueKeyFilter}
            />
          </Modal.Body>
        </Modal>
      ) : (
        ''
      )}
      <Modal
        show={sortModal}
        size='sm'
        onHide={() => dispatch(updateSortModalDT(false))}>
        <Modal.Body>
          <h1 className='fs-0 text-center mb-2'>Sort Column</h1>
          <div className='p-2 d-flex flex-column justify-content-center align-items-start'>
            <Form.Check
              id='dataTableSort.none'
              name='sort_none'
              type='radio'
              title='None'
              label='None'
              className='mb-1 fw-bold cursor-pointer'
              checked={
                sortState?.key !== selectedSortKey ||
                (sortState?.key === selectedSortKey && !sortState?.value)
              }
              onClick={onSortStateSelect(undefined)}
            />
            <Form.Check
              id='dataTableSort.asc'
              name='sort_none'
              type='radio'
              title='Asc'
              label='Asc'
              className='mb-1 fw-bold cursor-pointer'
              checked={
                sortState?.key === selectedSortKey && sortState?.value === 'asc'
              }
              onClick={onSortStateSelect('asc')}
            />
            <Form.Check
              id='dataTableSort.desc'
              name='sort_none'
              type='radio'
              title='Desc'
              label='Desc'
              className='mb-1 fw-bold cursor-pointer'
              checked={
                sortState?.key === selectedSortKey &&
                sortState?.value === 'desc'
              }
              onClick={onSortStateSelect('desc')}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Table
        {...getTableProps(tableProps)}
        style={{ minHeight: tableMinHeight }}>
        <thead className={`${headerClassName} odex-thead`}>
          <tr>
            {headers
              .filter((c) => !staticHiddenCols?.includes?.(c?.id))
              .map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps()}
                  className={column?.headerClass ? column.headerClass : ''}>
                  <div className='d-flex justify-content-between align-items-center w-100 resizer-root'>
                    <div className='d-flex justify-content-start '>
                      <span>
                        {typeof column.render('Header') === 'string'
                          ? t(column.render('Header'))
                          : column.render('Header')}
                        {customSort &&
                        column.id !== 'selection' &&
                        !column?.disableSort ? (
                          <FontAwesomeIcon
                            data-toggle='tooltip'
                            title={
                              sortState?.key === column.id
                                ? sortState?.value === 'asc'
                                  ? 'Ascending Order'
                                  : sortState?.value === 'desc'
                                  ? 'Descending Order'
                                  : 'Sort'
                                : 'Sort'
                            }
                            icon={
                              sortState?.key === column.id
                                ? sortState?.value === 'asc'
                                  ? faCaretUp
                                  : sortState?.value === 'desc'
                                  ? faCaretDown
                                  : faSort
                                : faSort
                            }
                            size={
                              sortState?.key === column.id && sortState?.value
                                ? 'lg'
                                : 'sm'
                            }
                            className={`ms-2 cursor-pointer ${
                              sortState?.key === column.id && sortState?.value
                                ? 'text-primary'
                                : 'text-400'
                            }`}
                            onClick={onSortStateSelect(
                              column.id,
                              sortState?.key === column.id
                                ? sortState?.value === 'asc'
                                  ? 'desc'
                                  : sortState?.value === 'desc'
                                  ? undefined
                                  : 'asc'
                                : 'asc'
                            )}
                          />
                        ) : !customSort &&
                          column.canSort &&
                          !(column.id === 'customSelect') &&
                          !column?.disableSort ? (
                          <span
                            {...column.getSortByToggleProps(
                              column.headerProps
                            )}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faCaretDown}
                                  className='ms-2 text-primary'
                                  size='lg'
                                />
                              ) : (
                                // <span className='sort desc' />
                                <FontAwesomeIcon
                                  icon={faCaretUp}
                                  className='ms-2 text-primary'
                                  size='lg'
                                />
                                // <span className='sort asc' />
                              )
                            ) : (
                              <FontAwesomeIcon
                                icon={faSort}
                                className='ms-2 text-400'
                              />
                              // <span className='sort' />
                            )}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>

                      {column.id !== 'selection' &&
                      column?.columnFilterType &&
                      column?.id ? (
                        page?.length < 3 && false ? (
                          <FontAwesomeIcon
                            icon={faFilter}
                            size='xs'
                            className={`${getFilterTextClass(
                              column
                            )} ms-1 cursor-pointer mt-1`}
                            onClick={() => {
                              dispatch(
                                updateFilterModalStateDT({
                                  filterKey: column.id,
                                  filterType: column.columnFilterType,
                                  options: column?.columnFilterOptions,
                                  numberFilter: column?.columnNumberFilter,
                                })
                              );
                              dispatch(
                                updateSelectedUniqueKeyFilter(uniqueFilterKey)
                              );
                              dispatch(toggleFilterModalDT(true));
                            }}
                          />
                        ) : (
                          <Dropdown
                            className='ms-1 d-inline'
                            key={column.id}
                            ref={filterRef.current[index]}>
                            <Dropdown.Toggle
                              as='span'
                              className='no-caret-dropdown-toggle cursor-pointer'>
                              <FontAwesomeIcon
                                icon={faFilter}
                                size='xs'
                                className={`${getFilterTextClass(column)} ms-1`}
                                onClick={() => {
                                  const newState = {
                                    ...location.state,
                                    from: null,
                                  };
                                  const newLocation = {
                                    ...location,
                                    state: newState,
                                  };
                                  navigate(newLocation);
                                }}
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='dropdown-menu-card  dropdown-menu-end'>
                              <Card
                                style={{
                                  width:
                                    column.columnFilterType === 'date-range'
                                      ? 480
                                      : 360,
                                  maxWidth: 600,
                                }}>
                                <Card.Body>
                                  <FilterSwitch
                                    type={column.columnFilterType}
                                    objectKey={column.id}
                                    value={filterState}
                                    setValue={onSetFilterState}
                                    options={column?.columnFilterOptions || []}
                                    numberFilter={
                                      column?.columnNumberFilter || {}
                                    }
                                    uniqueFilterKey={uniqueFilterKey}
                                    closeFilter={() => {
                                      filterRef.current[index]?.current.click();
                                    }}
                                  />
                                </Card.Body>
                              </Card>
                            </Dropdown.Menu>
                          </Dropdown>
                        )
                      ) : (
                        ''
                      )}
                    </div>

                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        {addTbodyBreak && navigator.userAgent.indexOf('Firefox') != -1 ? (
          <br />
        ) : (
          ''
        )}

        <tbody
          {...getTableBodyProps()}
          className={`${bodyClassName} ${
            autoHeight
              ? 'odex-data-table-root-auto'
              : getRowValues(page)?.length > 0
              ? 'odex-data-table-root'
              : ''
          }`}
          style={maxHeight == '' ? {} : { maxHeight: maxHeight }}>
          {dataLoading ? (
            <OdexSpinner />
          ) : getRowValues(page)?.length === 0 ? (
            <div className='d-flex justify-content-left align-items-center w-100 h-100'>
              <span className='fs-0'>{t('resource_3:noDataFound')}.</span>
            </div>
          ) : (
            getRowValues(page).map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} className={rowClassName} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
};

export default AdvanceTable;
