import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface CounterState {
  loginResponse:any;
}

const initialState: CounterState = {
  loginResponse:{}
};

export const loginSlice = createSlice({
  name: 'loginDetails',
  initialState,
  reducers: {

    checkLoginAuth: (state, action: PayloadAction<any>) => {
      state.loginResponse = action.payload;
    },
  },
});

export const { checkLoginAuth } = loginSlice.actions;

export default loginSlice.reducer;
