import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { boolean } from 'yup';

interface IReceiptDashSlice {
  showColumnSettings:boolean;
}

const initialState: IReceiptDashSlice = {
  showColumnSettings:false
};

export const getInGetOutDashboardSlice = createSlice({
  name: 'themeSettings',
  initialState,
  reducers: {
    toggleColumnSettingsPayDash:(state,action)=>{
        state.showColumnSettings = action.payload
    }
  },
});

export const { toggleColumnSettingsPayDash} = getInGetOutDashboardSlice.actions;

export default getInGetOutDashboardSlice.reducer;
