const AgentDelegationTNC = () => {
  return (
    <div>
      <p>
        <b>Hapag-Lloyd</b>
      </p>
      <p>
        We expressly authorize our appointed agent to request delivery from
        Carrier or Carrier's agent of goods consigned to us under selected sea
        waybills or bills of lading, and to release our invoices, and release
        our goods to the appointed agent upon identification against a sea
        waybill, or surrender of one duly endorsed original bills of lading, and
        to take all measures necessary for the delivery of the consignment on
        the authorizing party's behalf (including but not limited customs
        handling). We hereby agree that Carrier is entitled to collect all
        freights and charges with regard to the shipment(s) from the appointed
        agent, and to indemnify Carrier and/or Carriers agent, their respective
        servants and agents and to hold all of you harmless in respect of any
        liability, loss, damage or expenses of whatsoever nature which any of
        them may sustain by reason of complying with our request. This indemnity
        shall be governed by and construed in accordance with the laws of
        Germany, without giving effect to principles of conflict of law. The
        exclusive place of jurisdiction shall be Hamburg.
      </p>

      <p>
        In the event the above terms are not acceptable and you wish to reject
        the same, kindly disagree to close the window.
      </p>
    </div>
  );
};

export default AgentDelegationTNC;
