import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  addInvoiceChange,
  approveRejectInvoiceChange,
} from "../actions/Instruction";
import { getInvoiceChange, getInvoiceChangeTypes } from "../../../dashboard/actions/instruction";
import { getCurrencyDDList } from "../../../blPayment/actions/payments";

export interface InvoiceDetails {
  userRemarks: string;
  blId: number | string | null;
  email: string | null;
  taxId: string;
  contactNo: string;
  address: string;
  freightCharges: boolean;
  invoiceCharges: boolean;
  masterPartnerId: string;
  standingInstructionId: string;
  status: string;
  requestedpartnernm: string;
  //loiUpload: string;
  remarks: string;
  timePendingCharges: boolean;
  poReference: string;
  returnType: string;
  releaseType: string;
  country: string;
  matchCodeList: any[];
  matchCode: string;
  customerCode: string;
}
interface IInvoiceList {
  list: InvoiceDetails[];
  /*   next: string | null;
  previous: string | null; */
}

export interface IRelease {
  data: IInvoiceList;
  invoiceData: InvoiceDetails;
  dataLoading: boolean;
  invoiceCreateRes: any;
  isCompleteLoading: boolean;
  isRejectLoading: boolean;
  returnTypeList: any;
  releaseTypeList: any;
  invoiceList: Array<any>;
  currencyList: Array <any>;
}

const initialState: IRelease = {
  data: {
    list: [],
    /*   next: null,
    previous: null, */
  },
  invoiceList: [],
  currencyList:[],
  dataLoading: true,
  invoiceCreateRes: {},
  invoiceData: {
    blId: null,
    email: null,
    taxId: "",
    contactNo: "",
    address: "",
    freightCharges: false,
    invoiceCharges: true,
    masterPartnerId: "",
    standingInstructionId: "",
    status: "",
    requestedpartnernm: "",
    //loiUpload: '',
    remarks: "",
    timePendingCharges: false,
    poReference: "",
    returnType: "",
    releaseType: "",
    country: "",
    matchCodeList: [],
    matchCode: "",
    customerCode: "",
    userRemarks:""
  },
  isCompleteLoading: false,
  isRejectLoading: false,
  returnTypeList: [],
  releaseTypeList: [],
};

export const InvoiceChangeSlice = createSlice({
  name: "InvoiceChange",
  initialState,
  reducers: {
    updateReturnTypeList: (state, action: PayloadAction<any>) => {
      state.returnTypeList = action.payload;
    },
    updateReleaseTypeList: (state, action: PayloadAction<any>) => {
      state.releaseTypeList = action.payload;
    },
    updateInvoice: (state, action: PayloadAction<any>)=>{
        const row = state.invoiceList.find((i:any)=>i.value == action.payload.code)
        if(row) row[action.payload.key] = action.payload.value
    },
    updateInvoiceCurrency: (state, action: PayloadAction<any>)=>{
      state.invoiceList.forEach((x)=>{
        x.currency = action.payload
      })

    },
    resetInvoiceTable: (state)=>{
      const defaultTrue = [102]
      state.invoiceList = state.invoiceList?.map((i:any)=>({
        ...i,
        currency:state.currencyList?.[0]?.currencyCode??'',
        allowAccess:defaultTrue.includes(i.value)

      }))??[];

    }
  },
  extraReducers: (builder) => {
    builder.addCase(addInvoiceChange.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addInvoiceChange.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getInvoiceChange.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getInvoiceChange.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.invoiceData = action.payload;
      state.invoiceList.forEach((row:any)=>{
        const data = action.payload.invChangegAccessList.find((item:any)=>item.type == row.value)
        row.currency = data?.currency
        row.allowAccess = data?.allowAccess == 'Y'
      })
    });

    builder.addCase(approveRejectInvoiceChange.pending, (state, action) => {
      state.isCompleteLoading = true;
    });
    builder.addCase(approveRejectInvoiceChange.fulfilled, (state, action) => {
      state.isCompleteLoading = false;
    });
    builder.addCase(
      getCurrencyDDList.fulfilled,
      (state, action: any) => {
        state.currencyList = action?.payload
      }
    );
    builder.addCase(getInvoiceChangeTypes.pending, (state, action)=>{
      state.invoiceList = []
    })
    builder.addCase(getInvoiceChangeTypes.fulfilled, (state, action)=>{

      const defaultTrue = [102]
      state.invoiceList = action.payload?.map((i:any)=>({
        ...i,
        currency:state.currencyList?.[0]?.currencyCode??'',
        allowAccess:defaultTrue.includes(i.value)

      }))??[];
    })
  },
});

export const { updateReturnTypeList, updateReleaseTypeList, updateInvoice, updateInvoiceCurrency, resetInvoiceTable } =
  InvoiceChangeSlice.actions;

export default InvoiceChangeSlice.reducer;
