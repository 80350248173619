import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  addReturnInstruction,
  getReturnView,
} from "../../../dashboard/actions/instruction";

interface IReturnFormView {
  returnInstructionId: number | null;
  blId: number | null;
  senderEmail: string | null;
  country: string | null;
  intendedDate: string | null;
  remarks: string | null;
  rejectionRemarks: string | null;
  status: string | null;
  returnLocation: string | null;
  deliveryName: String | null;
  list: any[];
}

interface IReturnContainerDetails {
  //vesselType: any;
  containerNo: string | null;
  IntendedDate: string | null;
  returnLocation: string | null;
  deliveryName: String | null;
}

interface IReturnListView {
  list: any[];
  next: string | null;
  previous: string | null;
}

export interface IReturnDetails {
  data: IReturnListView;
  isReturnDetailsLoading: boolean;
  dataLoading: boolean;
  returnForm: IReturnFormView;
  // containerDetails: IDemurrageContainerDetails;
}

const initialState: IReturnDetails = {
  data: {
    list: [],
    next: null,
    previous: null,
  },
  dataLoading: true,
  isReturnDetailsLoading: false,
  returnForm: {
    returnInstructionId: null,
    blId: null,
    senderEmail: "",
    intendedDate: "",
    rejectionRemarks: "",
    status: "",
    remarks: "",
    country: "US",
    returnLocation: "",
    deliveryName: "",
    list: [],
  },
};

export const ReturnSlice = createSlice({
  name: "returnView",
  initialState,
  reducers: {
    updateReturnList: (state, action: PayloadAction<IReturnListView>) => {
      state.data = action.payload;
    },
    updateReturnListView: (state, action: PayloadAction<any>) => {
      // state.taxId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addReturnInstruction.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(addReturnInstruction.fulfilled, (state, action) => {
      state.dataLoading = false;
    });
    builder.addCase(getReturnView.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getReturnView.fulfilled, (state, action) => {
      state.dataLoading = false;

      state.returnForm = {
        returnInstructionId: action?.payload?.data?.returnInstructionId,
        blId: action?.payload?.data?.blId,
        senderEmail: "",
        remarks: action?.payload?.data?.remarks || "",
        rejectionRemarks: action?.payload?.data?.rejectionRemarks || "",
        country: "",
        status: action?.payload?.data?.status || "",
        intendedDate: new Date().toISOString(),
        returnLocation: "",
        deliveryName: "",
        list: action?.payload?.data?.list || [],
      };
      state.data.list = action?.payload?.data?.list || "";
      //state.demurrageForm = {
      // number: action?.payload?.number || '',
      //senderEmail: action?.payload.data?.customerEmailId || '',
      //IntendedDate: action?.payload.data?.customerEmailId,
      // state.data= action.payload;
      //};
    });
    builder.addCase(getReturnView.rejected, (state, action) => {});
  },
});
export const { updateReturnList } = ReturnSlice.actions;

export default ReturnSlice.reducer;
