import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup, Table } from 'react-bootstrap';
import {
  rawEarlierNotifications,
  rawNewNotifications,
} from '../../../data/notification/notification';
import { isIterableArray } from '../../../helpers/utils';
import useFakeFetch from '../../../hooks/useFakeFetch';
import FalconCardHeader from '../../common/FalconCardHeader';
import Notification from '../../../components/notification/Notification';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

const NotificationDropdown = () => {
  const data = useSelector((state) => state.dashboard.data);

  let pmtData = [];
if(data?.list){
  for (let i of data?.list) {
    if (i?.history) {
      for (let j of i.history) {
        if (j.document.includes(' Invoice') && j?.amount) {
          pmtData.push({
            ...i,
            event: j.event,
            document: j.document,
            invoiceNo: j.documentNo,
            amount: j.amount.split(' ')[1],
            invoiceType: j.document,
            time: j.time,
            payment: j?.paid === 'Yes' ? 'Yes' : 'No',
            paymentRefNo:
              j?.paid === 'Yes'
                ? `PMT${j.documentNo.toUpperCase().slice(0, 9)}`
                : '',
          });
        }
      }
    }
  }
}
  // State
  const { data: newNotifications, setData: setNewNotifications } =
    useFakeFetch(rawNewNotifications);
  const { data: earlierNotifications, setData: setEarlierNotifications } =
    useFakeFetch(rawEarlierNotifications);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAsRead = (e) => {
    e.preventDefault();

    const updatedNewNotifications = newNotifications.map((notification) =>
      Object.prototype.hasOwnProperty.call(notification, 'unread')
        ? { ...notification, unread: false }
        : notification
    );
    const updatedEarlierNotifications = earlierNotifications.map(
      (notification) =>
        Object.prototype.hasOwnProperty.call(notification, 'unread')
          ? { ...notification, unread: false }
          : notification
    );

    setIsAllRead(true);
    setNewNotifications(updatedNewNotifications);
    setEarlierNotifications(updatedEarlierNotifications);
  };

  return (
    <Dropdown navbar={true} as='li' show={isOpen} onToggle={handleToggle}>
   {/*    <Dropdown.Toggle
        bsPrefix='toggle'
        as={Link}
        to='#!'
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead,
        })}
      >
        <FontAwesomeIcon icon='bell' transform='shrink-6' className='fs-4' />
      </Dropdown.Toggle> */}

      <Dropdown.Menu className='dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg'>
        <Card
          className='dropdown-menu-end shadow-none'
          style={{ width: '60rem' }}
        >
          <Table responsive hover className='fs--1'>
            <thead>
              <tr className='bg-100'>
                <th>Carrier Name</th>
                <th>BL No.</th>
                <th>Document</th>
                <th>Event</th>
                <th>Document No</th>
                <th>Time</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {pmtData.slice(0, 5).map((value, index) => (
                <tr key={index}>
                  <td>Hapag Lloyd</td>
                  <td>{value.blNumber}</td>
                  <td>{value.document}</td>
                  <td>{value.event}</td>
                  <td>{value.invoiceNo}</td>
                  <td>{value.time}</td>
                  <td>
                    {value.currency} {value.amount}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
