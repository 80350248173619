import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { updateDocumentViewUrl } from '../slices/documentViewSlice';
import { useDispatch } from 'react-redux';
import { updateBlDetailsRowColumns } from '../../features/blDetails/slices/blDetailsTable';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface IProps {
  onClose?: any;
}

const DocumentView = ({ onClose }: IProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matchPth = matchPath(
    {
      path: '/payment',
      caseSensitive: true,
      end: true,
    },
    location.pathname
  );
  const rowColumns = useSelector(
    (state: RootState) => state.blDetailsTable.rowColumns
  );

  const backNavUrl = useSelector(
    (state: RootState) => state.documentView.backNavUrl
  );

  const _onCloseForm = () => {
    dispatch(
      updateBlDetailsRowColumns({
        ...rowColumns,
        mainTableClass: 'col-lg-2',
        documentMainViewClass: 'd-none',
        documentHistoryClass: 'col-lg-12',
        outletClass: 'd-none',
      })
    );

    setTimeout(() => {
      navigate(`${backNavUrl}${window.location.search || ''}`);
    }, 500);
  };

  const documentViewUrl = useSelector(
    (state: RootState) => state.documentView.documentUrl
  );

  const [selectedDoc, setSelectedDoc] = useState('');

  useEffect(() => {
    const fileName = location.pathname.split('/').pop();

    if (fileName) {
      switch (fileName) {
        case '102':
          dispatch(updateDocumentViewUrl('/pdf/INV12345674.pdf'));
          break;
        case '780':
          dispatch(updateDocumentViewUrl('/pdf/HLCU_2000925756_29112022.pdf'));

          /* break;
        case "OBL-Details":
          return <OblDetails />;
          break;
        */

          break;

        default:
          dispatch(updateDocumentViewUrl(documentViewUrl));
          break;
      }
    } else {
      dispatch(updateDocumentViewUrl(documentViewUrl));
    }
  }, [location.pathname]);

  const _onClose = () => {
    if (onClose) {
      onClose();
    } else {
      setSelectedDoc('');
    }
  };

  return (
    <Card className='h-100 w-100 border-top ' style={{ position: 'relative' }}>
      <Card.Header className='d-flex justify-content-end align-items-center sticky-top bg-white'>
        {!matchPth && (
          <FontAwesomeIcon
            icon={faTimes}
            onClick={_onCloseForm}
            className='cursor-pointer'
            onError={(e) => {
              console.error('IFrame Error: ', e);
            }}
          />
        )}
      </Card.Header>
      <Card.Body className='h-100 p-0'>
        <Document
          file={documentViewUrl}
          onLoadError={(e) => {
            console.error('PDF Load Error: ', e);
          }}
        >
          <Page pageNumber={1} renderTextLayer={false} />
        </Document>
      </Card.Body>
    </Card>
  );
};

export default DocumentView;
