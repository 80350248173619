import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { toggleNominationReq } from '../dashboard/slices/dashboardSlice';
import { useLocation, matchPath, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import NominateAgent from '../dashboard/components/NominateAgent';

const ReqForm = () => {
  const { t } = useTranslation();
  const selectedTab = useSelector(
    (state: RootState) => state.nominationReq.selectedTab
  );

  const { blNum } = useParams();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const dispatch = useDispatch();

  const location = useLocation();

  const matchPth = matchPath(
    {
      path: '/bl/:blNum/nomination-request',
      caseSensitive: true,
      end: true,
    },
    location.pathname
  );

  const [state, setState] = useState(
    !!matchPth || profileDetails?.partnerType === 'liner'
      ? {
          taxId: blNum === 'HLCUMEA23242820' ? '323344322' : '324234445',
          consigneeName:
            blNum === 'HLCUMEA23242820' ? 'ACME Inc' : 'ABC Corporation Co',
          primaryContant:
            blNum === 'HLCUMEA23242820' ? 'ACME Inc' : 'ABC Corporation Co',
          registeredEmail:
            blNum === 'HLCUMEA23242820'
              ? 'import@acmecrop.com'
              : 'import@abccrop.com',
          registeredPhoneNo: '1234567892',
          address: '5230 Pleasanton Ave, Suite 145, Pleasanton, CA 94566',
          remarks: '',
        }
      : {
          taxId: '',
          consigneeName: '',
          primaryContant: '',
          registeredEmail: '',
          registeredPhoneNo: '',
          address: '',
          remarks: '',
        }
  );

  const disableFields =
    profileDetails?.partnerType === 'liner' ||
    (!!matchPth && profileDetails?.partnerType !== 'liner');

  return (
    <div className='p-2 h-100'>
      <NominateAgent />
    </div>
  );
  {
    /*   <Card.Header className='border-bottom text-primary'> */
  }
  {
    /*   {!matchPth  && selectedTab!=''?( 
          <>
            Delegation Request -{' '}
            {selectedTab === 'consignee' ? 'Consignee' : 'Liner'}
          </>
        ) : (
          ''
        )} */
  }
  {
    /*    </Card.Header> */
  }
  {
    /*    <Card.Body> */
  }
  {
    /*    <Form>
          <div className='row'> */
  }

  {
    /*       </Card.Body> */
  }
  {
    /*    <div className='col-lg-6 mb-2'>
              <Form.Group controlId='reqForm.taxId'>
                <Form.Label>Tax ID</Form.Label>
                <Form.Control
                  size='sm'
                  disabled={disableFields}
                  onChange={(e) =>
                    setState({ ...state, taxId: e.target.value })
                  }
                  value={state.taxId}
                />
              </Form.Group>
            </div>

            <div className='col-lg-6 mb-2'>
              <Form.Group controlId='reqForm.consigneeName'>
                <Form.Label>Consignee Name</Form.Label>
                <Form.Control
                  size='sm'
                  disabled={disableFields}
                  value={state.consigneeName}
                  onChange={(e) =>
                    setState({ ...state, consigneeName: e.target.value })
                  }
                />
              </Form.Group>
            </div> */
  }

  {
    /*     <div className='col-lg-6 mb-2'>
              <Form.Group controlId='reqForm.primaryContact'>
                <Form.Label>Primary Contact</Form.Label>
                <Form.Control
                  size='sm'
                  placeholder='Primary Contact Name'
                  disabled={disableFields}
                  value={state.primaryContant}
                  onChange={(e) =>
                    setState({ ...state, primaryContant: e.target.value })
                  }
                />
              </Form.Group>
            </div>

            <div className='col-lg-6 mb-2'>
              <Form.Group controlId='reqForm.registeredEmail'>
                <Form.Label>Registered Email</Form.Label>
                <Form.Control
                  size='sm'
                  disabled={disableFields}
                  value={state.registeredEmail}
                  onChange={(e) =>
                    setState({ ...state, registeredEmail: e.target.value })
                  }
                />
              </Form.Group>
            </div>

            <div className='col-lg-6 mb-2'>
              <Form.Group controlId='reqForm.registeredPhone'>
                <Form.Label>Registered Phone No</Form.Label>
                <Form.Group>
                  <div className='row g-0'>
                    <div className='col-4'>
                      <Form.Select size='sm' disabled={disableFields}>
                        <option value='+1'>+1</option>
                      </Form.Select>
                    </div>

                    <div className='col-8'>
                      <Form.Control
                        size='sm'
                        disabled={disableFields}
                        value={state.registeredPhoneNo}
                        onChange={(e) =>
                          setState({
                            ...state,
                            registeredPhoneNo: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form.Group>
            </div>
            <div className='col-lg-6 mb-2'>
              <Form.Group controlId='reqForm.address'>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as='textarea'
                  disabled={disableFields}
                  value={state.address}
                  onChange={(e) =>
                    setState({ ...state, address: e.target.value })
                  }
                />
              </Form.Group>
            </div> */
  }
  {
    /*   {(!!matchPth &&
              window.localStorage.getItem('@odex/activeuser') !== 'liner') ||
            blNum !== 'HLCUMEA23242820' ? (
              ''
            ) : (
              <div className='col-lg-6 mb-2'>
                <Form.Group controlId='reqForm.remarks'>
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control
                    as='textarea'
                    value={state.remarks}
                    onChange={(e) =>
                      setState({ ...state, remarks: e.target.value })
                    }
                  />
                </Form.Group>
              </div>
            )} */
  }

  {
    /*    {window.localStorage.getItem('@odex/activeuser') === 'liner'
              ? blNum === 'HLCUMEA23242820' && (
                  <div className='col-lg-8 offset-2 mt-3 d-flex justify-content-center align-items-center'>
                    <Button variant='success' size='sm' className='me-2'>
                      <FontAwesomeIcon icon={faSave} className='me-2' />
                      Approve
                    </Button>

                    <Button variant='danger' size='sm'>
                      <FontAwesomeIcon icon={faTimes} className='me-2' />
                      {t('resource_1:reject')}
                    </Button>
                  </div>
                )
              : !matchPth && (
                  <div className='col-lg-8 offset-2 mt-3 d-flex justify-content-center align-items-center'>
                    <Button variant='falcon-primary' size='sm' className='me-2'>
                      <FontAwesomeIcon icon={faSave} className='me-2' />
                      <span>Submit</span>
                    </Button>

                    <Button
                      variant='falcon-danger'
                      size='sm'
                      onClick={() => {
                        dispatch(toggleNominationReq(false));
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} className='me-2' />
                      <span>Cancel</span>
                    </Button>
                  </div>
                )} */
  }
  {
    /*       </div>
        </Form>
      </Card.Body> */
  }
  /*   </div> */
  // );
};

export default ReqForm;
