import { Button, Card, Form, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useState, useRef } from "react";
import DataTable from "../../common/components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { IDataTableColumn } from "../../common/types/dataTable";
import {
  getCustomersDetailsByPartnerId,
  saveOrUpdateCustomersDetails,
  fetchLinerNameByCountry,
} from "./action/ProfileAccount";
import { updateBlDetailsRowColumns } from "./profileAccountSlice";
import { Link, matchPath, useLocation } from "react-router-dom";
import AccountDtls from "./AccountDtls";
import { useParams } from "react-router-dom";

import {
  faTimes,
  faRedo,
  faPenToSquare,
  faFloppyDisk,
  faTrash,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import CustomerMappingDtls from "./CustomerMappingDtls";

interface Profile {
  mstAccountId: any;
  mstPartnerId: any;
  bankName: string;
  accountName: string;
  accountAlias: string;
  accountNo: string;
  accountCurrency: string;
  localBankCode: string;
  branchAddress: string;
  ibanNo: string;
  swiftCode: string;
}
const ProfileAccount = () => {
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  //Close Profile account
  const _onCloseForm = () => {
    navigate("/home");
  };
  const [errMsg, setErrMsg] = useState("");

  //Enable and Disable field and buttons
  const [readOnly, setReadOnly] = useState(false);

  //common method for table
  const partnerData = useSelector((state: RootState) => state.partnerData);
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const [dummy, setDummy] = useState(false);
  const ref = useRef(null);
  const addRow = () => {
    navigate(`/user/profile/details/0`);
  };
  const addCustMapRow = () => {
    navigate(`/user/profile/custMapDetails/0`);
  };

  //partner Name
  const [companyName, setCompanyName] = useState("");

  //Phone Number
  const [phnNo, setPhoneNo] = useState("");
  const [taxId, setTaxId] = useState("")
  const [address, setAddress] = useState("");

  //Data Table
  const [accountList, setAccountList] = useState<any>([]);

  const { accountId } = useParams();
  const accountDetailsColumns: IDataTableColumn[] = [
    {
      accessor: "mstAccountId",
      Header: "mstAccountId",
    },
    {
      accessor: "mstPartnerId",
      Header: "mstPartnerId",
    },
    {
      accessor: "accountAlias",
      Header: "Account Alias",
      width: 200,
      Cell: (rowData: any) => {
        return (
          <Link
            to={`/user/profile/details/${rowData?.row?.original?.mstAccountId}`}
            className={
              accountId === rowData.row.original.mstAccountId
                ? "fw-bold"
                : "text-primary"
            }
          >
            {rowData.cell.row.original.accountAlias}
          </Link>
        );
      },
      maxWidth: 150,
    },
    {
      accessor: "localBankCode",
      Header: "Bank Code",
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "bankName",
      Header: "Bank Name",
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "accountName",
      Header: "Account Name",
      width: 200,
      maxWidth: 500,
    },
    {
      accessor: "accountNo",
      Header: "Account No./ACH ID",
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "accountCurrency",
      Header: "Currency",
      width: 75,
      maxWidth: 150,
    },
    {
      accessor: "branchAddress",
      Header: "Branch Address",
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "ibanNo",
      Header: "IBAN Number",
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "swiftCode",
      Header: "Swift Code",
      width: 200,
      maxWidth: 150,
    },
    {
      accessor: "readonly",
      Header: "",
      width: 80,
      maxWidth: 150,
    },
  ];

  //Customer Mapping
  const [customerMappingList, setCustomerMappingList] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  const { custMapId } = useParams();
  const customerMappingDetailsColumns: IDataTableColumn[] = [
    {
      accessor: "customerMapId",
      Header: "customerMapId",
    },
    {
      accessor: "customerId",
      Header: "customerId",
    },
    {
      accessor: "linerId",
      Header: "Liner",
      width: 250,
      Cell: (rowData: any) => {
        return options?.find(
          (v: any) =>
            v?.value ===
            customerMappingList?.find((u: any) => v?.value === u?.linerId)
              ?.linerId
        )?.text;
      },
      maxWidth: 300,
    },
    {
      accessor: "mapCode",
      Header: "Customer Code",
      width: 250,
      Cell: (rowData: any) => {
        return (
          <Link
            to={`/user/profile/custMapDetails/${rowData.row.original.customerMapId}`}
            className={
              custMapId === rowData.row.original.customerMapId
                ? "fw-bold"
                : "text-primary"
            }
          >
            {rowData.cell.row.original.mapCode}
          </Link>
        );
      },
      maxWidth: 400,
    },
    {
      accessor: "internalCode",
      Header: "Match Code",
      width: 200,
      maxWidth: 150,
    },

    {
      accessor: "readonly",
      Header: "",
      width: 80,
      maxWidth: 150,
    },
  ];

  //slider
  useEffect(() => {
    if (matchPath("/user/profile/details/:accountId", location.pathname)) {
      dispatch(
        updateBlDetailsRowColumns({
          ...partnerData,
          mainTableClass: "col-lg-4",
          outletClass: "col-lg-8",
          colClass: "col-lg-6",
          custMapTableClass: "col-lg-12",
          custMapoutletClass: "d-none",
        })
      );
    } else if (
      matchPath("/user/profile/custMapDetails/:custMapId", location.pathname)
    ) {
      dispatch(
        updateBlDetailsRowColumns({
          ...partnerData,
          mainTableClass: "col-lg-12",
          outletClass: "d-none",
          colClass: "col-lg-6",
          custMapTableClass: "col-lg-4",
          custMapoutletClass: "col-lg-8",
        })
      );
    } else if (matchPath("/user/profile", location.pathname)) {
      dispatch(
        fetchLinerNameByCountry({
          country_code:
            profileDetails?.partnerCountry ||
            partnerData?.data?.country?.substring(0, 2) ||
            null,
        })
      );
      dispatch(
        updateBlDetailsRowColumns({
          ...partnerData,
          mainTableClass: "col-lg-12",
          outletClass: "d-none",
          colClass: "col-lg-2",
          custMapTableClass: "col-lg-12",
          custMapoutletClass: "d-none",
        })
      );
      if (partnerData.toggle) {
        dispatch(getCustomersDetailsByPartnerId({}))
          .unwrap()
          .then((response: any) => {
            setCompanyName(response.companyName);
            setAddress(response.address);
            setTaxId(response.partnerIdentityNo)
            setPhoneNo(response.phnNo);
            if (
              response.mstPartnerId !== undefined ||
              response.mstPartnerId !== null
            ) {
              if (
                partnerData?.data?.partnerAccountList?.list?.length !== 0 &&
                partnerData?.data?.partnerAccountList?.list?.find(
                  (v: any) => v?.mstAccountId === null
                ) !== undefined
              ) {
                setAccountList(
                  response.partnerAccountList.list.map((v: any) => ({
                    mstAccountId: v?.mstAccountId,
                    mstPartnerId: v?.mstPartnerId,
                    bankName: v?.bankName,
                    accountName: v?.accountName,
                    accountAlias: v?.accountAlias,
                    accountNo: v?.accountNo,
                    accountCurrency: v?.accountCurrency,
                    localBankCode: v?.localBankCode,
                    branchAddress: v?.branchAddress,
                    ibanNo: v?.ibanNo,
                    swiftCode: v?.swiftCode,
                    readonly: true,
                  }))
                );
              }

              if (
                partnerData?.data?.customermappingList?.list?.length !== 0 &&
                partnerData?.data?.customermappingList?.list?.find(
                  (v: any) => v?.customerMapId === null
                ) !== undefined
              ) {
                setCustomerMappingList(
                  response.customermappingList.list.map((v: any) => ({
                    customerMapId: v?.customerMapId,
                    customerId: v?.customerId,
                    linerId: v?.linerId,
                    mapCode: v?.mapCode,
                    internalCode: v?.internalCode,
                    readonly: true,
                  }))
                );
              }
            }
          });
      }
    }
  }, [location.pathname, customerMappingList, accountList]);

  useEffect(() => {
    dispatch(
      fetchLinerNameByCountry({
        country_code:
          profileDetails?.partnerCountry ||
          partnerData?.data?.country?.substring(0, 2) ||
          null,
      })
    )
      .unwrap()
      .then((v: any) => {
        setOptions(v || [{ text: "Hapag-Lloyd", value: 1 }]);
      });
    dispatch(getCustomersDetailsByPartnerId({}))
      .unwrap()
      .then((response: any) => {
        setCompanyName(response.companyName);
        setAddress(response.address);
        setTaxId(response.partnerIdentityNo)
        setPhoneNo(response.phnNo);
        if (
          response.mstPartnerId !== undefined ||
          response.mstPartnerId !== null
        ) {
          if (accountList.length == 0) {
            setAccountList(
              response?.partnerAccountList?.list.map((v: any) => ({
                mstAccountId: v?.mstAccountId,
                mstPartnerId: v?.mstPartnerId,
                bankName: v?.bankName,
                accountName: v?.accountName,
                accountAlias: v?.accountAlias,
                accountNo: v?.accountNo,
                accountCurrency: v?.accountCurrency,
                localBankCode: v?.localBankCode,
                branchAddress: v?.branchAddress,
                ibanNo: v?.ibanNo,
                swiftCode: v?.swiftCode,
                readonly: true,
              }))
            );
          }
          if (customerMappingList.length == 0) {
            setCustomerMappingList(
              response.customermappingList.list.map((v: any) => ({
                customerMapId: v?.customerMapId,
                customerId: v?.customerId,
                linerId: v?.linerId,
                mapCode: v?.mapCode,
                internalCode: v?.internalCode,
                readonly: true,
              }))
            );
          }
        }
      });
  }, []);

  return (
    <Card>
      <div className="bg-holder bg-card odex-bldetails-img-r" />
      <Card.Header className="d-flex  align-items-center border-bottom">
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className="cursor-pointer fa-lg mb-1 me-1 justify-content-start"
          onClick={_onCloseForm}
        />
        <h2 className="fs-0 text-primary mb-1">{"Profile"}</h2>
      </Card.Header>
      <Card.Body>
        <div className="container-fluid">
          <div>
            <div className="h2 text-secondary fs-0 pb-3 border-bottom">
              {t("resource_2:companyDetails")}
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <Form.Group controlId="profileAccount.companyName">
                  <Form.Label className="fs--1">{"Company Name"}</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setCompanyName(e.target.value)}
                    size="sm"
                    disabled={true}
                    value={companyName}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3 mb-3">
                <Form.Group controlId="profileAccount.phnNo">
                  <Form.Label className="fs--1">{"Phone Number"}</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setPhoneNo(e.target.value)}
                    size="sm"
                    disabled={true}
                    value={phnNo}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3 mb-3">
                <Form.Group controlId="profileAccount.phnNo">
                  <Form.Label className="fs--1">{t('resource_2:taxId')}</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setTaxId(e.target.value)}
                    size="sm"
                    disabled={true}
                    value={taxId}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <Form.Group controlId="profileAccount.address">
                <Form.Label className="fs--1">{"Address"}</Form.Label>
                <Form.Control
                  type="textarea"
                  as="textarea"
                  onChange={(e) => setAddress(e.target.value)}
                  disabled={true}
                  value={address}
                  rows={2}
                />
              </Form.Group>
            </div>

            {partnerData.data.mstPartnerId === undefined ? (
              ""
            ) : (
              <div className="row g-0 h-100 flex-1 ">
                <div
                  className={`${partnerData.rowColumns.mainTableClass} h-100 zindex5`}
                >
                  <Button
                    className="me-2 userAcAddBtn "
                    variant="falcon-danger"
                    size="sm"
                    onClick={(e) => addRow()}
                  >
                    <FontAwesomeIcon icon={faAdd} />
                  </Button>

                  <div className="row border-bottom">
                    <div
                      className={`${partnerData.rowColumns.colClass} mb-2 h2 text-secondary fs-0 pb-3`}
                    >
                      {"Account Details"}
                    </div>
                    <div className="col-lg-1 mb-1"></div>
                    <div style={{ display: "inline-block", color: "red" }}>
                      {errMsg}
                    </div>
                  </div>
                  <div>
                    {accountList?.length > 0 && (
                      <DataTable
                        data={accountList}
                        columns={accountDetailsColumns}
                        sortable={false}
                        gridName="ACCOUNT"
                        searchable={false}
                        showColumnFilter={false}
                        selection={false}
                        perPage={500}
                        pagination={true}
                        seekPagination={true}
                        showExport={false}
                        uniqueFilterKey="profileAccount"
                        autoHeight={true}
                        staticHiddenCols={[
                          "mstAccountId",
                          "mstPartnerId",
                          "branchAddress",
                          "ibanNo",
                          "swiftCode",
                        ]}
                        tableMinHeight={100}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${partnerData.rowColumns.outletClass}  h-100 zindex10 `}
                >
                  <AccountDtls />
                </div>
              </div>
            )}

            {/*customer mapping*/}
            {partnerData.data.mstPartnerId === undefined ? (
              ""
            ) : (
              <div className="row g-0 h-100 flex-1 ">
                <div
                  className={`${partnerData.rowColumns.custMapTableClass} h-100 zindex5`}
                >
                  <Button
                    className="me-2 userAcAddBtn "
                    variant="falcon-danger"
                    size="sm"
                    hidden={true}
                    onClick={(e) => addCustMapRow()}
                  >
                    <FontAwesomeIcon icon={faAdd} />
                  </Button>

                  <div className="row border-bottom">
                    <div
                      className={`${partnerData.rowColumns.colClass} mb-2 h2 text-secondary fs-0 pb-3`}
                    >
                      {"Customer Map"}
                    </div>
                    <div className="col-lg-1 mb-1"></div>
                    <div style={{ display: "inline-block", color: "red" }}>
                      {errMsg}
                    </div>
                  </div>
                  <div>
                    <DataTable
                      data={customerMappingList}
                      columns={customerMappingDetailsColumns}
                      sortable={false}
                      gridName="PROFILE_ACCOUNT"
                      searchable={false}
                      showColumnFilter={false}
                      selection={false}
                      perPage={500}
                      pagination={true}
                      showExport={false}
                      uniqueFilterKey="customerMapping"
                      autoHeight={true}
                      staticHiddenCols={[
                        "customerMapId",
                        "customerId",
                        "readonly",
                      ]}
                      tableMinHeight={100}
                      seekPagination={true}
                    />
                  </div>
                </div>
                <div
                  className={`${partnerData.rowColumns.custMapoutletClass}  h-100 zindex10 `}
                >
                  <CustomerMappingDtls />
                </div>
              </div>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProfileAccount;
