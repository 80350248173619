import { useSelector } from "react-redux"
export const reportMap = 
{
    808:["PICKUP_LOC_INSTRACT_REPORT","pickupRep"]
}
const useReportGridName = ()=>{
    const selectedInst = useSelector((state)=>state.reportDashboard.selectedInstruct)
    const reportList = useSelector((state)=>state.reportDashboard.reportList)
    if(reportList.map((i)=>i.value).includes(+selectedInst)){
        return {
            reportGridName: reportMap[selectedInst][0],
            reportGridFilterKey: reportMap[selectedInst][1]
        }
    }
    return {
        reportGridName: null,
        reportGridFilterKey: null
    }
}

export default useReportGridName