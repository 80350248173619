import React from 'react';
import PropTypes from 'prop-types';
/* import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Background from '../components/common/Background';
import Flex from '../components/common/Flex';
import Section from '../components/common/Section';
import SignUpBox from './SignUpBox';
import bgShape from '../assets/img/illustrations/bg-shape.png';
import shape1 from '../assets/img/illustrations/shape-1.png';
import halfCircle from '../assets/img/illustrations/half-circle.png'; */
import LogoOne from '../../../assets/images/logo.png';
/* import OdexLoginBg from '../assets/images/odexlogin-bg.jpg'; */
import {
  Container,
  Row,
  Col,
  Carousel,
  Image,
  Card,
  Form,
} from 'react-bootstrap';
/* import LoginForm from '../app/features/login/Login/LoginForm'; */
import {
  faCheck,
  faLock,
  faLockOpen,
  faPlus,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faDocker } from '@fortawesome/free-brands-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import ResetPass from './ResetPass';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Container fluid>
        <Row>
          <Col
            style={{ position: 'relative' }}
            className='p-0 col-4 form-login'>
            {/* <SignUpBox /> */}
            <div className='login-wrap'>
              <Card className='h-100 pt-4 pb-5 p-4'>
                <img src={LogoOne} className='logoWidth pb-5' />
                <Card.Header className='cardHeader textLowerCase'>
                  {t('resource_2:resetPassword')}
                </Card.Header>
                <Card.Body className='p-2 bodyHeightReset'>
                  <ResetPass />
                </Card.Body>
                <fieldset>
                  <legend>Password Policy</legend>
                  <p>{t('resource_message:passwordLength')}</p>
                  <p>
                    {t(
                      'resource_message:passwordShouldContainUpparAndLowerCase'
                    )}
                  </p>
                  <p>{t('resource_message:passwordShouldContainNo')}</p>
                </fieldset>
                <div className='col-12 fs--1 copyWriteText'>
                  Copyright © EA Technologies 2023. All rights reserved
                </div>
              </Card>
            </div>
          </Col>
          <Col className='p-0'>
            <div style={{ position: 'relative' }} className='image-container'>
              <Image
                src='https://ae.odexglobal.com/img/uaeLoginBanner.png'
                fluid
                style={{ height: '100vh' }}
              />
              <div
                style={{
                  position: 'absolute',
                  bottom: '20px',
                  width: '100%',
                  display: 'none',
                }}>
                <Carousel>
                  <Carousel.Item>
                    <div
                      style={{
                        background: 'white',
                        height: '170px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      className='p-2'>
                      <div
                        style={{
                          background: '#dcedea',
                          height: '70px',
                          marginBottom: '10px',
                          borderRadius: '0.5rem',
                          padding: '0.5rem',
                          display: 'flex',
                        }}>
                        <div style={{ marginRight: '1rem' }}>
                          <img src='https://ae.odexglobal.com/img/testimonials/gulf.png' />
                        </div>

                        <div>
                          <div>
                            <h6>- Mr. Adel Mohammad Hanif - Logistic dept </h6>
                          </div>
                          <div>
                            <span style={{ fontSize: '12px' }}>
                              Abbas Juma Clearing and Forwarding
                            </span>
                          </div>
                        </div>
                      </div>

                      <div style={{ height: '100px' }}>
                        <span style={{ fontSize: '12px' }}>
                          We are happy to use ODeX as it has simplified the
                          process. It is easy to use and reduces the waiting
                          time from 2-3 days to 4 hours. We would recommend
                          other lines to use ODeX.
                        </span>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div
                      style={{
                        background: 'white',
                        height: '170px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      className='p-2'>
                      <div
                        style={{
                          background: '#dcedea',
                          height: '70px',
                          marginBottom: '10px',
                          borderRadius: '0.5rem',
                          padding: '0.5rem',
                          display: 'flex',
                        }}>
                        <div style={{ marginRight: '1rem' }}>
                          <img src='https://ae.odexglobal.com/img/testimonials/gulf.png' />
                        </div>

                        <div>
                          <div>
                            <h6>- Mr. Adel Mohammad Hanif - Logistic dept </h6>
                          </div>
                          <div>
                            <span style={{ fontSize: '12px' }}>
                              Abbas Juma Clearing and Forwarding
                            </span>
                          </div>
                        </div>
                      </div>

                      <div style={{ height: '100px' }}>
                        <span style={{ fontSize: '12px' }}>
                          We are happy to use ODeX as it has simplified the
                          process. It is easy to use and reduces the waiting
                          time from 2-3 days to 4 hours. We would recommend
                          other lines to use ODeX.
                        </span>
                      </div>
                    </div>
                  </Carousel.Item>
                  {/* Add more carousel items as needed */}
                </Carousel>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
ResetPassword.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
};

export default ResetPassword;
