import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useDispatch } from 'react-redux';
import { updateDataTableFilterState } from './slices/DataTable';

const MultiSelect = ({
  objectKey,
  value,
  setValue,
  options,
  uniqueFilterKey,
}) => {
  const dispatch = useDispatch();

  const [selectAll, setSelectAll] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const focusRef = useRef(null);

  const filterState = useSelector((state) => state.dataTable.filterState);

  
  useEffect(() => {
    if(filterState?.[uniqueFilterKey]?.[objectKey]?.length == options?.length){
      setSelectAll(true)
    }
  },[options])

  useEffect(()=>{
    setTimeout(()=>{
      focusRef.current.focus()
    },200)
  })
  return (
    <>
      <Form.Control
        autoFocus={false}
        ref={focusRef}
        className='mb-3'
        placeholder='Search'
        size='sm'
        value={searchInput}
        onChange={(e) => {
          setSearchInput(e.target.value);
        }}
      />
      <Form.Check
        id='multiselect.selectAll'
        type='checkbox'
        label='Select All'
        className='text-dark fw-bold'
        checked={selectAll}
        onChange={() => {
          if (!selectAll) {
            dispatch(
              updateDataTableFilterState({
                ...filterState,
                [uniqueFilterKey]: {
                  ...filterState?.[uniqueFilterKey],

                  [objectKey]: options.map((i) => i.accessor),
                },
              })
            );
          } else {
            dispatch(
              updateDataTableFilterState({
                ...filterState,
                [uniqueFilterKey]: {
                  ...filterState?.[uniqueFilterKey],

                  [objectKey]: [],
                },
              })
            );
          }
          setSelectAll(!selectAll);
        }}
      />
      <div
        style={{
          maxHeight: 240,
          overflowY: 'auto',
        }}
      >
        {options
          .filter(
            (v) =>
              searchInput === '' ||
              v.label.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map((i, index) => (
            <Form.Check
              key={index}
              type='checkbox'
              checked={filterState?.[uniqueFilterKey]?.[objectKey]?.includes(
                i.accessor
              )}
              id={`multiselect.${i.accessor}`}
              label={i.label}
              name={objectKey}
              onChange={() => {
                const selectedValues =
                  filterState?.[uniqueFilterKey]?.[objectKey];

                dispatch(
                  updateDataTableFilterState({
                    ...filterState,
                    [uniqueFilterKey]: {
                      ...filterState?.[uniqueFilterKey],

                      [objectKey]: selectedValues
                        ? selectedValues?.includes?.(i.accessor)
                          ? selectedValues?.filter((v) => v !== i.accessor)
                          : [...selectedValues, i.accessor]
                        : [i.accessor],
                    },
                  })
                );
              }}
              className='text-primary fw-normal'
            />
          ))}
      </div>
    </>
  );
};

export default MultiSelect;
