import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IDocumentViewSlice {
  documentUrl: string;
  backNavUrl: string;
}

const initialState: IDocumentViewSlice = {
  documentUrl: '/pdf/INV12345674.pdf',
  backNavUrl: '',
};

export const documentViewSlice = createSlice({
  name: 'documentView',
  initialState,
  reducers: {
    updateDocumentViewUrl: (state, action: PayloadAction<string>) => {
      state.documentUrl = action.payload;
    },

    updateBackNavUrl: (state, action: PayloadAction<string>) => {
      state.backNavUrl = action.payload;
    },
  },
});

export const { updateDocumentViewUrl, updateBackNavUrl } =
  documentViewSlice.actions;

export default documentViewSlice.reducer;
