import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DataTable from '../../common/components/DataTable';
import FilterView from '../../common/components/FilterView';
import { IDataTableColumn } from '../../common/types/dataTable';
import { AppDispatch, RootState } from '../../store';
import { useEffect, useRef, useState } from 'react';

import queryString from 'query-string';
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import OblDetails from '../../common/components/OblDetails';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import {
  IConfigsDefinition,
} from '../../utils/columnConfig';

import { getColumnViews } from '../../common/actions/dataTable';
import {
  updateDashbaordRefresh,
  updateParams,
  updateSortStateDataTable,
} from '../../common/components/DataTable/slices/DataTable';
import { getDashboardData } from '../dashboard/actions/dashboard';
import { updateCurrentPagePayment } from '../dashboard/slices/dashboardSlice';
import { updateDashbaordName } from '../../common/slices/navbarTop';

import moment from 'moment';
import DashboardContainer from '../../../components/common/DashboardContainer';
import useDataCountry from '../../../hooks/useDataCountry';
import { toggleColumnSettingsPayDash } from './getInGetOutDashSlice';
import { useAmountFormatter } from '../../../hooks/useAmountFormatter';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const gridName = 'GET_IN_OUT_DASHBOARD'
const dashboardName = 'GET_IN_OUT_DASHBOARD'
const GetInGetOutDashboard = () => {
  const { blNum } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  const refresh = useSelector((
    state:RootState)=> state.dataTable.refreshDashboard)
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageGetInGetOut
  );

  const data = useSelector(
    (state: RootState) => state.dashboard.getInGetOutDashboardList
  );

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.getInGetOutGrids
  );
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const sortState = useSelector((state:RootState) => state.dataTable.sortState);
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPagePayment(page));
  };

  const receiptTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.receiptTopSearchState
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );

  const dataCountry = useDataCountry()
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();
    
    useEffect(() => {
      callback.current = effect;
    }, [effect]);
  
    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };
  
      const timer = setTimeout(handler, delay);
      
      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }
  const generateColumnConfig = useGenerateColumnConfig()

  useEffect(() => {
    const paymentDashboardDefinition =
      grids?.[0]?.columnConfigGrid || '';

    if (!paymentDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(paymentDashboardDefinition)),
        getParams,
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location]);

  useEffect(() => {
    dispatch(updateDashbaordName(gridName));
  }, []);

  const filterStateLocal = filterState?.['receipt'];
  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');
  //   (profileDetails.partnerType === 'customer' &&
  //   profileDetails.partnerId &&
  //   grids?.[0]?.whereClause) ||
  // profileDetails.partnerType === 'liner'

  
  useEffect(() => {
    if (location?.pathname && dataCountry && linerId) {
      dispatch(
        getColumnViews({ gridName: dashboardName, country: dataCountry, linerId : linerId })
      );
    }
    dispatch(updateParams({}));
    // dispatch(
    //   updateSortStateDataTable({
    //     key: null,
    //   })
    // );
  }, [dataCountry, linerId]);

  useEffect(() => {
    loadGetInGetOutDashboard(false);
  }, [currentPage]);
  useEffect(()=>{
    if (refresh) loadGetInGetOutDashboard(false)
    // server sometimes takes time to update the view so the delay
    setTimeout(()=>dispatch(updateDashbaordRefresh(false)),2000)
  }, [refresh])

  useEffect(() => {
    if(matchPath(location.pathname, '/receipt'))loadGetInGetOutDashboard(false);
  }, [location.pathname]);


  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, grids]);

  // useEffect(() => {
  //   if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
  //     loadPaymentDashboard(true);
  //     setPreviousState(currentState);
  //   }
  // }, [currentState, linerId]);

  useDebouncedEffect(() => {
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      loadGetInGetOutDashboard(true);
      setPreviousState(currentState);
    }
  }, [currentState, linerId], 700);

  useEffect(() => {
    loadGetInGetOutDashboard(true);
  }, [linerId]);

  const loadGetInGetOutDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) setCurrentPage(1);

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined ;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};
          const currentDate = new Date();

          if (grids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange:any = {};

            const newdata = grids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.['receipt']
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.dateRange;
            }
            if (filterApiState.invdt && timeFlag == 'day' && dateRange) {
              filterApiState.invdt[1] = moment()
              .subtract(Math.abs(dateRange.to), 'days')
              .endOf('day')
              .toISOString()
              filterApiState.invdt[0] = moment()
              .subtract(Math.abs(dateRange.from), 'days')
              .startOf('day')
              .toISOString();
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            setTimeout(()=> {dispatch(
              getDashboardData({
                gridName: gridName,
                payload: {
                  fields: /*  SelectedViewFields?.fields || */ [],
                  filters:
                    !selectedTopSearchFilter ||
                    selectedTopSearchFilter?.where == '' ||
                    searchInputValue.trim() === ''
                      ? finalFilterApiState
                      : {},

                  size: 20,
                  sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['receipt_id desc'],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== '' &&
                  searchInputValue.trim() !== ''
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );},250)
            
          }
        }
      } else {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails?.partnerType === 'liner'
        ) {
          setTimeout(()=>{dispatch(
            getDashboardData({
              gridName: gridName,
              payload: {
                fields: /* SelectedViewFields?.fields || */ [],
                filters:
                  selectedTopSearchFilter?.where === ''
                    ? receiptTopSearchState
                    : {},
                size: 20,
                sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['receipt_id desc'],
                page: filterUpdated ? 1 : currentPage,
              },
              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: grids?.[0]?.whereClause
                ? AESEncrypt(grids?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ''
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          );},250)
          
        }
      }
    } else {
      if (
        location.pathname.includes('/receipt') &&
        location?.state?.from == undefined
      ) {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails.partnerType === 'liner'
        ) {
          setTimeout(()=>{ dispatch(
            getDashboardData({
              gridName: gridName,
              payload: {
                fields: /* SelectedViewFields?.fields || */ [],
                filters:
                  selectedTopSearchFilter?.where == ''
                    ? receiptTopSearchState
                    : {},
                size: 20,
                sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : ['receipt_id desc'],
                page: filterUpdated ? 1 : currentPage,
              },
              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: grids?.[0]?.whereClause
                ? AESEncrypt(grids?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ''
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          );},250)
        }
      }
    }
  };

  const showColumnSettings = useSelector(
    (state:RootState)=>state.paymentDashboard.showColumnSettings)

  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : '',
      pmtmode: blNum ? AESDecrypt(blNum) : '',
    };
  };

  useEffect(() => {
    dispatch(updateParams({}));
    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    );
  }, [dataCountry]);



  return (
    <DashboardContainer >
      <FilterView
        loadList={loadGetInGetOutDashboard}
        showUploadButton
        showExport={true}
        showColumnSettings={true}
        dataLoading={false}
        toggleColumnSettings={toggleColumnSettingsPayDash}
        columnSettingsPersistKey='@odex/getInGetOut/v1'
        uniqueFilterKey='getInGetOut'
        configViews={
          grids?.find?.((g) => g?.gridName === dashboardName)
            ?.configGroup || []
        }
        showRightDiv={!!matchPath(location.pathname, '/getInGetOut')}
        dashboardName={dashboardName}
        data={data}
        gridDef={grids}
        child={
          <div> 
            <Button size={'sm'} variant='falcon-primary'  className='me-2 mt-1 '> <FontAwesomeIcon icon={'arrow-right'} className='me-2 mt-1 '/>Get In</Button>
            <Button size={'sm'} variant='falcon-primary'  className='me-2 mt-1 '>  <FontAwesomeIcon  icon={'arrow-left'} className='me-1 mt-1 ' style={{color:"#f5822a"}}/>  Get Out</Button>
          </div>
        }
      />
      {columns?.length > 0 && (
        <DataTable
          data={data?.result || null}
          columns={columns}
          dataCountry={dataCountry || ''}
          sortable
          searchable={false}
          showColumnFilter={false}
          selection={false}
          perPage={20}
          pagination
          numberPagination
          maxPage={parseInt(data?.maxPage || '100')}
          hasNext={data?.hasNext}
          currentPage={currentPage}
          gridId={grids?.[0]?.gridId || 0}
          onChangePageNumber={(pageNumber) =>
            setCurrentPage(pageNumber)
          }
          showExport={false}
          showColumnSettings={showColumnSettings}
          onHide={()=>{dispatch(toggleColumnSettingsPayDash(false));}}
          columnSettingsPersistKey='@odex/getInGetOut/v1'
          customSort={true}
          uniqueFilterKey='getInGetOut'
          staticHiddenCols={configs?.staticHiddenCols || []}
          defaultHiddenCols={[]}
          tableMinHeight={400}
          gridName= {dashboardName}
          dataLoading={false}
          configGroup={grids?.[0]?.configGroup || []}
        />
      )}          
    </DashboardContainer>
  )
};

export default GetInGetOutDashboard;
