import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FormControl, InputGroup, Row, Col } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

const CustomSearch = () => {
  const [value, setValue] = useState();

  const onChange = useAsyncDebounce((value) => {}, 200);
  return (
    <Row className='flex-end-center mb-2 mt-2'>
      <Col xs='auto' sm={6} lg={4}>
        <InputGroup className='position-relative'>
          <FormControl
            value={value || ''}
            onChange={({ target: { value } }) => {
              setValue(value);
              onChange(value);
            }}
            size='sm'
            id='search'
            placeholder='Search..'
            type='search'
            className='shadow-none'
          />
          <InputGroup.Text className='bg-transparent'>
            <FontAwesomeIcon icon='search' className='fs--1 text-600' />
          </InputGroup.Text>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default CustomSearch;
