import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NominationReq {
  selectedTab: 'consignee' | 'liner' | '';
}

const initialState: NominationReq = {
  selectedTab: '',
};

export const nominationReqSlice = createSlice({
  name: 'nominationReq',
  initialState,
  reducers: {
    updateSelectedTab: (
      state,
      action: PayloadAction<'consignee' | 'liner' | ''>
    ) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { updateSelectedTab } = nominationReqSlice.actions;

export default nominationReqSlice.reducer;
