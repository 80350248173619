import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getCreditBalance } from "../../blRequestInstruction/components/actions/Instruction";

export interface CreditBalanceBlPaymentDetails {
  pmtReference: string;
  pmtTime: string;
  pmtAmount: string;
  allocatedAmount: any;
  unAllocatedAmount: any;
}

interface CreditBalanceSllice {
  data: CreditBalanceBlPaymentDetails[];
}

const initialState: CreditBalanceSllice = {
  data: [
    /*  {
      paymentRef: 'PTMNO2366',
      time: '18-02-2023  5:30',
      amount: 'USD 100',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '10-02-2023  1:00',
      amount: 'USD 200',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '11-02-2023  3:30',
      amount: 'USD 800',
    },
    {
      paymentRef: 'PTMNO2366',
      time: '12-02-2023  5:20',
      amount: 'USD 200',
    }, */
  ],
};

export const creditBalanceBlPayment = createSlice({
  name: "creditBalanceBlPayment",
  initialState,
  reducers: {
    updateCreditBalanceList: (
      state,
      action: PayloadAction<CreditBalanceBlPaymentDetails[]>
    ) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreditBalance.pending, (state: any, action) => {
      state.dataLoading = true;
      state.data = action.payload;
    });
    builder.addCase(getCreditBalance.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
  },
});

export const { updateCreditBalanceList } = creditBalanceBlPayment.actions;

export default creditBalanceBlPayment.reducer;
