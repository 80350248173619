import { Button, Card, Form, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState, useRef } from 'react';
import DataTable from '../../common/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { IDataTableColumn } from '../../common/types/dataTable';
import {
  getCustomersDetailsByPartnerId,
  saveOrUpdateCustomersDetails,
} from './action/ProfileAccount';
import {
  updateBlDetailsRowColumns,
  updateAccountDetails,
  updateToggle,
} from './profileAccountSlice';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';

import {
  faTimes,
  faRedo,
  faPenToSquare,
  faFloppyDisk,
  faTrash,
  faAdd,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { getCurrencyDDList } from '../blPayment/actions/payments';
import { useLoading } from '../../../middleware/loadingMiddleware';

const AccountDtls = () => {
  const location2 = useLocation();
  const { t } = useTranslation();
  const { userID } = useParams();
  const dispatch2 = useDispatch<AppDispatch>();

  type FormValues = {
    mstAccountId: any;
    mstPartnerId: any;
    bankName: string;
    accountName: string;
    accountAlias: string;
    accountNo: string;
    accountCurrency: string;
    localBankCode: string;
    branchAddress: string;
    ibanNo: string;
    swiftCode: string;
  };

  const navigate = useNavigate();
  //Close Profile account
  const _onCloseForm = () => {
    dispatch2(
      updateToggle({
        ...partnerData,
        toggle: false,
      })
    );

    navigate('/user/profile');
  };

  const partnerData = useSelector((state: RootState) => state.partnerData);
  const { accountId } = useParams();
  const [account, setAccount] = useState<any>(
    accountId !== '0'
      ? {
          mstAccountId: null,
          mstPartnerId: partnerData.data.mstPartnerId,
          bankName: '',
          accountName: '',
          accountAlias: '',
          accountNo: '',
          accountCurrency: '',
          localBankCode: '',
          branchAddress: '',
          ibanNo: '',
          swiftCode: '',
          readonly: false,
        }
      : partnerData.data.partnerAccountList.list.find(
          (v: any) => accountId === v.mstAccountId.toString()
        )
  );

  //Account Currency
  const [accountAlias, setAccountAlias] = useState('');
  //Bank Name
  const [bankName, setBankName] = useState('');
  //Account Name
  const [accountName, setAccountName] = useState('');
  //Account Name
  const [accountNo, setAccountNo] = useState('');
  //Account Currency
  const [accountCurrency, setAccountCurrency] = useState('');
  //Account Currency
  const [localBankCode, setLocalBankCode] = useState('');

  const loadingCurrency = useLoading(['getCurrencyDDList'])
  const countryDDList = useSelector(
    (state: RootState) => state.standaloneInvoice.countryDDList
  );

  //slider
  useEffect(() => {
    if (matchPath('/user/profile/details/:accountId', location2.pathname)) {
      dispatch2(
        updateBlDetailsRowColumns({
          ...partnerData,
          mainTableClass: 'col-lg-4',
          outletClass: 'col-lg-8',
          colClass: 'col-lg-6',
          custMapTableClass: 'col-lg-12',
          custMapoutletClass: 'd-none',
        })
      );
      if (accountId === '0') {
        setAccount({
          mstAccountId: null,
          mstPartnerId: partnerData.data.mstPartnerId,
          bankName: '',
          accountName: '',
          accountAlias: '',
          accountNo: '',
          accountCurrency: '',
          localBankCode: '',
          branchAddress: '',
          ibanNo: '',
          swiftCode: '',
          readonly: false,
        });
      } else {
        if (
          partnerData.data.partnerAccountList.list.map((v: any) => v).length ===
          0
        ) {
          dispatch2(
            getCustomersDetailsByPartnerId({
              //  blId: blList.list.find((v) => v?.number === blNum)?.id || null,
            })
          )
            .unwrap()
            .then((response: any) => {
              setAccount(
                response.partnerAccountList.list.find(
                  (v: any) => accountId === v.mstAccountId.toString()
                )
              );
            });
        } else {
          setAccount(
            partnerData.data.partnerAccountList.list.find(
              (v: any) => accountId === v.mstAccountId.toString()
            )
          );
        }
      }
    } else if (matchPath('/user/profile', location2.pathname)) {
      dispatch2(
        updateBlDetailsRowColumns({
          ...partnerData,
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
          colClass: 'col-lg-2',
          custMapTableClass: 'col-lg-12',
          custMapoutletClass: 'd-none',
        })
      );
    }

    setAccountAlias(account?.accountAlias);
  }, [location2.pathname]);

  useEffect(() => {
    if(!countryDDList || countryDDList.length < 1)dispatch2(getCurrencyDDList()).unwrap().then((data:any)=>{
      setAccountCurrency(data?.[0]?.currencyCode??'')
    })
  }, []);

  useEffect(() => {
    setAccountAlias(account?.accountAlias);
    setValue('accountAlias', account?.accountAlias);
    setLocalBankCode(account?.localBankCode);
    setValue('localBankCode', account?.localBankCode);
    setBankName(account?.bankName);
    setValue('bankName', account?.bankName);
    setAccountName(account?.accountName);
    setValue('accountName', account?.accountName);
    setAccountCurrency(account?.accountCurrency);
    setValue('accountCurrency', account?.accountCurrency);
    setAccountNo(account?.accountNo);
    setValue('accountNo', account?.accountNo);
  }, [account]);

  const validateAlias = () => {
    {
      const s = partnerData.data.partnerAccountList.list.map((v: any) => v);
      for (var i = 0; i < s.length; i++) {
        if (
          (account.mstAccountId === null ||
            (account.mstAccountId !== null &&
              account.mstAccountId !== s[i].mstAccountId)) &&
          accountAlias === s[i].accountAlias
        ) {
          return false;
        }
      }
      return true;
    }
  };

  const validationSchema2 = Yup.object().shape({
    accountAlias: Yup.string()
      .required(t('resource_3:isRequired', { field: 'Account Alias' }))
      .test('uniqueAlias', 'Alias name should be unique', (val) =>
        validateAlias()
      ),
    bankName: Yup.string().required(
      t('resource_3:isRequired', { field: 'Bank Name' })
    ),
    accountName: Yup.string().required(
      t('resource_3:isRequired', { field: 'Account Name' })
    ),
    accountNo: Yup.string().required(
      t('resource_3:isRequired', { field: 'Account No./ACH ID' })
    ),
    accountCurrency: Yup.string().required(
      t('resource_3:isRequired', { field: 'Currency' })
    ),
  });

  //Setup
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema2),
    mode: 'onChange',
  });

  //SAVE profile details
  const onSubmit = handleSubmit(async (payload) => {
    const finalList =
      account.mstAccountId === null
        ? partnerData.data.partnerAccountList.list
            .map((v: any) => v)
            .concat({
              mstAccountId: null,
              mstPartnerId: partnerData.data.mstPartnerId,
              bankName: bankName,
              accountName: accountName,
              accountAlias: accountAlias,
              accountNo: accountNo,
              accountCurrency: accountCurrency,
              localBankCode: localBankCode,
              branchAddress: '',
              ibanNo: '',
              swiftCode: '',
              readonly: false,
            })
        : partnerData.data.partnerAccountList.list.map((v: any) => {
            if (account.mstAccountId === v.mstAccountId) {
              return {
                ...v,
                mstPartnerId: partnerData.data.mstPartnerId,
                bankName: bankName,
                accountName: accountName,
                accountAlias: accountAlias,
                accountNo: accountNo,
                accountCurrency: accountCurrency,
                localBankCode: localBankCode,
              };
            } else {
              return v;
            }
          });

    try {
      dispatch2(
        saveOrUpdateCustomersDetails({
          ...partnerData,
          updatedPartnerAccountList: finalList,
          updatedCustomermappingList:
            partnerData.data.customermappingList.list.map((v: any) => v),
        })
      )
        .unwrap()
        .then((response: any) => {
          if (response === '') {
            toast.dark('Please Try Again');
          } else {
            toast.dark(response.message.toString());
            dispatch2(
              updateToggle({
                ...partnerData,
                toggle: true,
              })
            );
            dispatch2(
              updateAccountDetails({
                ...partnerData.data.partnerAccountList,
                list: finalList,
              })
            );
            navigate('/user/profile');
          }
        });
    } catch (err) {
      toast.dark('Please Try Again');
    }
  });

  const disableFields = account.mstAccountId ? true : false;

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  return (
    <Card className='h-100'>
      <Card.Header className='border-bottom d-flex  align-items-center'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='cursor-pointer fa-lg mb-1 me-1'
          onClick={_onCloseForm}
        />
        {accountId === '0' ? (
          <h2 className='fs-0 text-primary mb-1'>{'Add Account Details'}</h2>
        ) : (
          <h2 className='fs-0 text-primary mb-1'>{'Account Details'}</h2>
        )}
      </Card.Header>
      <Card.Body>
        <div className=' h-100'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div>
              <div className='row'>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='accountDtls.accountAlias'>
                    <Form.Label className='fs--1 '>
                      <span className='mandatory'>*</span>
                      {'Account Alias'}
                    </Form.Label>
                    <Form.Control
                      {...register('accountAlias')}
                      className={`fs--1 form-control ${
                        errors.accountAlias ? 'is-invalid' : ''
                      }`}
                      type='text'
                      onChange={(e) => {
                        setAccountAlias(e.target.value);
                        //_onAccountAliasChange(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (accountAlias !== '') {
                          setValue('accountAlias', e.target.value);
                        }
                      }}
                      size='sm'
                      value={accountAlias}
                      disabled={disableFields}
                    />
                    <div className='invalid-feedback'>
                      {errors.accountAlias?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='profileAccount.localBankCode'>
                    <Form.Label className='fs--1'>{'Bank Code'}</Form.Label>
                    <Form.Control
                      {...register('localBankCode')}
                      className={`fs--1 form-control ${
                        errors.localBankCode ? 'is-invalid' : ''
                      }`}
                      type='text'
                      onChange={(e) => setLocalBankCode(e.target.value)}
                      size='sm'
                      value={localBankCode}
                      disabled={disableFields}
                    />
                    <div className='invalid-feedback'>
                      {errors.localBankCode?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='profileAccount.bankName'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {'Bank Name'}
                    </Form.Label>
                    <Form.Control
                      {...register('bankName')}
                      className={`fs--1 form-control ${
                        errors.bankName ? 'is-invalid' : ''
                      }`}
                      type='text'
                      onChange={(e) => setBankName(e.target.value)}
                      size='sm'
                      value={bankName}
                      disabled={disableFields}
                    />
                    <div className='invalid-feedback'>
                      {errors.bankName?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='profileAccount.accountName'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {'Account Name'}
                    </Form.Label>
                    <Form.Control
                      {...register('accountName')}
                      className={`fs--1 form-control ${
                        errors.accountName ? 'is-invalid' : ''
                      }`}
                      type='text'
                      onChange={(e) => setAccountName(e.target.value)}
                      size='sm'
                      value={accountName}
                      disabled={disableFields}
                    />
                    <div className='invalid-feedback'>
                      {errors.accountName?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='accountDtls.accountNo'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {'Account No./ACH ID'}
                    </Form.Label>
                    <Form.Control
                      {...register('accountNo')}
                      className={`fs--1 form-control ${
                        errors.accountNo ? 'is-invalid' : ''
                      }`}
                      type='text'
                      onChange={(e) => setAccountNo(e.target.value)}
                      size='sm'
                      value={accountNo}
                      disabled={disableFields}
                    />
                    <div className='invalid-feedback'>
                      {errors.accountNo?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='profileAccount.accountCurrency'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {'Currency'}
                    </Form.Label>
                    <Form.Select
                      {...register('accountCurrency')}
                      className={`fs--1 form-control ${
                        errors.accountCurrency ? 'is-invalid' : ''
                      }`}
                      value={accountCurrency}
                      disabled={disableFields || loadingCurrency}
                      onChange={(e) => setAccountCurrency(e.target.value)}
                    >
                      {
                        countryDDList.map((r: any) => {
                          return (
                            <>
                              <option value={r?.currencyCode} key={r?.currencyShortName}>
                                {r?.currencyShortName}
                              </option>
                            </>
                          )
                        })
                      }
                    </Form.Select>
                    <div className='invalid-feedback'>
                      {errors.accountCurrency?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
            {disableFields ? (
              <></>
            ) : (
              <div className='d-flex justify-content-center align-items-center mt-4'>
                <Button
                  type='submit'
                  className='me-2'
                  variant='falcon-danger'
                  size='sm'
                >
                  <FontAwesomeIcon icon={faFloppyDisk} className='me-2' />
                  {'Save'}
                </Button>
              </div>
            )}
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};
export default AccountDtls;
