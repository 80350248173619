import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getPickUp } from '../../../dashboard/actions/instruction';

export interface PickUpDetails {
  pickUpLoc: string;
  destuffingTp: string;
  returnType: string;
  status: string;
  userRemark: string;
  rejectionRemarks: string;
}
interface IPickupList {
  list: PickUpDetails[];
  /*   next: string | null;
    previous: string | null; */
}

export interface IPickup {
  data: IPickupList;
  pickUpData: PickUpDetails;
  dataLoading: boolean;
  isCompleteLoading: boolean;
  isRejectLoading: boolean;
  destuffingTpList: any;
}

const initialState: IPickup = {
  data: {
    list: [],
    /*   next: null,
      previous: null, */
  },
  dataLoading: true,
  pickUpData: {
    pickUpLoc: '',
    destuffingTp: '',
    returnType: '',
    status: '',
    userRemark: '',
    rejectionRemarks: '',
  },
  isCompleteLoading: false,
  isRejectLoading: false,
  destuffingTpList: [],
};

export const PickupSlice = createSlice({
  name: 'Pickup',
  initialState,
  reducers: {
    updateDestuffingTpList: (state, action: PayloadAction<any>) => {
      state.destuffingTpList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPickUp.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(getPickUp.fulfilled, (state, action) => {
      state.pickUpData = {
        pickUpLoc: action?.payload?.data?.pickUpLoc,
        destuffingTp: action?.payload?.data?.destuffingTp,
        returnType: action?.payload?.data?.returnInstructionId,
        status: action?.payload?.data?.status,
        userRemark: action?.payload?.data?.userRemarks,
        rejectionRemarks: action?.payload?.data?.rejectionRemarks,
      };
    });
  },
});

export const { updateDestuffingTpList } = PickupSlice.actions;

export default PickupSlice.reducer;
