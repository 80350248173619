import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateDataTableFilterState } from './slices/DataTable';

const SingleSelect = ({
  objectKey,
  value,
  setValue,
  options,
  uniqueFilterKey,
}) => {
  const dispatch = useDispatch();

  const filterState = useSelector((state) => state.dataTable.filterState);

  return (
    <>
      {filterState?.[uniqueFilterKey]?.[objectKey] ? (
        <Form.Check
          type='radio'
          id='showAll'
          label='Remove'
          name={objectKey}
          onClick={() =>
            dispatch(
              updateDataTableFilterState({
                ...filterState,
                [uniqueFilterKey]: {
                  ...filterState?.[uniqueFilterKey],

                  [objectKey]: '',
                },
              })
            )
          }
          className='text-warning'
        />
      ) : (
        ''
      )}

      {options.map((i, index) => (
        <Form.Check
          key={index}
          type='radio'
          checked={filterState?.[uniqueFilterKey]?.[objectKey] === i.accessor}
          id={i.accessor}
          label={i.label}
          name={objectKey}
          onChange={() => {
            dispatch(
              updateDataTableFilterState({
                ...filterState,
                [uniqueFilterKey]: {
                  ...filterState?.[uniqueFilterKey],

                  [objectKey]: i.accessor,
                },
              })
            );
          }}
          className='text-dark'
        />
      ))}
    </>
  );
};

export default SingleSelect;
