import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
//todo bllist
import {
  getInvoiceList,
  getInvoiceListFilter,
} from '../dashboard/actions/dashboard';
import {
  addRegistrationDetails,
  addRegistrationDetailsCompany,
  fetchCompanyDetailsForVerification,
} from './actions/Registration';

/*  export interface RegistrationDetailsCompany {
  company_name: string;
  address_details:string;
  state: string;
  country_code: string;
  request_id:string;
  source:string;
  company_number:string;

}  */
export interface RegistrationDetailsCompany {
  country: string;
  state: string;
  company_name: string;

  reg_number: string;
  current_status: string;
  address: string;
  acceptTerms: boolean;
}

export interface IRegistrationCompanyList {
  list: RegistrationDetailsCompany[];
}

export interface RegistrationCompanySlice {
  data: IRegistrationCompanyList;
  dataLoading: boolean;
  companyDetails: any;
  // showColumnSettings: boolean;
  //registrationAddressDetails: IRegistrationAddress;
  // selectedAddressResult: string;
}

const initialState: RegistrationCompanySlice = {
  data: {
    list: [],
  },

  dataLoading: true,

  companyDetails: {},
  //howColumnSettings: false,

  // selectedAddressResult: '',
};

export const RegistrationCompanyReducer = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addRegistrationDetailsCompany.pending, (state, action) => {
      state.dataLoading = true;
    });
    builder.addCase(
      addRegistrationDetailsCompany.fulfilled,
      (state, action) => {
        state.dataLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      fetchCompanyDetailsForVerification.fulfilled,
      (state, action) => {
        state.companyDetails = action.payload;
      }
    );
  },
});

export default RegistrationCompanyReducer.reducer;
