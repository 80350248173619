import {Modal } from "react-bootstrap"
import PrivacyPolicy from "../registration/privacyPolicy"
import TermsAndConditions from "../registration/termsAndCondition"
import { useTranslation } from "react-i18next"

export default function QuickPayModals({toc, policy, registered, setToc, setPolicy, setRegistered}:any){
    const {t} = useTranslation()
    return <>
            <Modal show={toc} onHide={() => { setToc(false) }} size='xl'>
            <Modal.Header className='p-2 pb-1' closeButton>
                <Modal.Title>{t('resource_3:termsAndConditions')}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    height: '500px',
                    overflow: 'auto',
                    fontSize: '12px',
                }}>
                <TermsAndConditions />
            </Modal.Body>
        </Modal>

        <Modal show={policy} onHide={() => setPolicy(false)} size='xl'>
            <Modal.Header className='p-2 pb-1' closeButton>
                <Modal.Title>{t('resource_2:privacyPolicy')}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{
                    height: '500px',
                    overflow: 'auto',
                    fontSize: '12px',
                }}>
                <PrivacyPolicy />
            </Modal.Body>
        </Modal>
    </>
}